import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { Event } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CommunicationService } from "../services/communication.service";
import { ReportService } from '../services/report.service';
import { LanguageService } from '../services/language.service';
import { DrawService } from '../services/draw.service';
import { TranslateService } from '@ngx-translate/core';

export interface Element {
  name: string;
  icon: any;
  ticon?,
  state: boolean,
  tooltip?
}

const CONTROL_BUTTON_PROPS: Element[] = [
  { name: 'label', icon: 'label', ticon: 'label_off', state: false, tooltip: "show/hide Labels" },
  { name: 'home', icon: 'home', ticon: 'home', state: true, tooltip: "Home" },
  { name: '2D', icon: 'dimension', state: false, tooltip: "2D/3D View" },
  { name: 'zoomin', icon: 'add', ticon: 'add', state: true, tooltip: "Zoom In" },
  { name: 'zoomout', icon: 'remove', ticon: 'remove', state: true, tooltip: "Zoom Out" },
  { name: 'front', icon: 'front', state: false },
  { name: 'top', icon: 'top', state: false },
  { name: 'back', icon: 'back', state: false },
  { name: 'right', icon: 'right', state: false },
  { name: 'left', icon: 'left', state: false },
  { name: 'pdf', icon: 'picture_as_pdf', ticon: 'picture_as_pdf', state: false, tooltip: "pdf" },
  { name: 'setting', icon: 'build', ticon: 'build', state: true, tooltip: "Settings" },
  { name: 'preview', icon: 'picture_as_pdf', ticon: 'picture_as_pdf', state: false, tooltip: "Preview" },
  { name: 'building', icon: 'business', ticon: 'domain_disabled', state: false, tooltip: "FMF" },
  { name: 'ground', icon: 'layers', ticon: 'layers_clear', state: true, tooltip: "Ground" },
  { name: 'BC', icon: 'BC', state: false, tooltip: "Jetty NB-NC" },
  { name: 'DF', icon: 'DF', state: false, tooltip: "Jetty DF" },
  { name: 'GH', icon: 'GH', state: false, tooltip: "Jetty GH" },
  { name: 'J', icon: 'J', state: false, tooltip: "Jetty J" },
  { name: 'Y', icon: 'Y', state: false, tooltip: "Jetty Y" },
  { name: 'ML', icon: 'ML', state: false, tooltip: "Jetty ML" },
  { name: 'C', icon: 'C', state: false, tooltip: "Jetty C" },
  { name: 'AB', icon: 'AB', state: false, tooltip: "Jetty AB" },
  { name: 'FMF', icon: 'FMF', state: false, tooltip: "Jetty FMF" },
  { name: 'roof', icon: 'roof', state: false, tooltip: "Show/Hide Roof" },
  { name: 'water', icon: 'water', ticon: 'waves', state: false, tooltip: "Show Ocean" },
  { name: 'marker', icon: 'marker', state: false, ticon: '' },
  { name: 'cutmarker', icon: 'create', state: false, ticon: '' },
  { name: 'stateBoard', icon: 'grid_on', state: false, ticon: 'grid_off', tooltip: "Stateboard" },
  { name: 'dashboard', icon: 'dashboard', state: false, ticon: 'dashboard', tooltip: "Dashboard" },
  { name: 'cameraAnimation', icon: 'videocam_off', state: false, ticon: 'videocam', tooltip: "Camera Animation" },
  { name: 'drag', icon: 'swipe', state: false, ticon: 'do_not_touch', tooltip: "Drag" },
  { name: 'comments', icon: 'comment', state: false, ticon: 'speaker_notes_off', tooltip: "Comments" },
];

@Component({
  selector: 'app-controltools',
  templateUrl: './controltools.component.html',
  styleUrls: ['./controltools.component.scss'],
  providers: [DatePipe]
})

export class ControltoolsComponent implements AfterViewInit {
  // @ViewChild(MatSelectionList, { static: true }) obj: MatSelectionList;
  currentJetty = "cfbhalifax"
  displayedColumns: string[] = ['position'];
  arrControlBtnProps = CONTROL_BUTTON_PROPS;
  broadcastSubs: any;
  planTypeView: any;
  seePlanState: boolean = false;
  loadJetty: boolean = true;
  showReportDate: any;
  @Input() loadedPort: any = '';
  @Input() shipLoadingStatus: boolean = true;
  @Input() jettyMissingShipList: any = [];
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() controlToolEvent = new EventEmitter<string>();
  @Output() zoomControl = new EventEmitter<string>();
  @Output() loadHomeScreen: EventEmitter<any> = new EventEmitter<any>();
  @Output() tblSeePlanOpen: EventEmitter<any> = new EventEmitter<any>();
  @Input() getElements;
  @Input() set planType(v: any) {
    if (v) {
      this.planTypeView = v.toLowerCase();
    }
  }
  @Input() set toggleFMFState(boo: any) {
    this.arrControlBtnProps[13].state = boo;
  }

  constructor(private datePipe: DatePipe, private drawService: DrawService, private reportServ: ReportService, private communicationServ: CommunicationService, private cdr: ChangeDetectorRef, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private translate: TranslateService, private language: LanguageService) {

    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `roof-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof-block.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `roof-slash-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof-slash-block.svg")
    );

    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  ngOnInit() {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "slider") {
          if (data.event === 'hidetag') {
            this.hideTag();
          }
        }
        if (data.src == 'sidepanel') {
          if (data.event == 'controlEvent') {
            let control = data['data'];
            let idx = this.arrControlBtnProps.findIndex(e => e.name == control.name);
            if (idx > -1) {
              this.arrControlBtnProps[idx].state = control.state;
            }
          }
        }
        if (data.src === "main") {
          if (data.event === 'camera-view-stop') {
            this.arrControlBtnProps[30].state = false;
          }
        }
        if (data.src === "navyApp") {
          if (data.event === 'cameraMode') {
            if(data.data.type == '2D'){
              if(!this.arrControlBtnProps[2].state){
                this.buttonClick(this.arrControlBtnProps[2],2);
              }
            }else{
              if(this.arrControlBtnProps[2].state){
              // this.arrControlBtnProps[2].state = !this.arrControlBtnProps[2].state; 
              this.buttonClick(this.arrControlBtnProps[2],2);
              }
            }
           
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  getReportDate() {
    this.showReportDate = this.reportServ.lastRefershedDate;
    if (this.showReportDate) {
      this.showReportDate = this.datePipe.transform(this.showReportDate, "dd-MMM-yy");
    }
    return this.showReportDate;
  }

  onMouseDown(row, index) {
    if (row.name === 'zoomin' || row.name === 'zoomout') {
      // this.buttonClick(row, index);
      let zoomPosition = row.name === 'zoomin' ? ']' : '[';
      this.zoomControl.emit(zoomPosition)
    }
  }

  //RAJ-COMMENT: check positionToolEvent, make singne event method and handle it
  buttonClick(row, index) {
    if (row === 'home_port') {
      this.currentJetty = index;
      let d = { src: 'animcontrol', event: 'home_port', data: { id: row, type: index } };
      this.communicationServ.getInstance().next(d);
      let idx = this.arrControlBtnProps.findIndex(e => e.name == 'building');
      var building: any = this.arrControlBtnProps[idx];
      if (idx > -1) {
        building['jetty'] = this.currentJetty
      }
      this.controlToolEvent.next(building);
    }
    else if (row === 'jetty') {
      this.currentJetty = index;
      let d = { src: 'animcontrol', event: 'external_jetty', data: { id: row, type: index } };
      this.communicationServ.getInstance().next(d);
      let idx = this.arrControlBtnProps.findIndex(e => e.name == 'building');
      var building: any = this.arrControlBtnProps[idx]
      if (idx > -1) {
        building['jetty'] = this.currentJetty
      }
      this.controlToolEvent.next(building);
    } else if (row.name === 'marker') {
      this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
      this.drawService.draw_select_mode = this.arrControlBtnProps[26].state;
      if (this.drawService.draw_select_mode) {
        this.arrControlBtnProps[27].state = !this.drawService.draw_select_mode;
        this.drawService.cut_mode = !this.drawService.draw_select_mode;
      }
    } else if (row.name === 'cutmarker') {
      this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
      this.drawService.cut_mode = this.arrControlBtnProps[27].state;
      if (this.drawService.cut_mode) {
        this.arrControlBtnProps[26].state = !this.drawService.cut_mode;
        this.drawService.draw_select_mode = !this.drawService.cut_mode;
      }
    } else if (row.name === 'cameraAnimation') {
      this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;      
      this.controlToolEvent.next(row);  
    } else {
      if (this.loadedPort === 'cfbhalifax') {
        if (row.name === 'home') {
          this.currentJetty = 'cfbhalifax';
        }
      }
      this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
      let idx = this.arrControlBtnProps.findIndex(e => e.name == 'building');
      if (idx > -1) {
        row['jetty'] = this.currentJetty
      }
      this.controlToolEvent.next(row);
    }
  }
  /* * *
   * hide label control
   * */
  hideTag() {
    var selElem;
    this.arrControlBtnProps.forEach((row, index) => {
      if (row.name === 'label') {
        if (this.arrControlBtnProps[index].state) {
          selElem = row;
          this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
          this.controlToolEvent.next(selElem);
        }
      }
    });
  }

  // triggerkey(key) {
  //   this.trigger.next(key);
  // }

  publishDialog() {
    this.openPublishDialog.emit('publish');
  }

  loadPlayScreen(e) {
    this.loadHomeScreen.emit('PTP');
  }

  seePlan() {
    this.seePlanState = true;
    this.tblSeePlanOpen.emit(this.seePlanState);
  }

  previewPDF(e) {
  }

  onPortSelect(val) {
    if (val != this.loadedPort) {
      this.loadedPort = val;
      this.broadcastInfo({ src: 'sidepanel', event: 'port_change', data: val, key: "" });
    }
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
}
