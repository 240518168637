import { Component, EventEmitter, Input, Inject, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-roundsapp',
  templateUrl: './roundsapp.component.html',
  styleUrls: ['./roundsapp.component.scss'],
  providers: [DatePipe]
})
export class RoundsappComponent implements OnInit {
  projectConfig: any;
  roundsDataList: any;
  roundsListView: boolean = true;
  roundsDetails: boolean = false;
  viewRoundsDetsils: any;
  broadcastSubs;
  loginUser: any;
  data: any;
  subscription: Subscription;
  webView: any = true;
  constructor(public dialog: MatDialog, private configService: ConfigService, private datePipe: DatePipe, private chitService: ChitService, private _formBuilder: FormBuilder, private communicationServ: CommunicationService,) {
    this.subscription = this.chitService.getReload().subscribe(reload => {
      var roundsData = reload.text;
      if (roundsData == 'reload') {
        this.getRoundsData();
      }
    });
    if (window.innerWidth < 600) {
      this.webView = false;
    } else {
      this.webView = true;
    }
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    if (event.target.innerWidth < 600) {
      this.webView = false;
    } else {
      this.webView = true;
    }
  }
  openDialog(item) {
    this.dialog.open(DialogStatus, {
      data: {
        data: item,
      },
    });
  }

  ngOnInit(): void {

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "app-main") {
          if (data.event === 'getChitDetail') {
            console.log("data:::", data.data);
            this.loginUser = data.data['user'];
          }
          if (data.event === 'getUserDetail') {
            console.log("login User", data.data);
            this.loginUser = data.data;
          }
        }
      })
    this.getRoundsData();
  }
  getRoundsData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
      this.chitService.getRoundsDataList().subscribe(response => {
        console.log('data getRoundsData', response);
        // this.roundsDataList = response;
        // var dateMatch = response.filter(t => t.email == "diane.b@datifex.com");
        // var dateMatch = response.filter(t => t.email.toLowerCase() == this.loginUser.email.toLowerCase());
        var dateMatch = response.filter(t => t.email == this.loginUser);
        this.roundsDataList = dateMatch;
      })
    })
  }
  dateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'MMMM d, y')
    }
  }

  getDayLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    return (days + 1) + " day(s)";
  }

  getDayHoursLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }

  formateDate(date) {
    if (date) {
      return this.datePipe.transform(date, 'dd-MMM-yy');
    } else {
      return '-'
    }

  }

  getDayHoursLength2(startDate, startTime, endDate, endTime) {
    startDate = this.datePipe.transform(startDate, 'dd-MMM-yy') + ' ,' + startTime;
    endDate = this.datePipe.transform(endDate, 'dd-MMM-yy') + ' ,' + endTime;
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
    // console.log( "hours",delta)
  }
  viewRoundsData(item) {
    this.roundsListView = false;
    this.roundsDetails = true;
    this.viewRoundsDetsils = item;
  }
  roundsStatusCancel() {
    this.roundsListView = true;
    this.roundsDetails = false;
  }
  roundsStatusInprogress() {
    var data = 'false'
    this.chitService.approveRoundsData(this.viewRoundsDetsils._id, { status: data })
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        this.roundsListView = true;
        this.roundsDetails = false;
        this.getRoundsData()
      }, error => {
        console.error('There was an error!');
      })
  }

}

@Component({
  selector: 'status',
  templateUrl: 'status.html',
  styleUrls: ['./roundsapp.component.scss'],
})
export class DialogStatus {
  notes: any;
  status: any;
  roundsId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: RoundsappComponent, public dialogRef: MatDialogRef<RoundsappComponent>, public dialog: MatDialog, private chitService: ChitService) {
    var roundsData = data.data
    this.notes = roundsData.notes
    this.status = roundsData.status
    this.roundsId = roundsData._id
  }
  roundsStatusCompleted() {
    this.chitService.approveRoundsData(this.roundsId, { status: this.status })
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        this.dialogRef.close();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }
}
