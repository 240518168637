import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { ReportService } from '../services/report.service';
import { TagoutService } from '../services/tagout.service';

@Component({
  selector: 'app-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss']
})

export class TimeSliderComponent implements OnInit {

  shipDate: any = 1;
  shipTime: any = 0;
  timeMaxvalue: any = 24;
  numberOfMinutes: any = 1440;
  range: any = 1;
  monthRange = 27;
  sliderSeconds: any = 0;
  sliderHours: any = 0;
  timerSpeed: any = '300';
  date = new Date();
  sliderDate = new Date("01-MAR-2021");
  animationDateTime: any = new Date("01-MAR-2021");
  changeEvent: any = 'default';
  broadcastSubs: any;

  constructor(private tagoutServ: TagoutService, private reportServ: ReportService, private ctrlServ: ControllerService, private communicationServ: CommunicationService, private datePipe: DatePipe,) {
  }

  ngOnInit(): void {
    this.animationDateTime = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy, HH:mm');
    this.ctrlServ.currentActivityDate = this.sliderDate;
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * * *
  * Time format label
  * * * * * */
  formatLabelTime(minutes: number | null) {
    if (!minutes) {
      return 0;
    }
    if (minutes < 0) {
      return minutes;
    }
    var hours = Math.floor(minutes / 60);
    var m = minutes % 60;
    var mins = m.toFixed(0);
    let _minutes = hours + ':' + mins;
    _minutes = _minutes == '24:0' ? '23:59' : _minutes;
    return _minutes;
  }

  /* * * * *
  * on date change
  * * * * * */
  dateChange(e) {
    this.shipTime = 0;
    this.sliderDate = e.value;
    this.changeEvent = "dateChange";
    this.onChangeTimeRange();
  }

  /* * * * *
  * time range value
  * * * * * */
  onTimeChange(event) {
    this.shipTime = event;
    this.changeEvent = "default";
    this.onChangeTimeRange();
  }

  /* * * * *
  * Time change pre/next
  * * * * * */
  timeChange(e) {
    if (e == 'pre') {
      this.shipTime = this.shipTime - 30;
    } else if (e == 'next') {
      this.shipTime = this.shipTime + 30;
    }
    this.changeEvent = "default";
    this.onChangeTimeRange();
  }

  /* * * * *
  * load to current day ship
  * * * * * */
  loadCurrentDayData() {
    this.shipTime = 0;
    this.changeEvent = "default";
    this.onChangeTimeRange();
  }

  /* * * * *
  * date range value
  * * * * * */
  onChangeTimeRange() {
    let m = this.formatLabelTime(this.shipTime);
    let minutes = m <= 0 ? '00:00' : m;
    let sliderDate = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy')
    let sliderDateTime = new Date(sliderDate + ', ' + minutes);
    this.animationDateTime = this.datePipe.transform(sliderDateTime, 'dd-MMM-yy, HH:mm');
    this.ctrlServ.animationDate = sliderDateTime;
    this.ctrlServ.currentActivityDate = sliderDateTime;
    this.reportServ.setCurrentViewDate(sliderDateTime);
    this.filterTimeRange();
  }

  /* * * * *
  * based on date/time scrub filter the data
  * * * * * */
  filterTimeRange() {
    if (this.changeEvent == 'dateChange') {
      this.getCurrentDayData();
    } else if (this.changeEvent == 'default') {
      this.onFilterTimeScrubberData();
    }
  }

  /* * * * *
  * based on calender date filter the data
  * * * * * */
  getCurrentDayData() {
    setTimeout(() => {
      let endDate = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy');
      let sliderTime = this.formatLabelTime(this.shipTime);
      this.broadcastInfo({ src: 'slms-slider', event: 'filterSlms', sliderDate: this.sliderDate, endDate: endDate, sliderTime: sliderTime });
    })
  }

  /* * * * *
  * based on time scrub filter the data
  * * * * * */
  onFilterTimeScrubberData() {
    setTimeout(() => {
      this.tagoutServ.sliderDate = this.sliderDate;
      this.tagoutServ.sliderDate = this.datePipe.transform(this.sliderDate, 'yyyy-MM-dd');
      let endDate = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy');     
      let sliderTime = this.formatLabelTime(this.shipTime);
      this.broadcastInfo({ src: 'slms-slider', event: 'filterScrubber', sliderDate: this.sliderDate, endDate: endDate, sliderTime: sliderTime });
      this.broadcastInfo({ src: 'slms-slider', event: 'filterSlms', sliderDate: this.sliderDate, endDate: endDate, sliderTime: sliderTime });
    })
  }

}
