import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { TagService } from './tag.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChitService {
  project_config: any;
  private config: 'any';
  typeCollectionForm: any;
  private _jsonURL = '../assets/project.config.json';
  // baseURL = 'http://localhost:3000/officer/'
  // baseURLRounds = 'http://localhost:3000/rounds/'
  //  baseURLSchedule = 'http://localhost:3000/chitMasterSchedule/'
  baseURL = 'https://nodesdms.azurewebsites.net/officer/'
  baseURLRounds = 'https://nodesdms.azurewebsites.net/rounds/'
  // baseURLTeams = 'https://nodesdms.azurewebsites.net/teams/'
  baseURLSchedule = 'https://nodesdms.azurewebsites.net/chitMasterSchedule/'
  // baseURLS = 'http://localhost:3000/radiate/'
  // baseURL = 'https://nodesdms.azurewebsites.net/chits/' scheduleApp
  baseURLS = 'https://nodesdms.azurewebsites.net/radiate/';
  url: any = 'https://nodesdms.azurewebsites.net/';
  private subject = new Subject<any>();
  locationList: any = [];
  isOpenPopupStatus = false;

  constructor(private http: HttpClient, private configService: ConfigService, private tagServ: TagService) {
  }


  addRadiate(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.radiate + 'create', body, { 'headers': headers })
  }
  radiateList(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.radiate + 'list', body, { 'headers': headers })
  }
  // radiateList(): Observable<any> {
  //   return this.http.get(this.baseURLS + 'list')
  // }

  getJSON(url = null): Observable<any> {
    return this.http.get(url || this._jsonURL);
  }

  getProjsectConfigs() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON().subscribe(data => {
          self.config = data[data["config"]];
          this.project_config = data;
          console.log("data config1111", this.project_config)
          resolve(self.config)
        },
          error => reject(error)
        );
      }
    })
  }

  public activityValidate(): Observable<any> {
    return this.http.get("./assets/activitydata.json");
  }

  getUserData(): Observable<any> {
    return this.http.get(this.configService.project_config.authaccess);
  }

  getChitData(): Observable<any> {
    return this.http.get(this.configService.project_config.chit)
  }

  getChitDataList(id: string): Observable<any> {
    return this.http.get(this.configService.project_config.chit + id)
  }

  CreateChit(id: string): Observable<any> {
    return this.http.get(this.configService.project_config.chit + id)
  }

  addChit(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.chit, body, { 'headers': headers })
  }
  updateChit(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + id, body, { 'headers': headers })
  }
  addChitEvolution(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'evolution' + '/' + id, body, { 'headers': headers })
  }
  addChitLocation(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'location' + '/' + id, body, { 'headers': headers })
  }
  addChitSafeExhaust(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'safeexhaust' + '/' + id, body, { 'headers': headers })
  }
  addChitProximity(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'proximity' + '/' + id, body, { 'headers': headers })
  }
  addChitFlags(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'flags' + '/' + id, body, { 'headers': headers })
  }
  addChitQhm(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'qhm' + '/' + id, body, { 'headers': headers })
  }
  addChitSign(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'sign' + '/' + id, body, { 'headers': headers })
  }
  addChitSpecialCircum(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'specialcircum' + '/' + id, body, { 'headers': headers })
  }
  addChitCompleted(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chit + 'completed' + '/' + id, body, { 'headers': headers })
  }
  deleteChit(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.configService.project_config.chit + '/' + id, { 'headers': headers })
  }
  //methods for Activity
  createActivity(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.createActivity, body, { 'headers': headers })
  }
  deleteActivity(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.deleteActivity, body, { 'headers': headers })
  }

  // Chit Activity

  getChitActivityDataList(): Observable<any> {
    return this.http.get(this.configService.project_config.chitActivity)
  }
  getChitActivityData(id): Observable<any> {
    return this.http.get(this.configService.project_config.chitActivity + id)
  }
  addChitActivity(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.chitActivity, body, { 'headers': headers })
  }
  addChitEvolutionActivity(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chitActivity + 'evolution' + '/' + id, body, { 'headers': headers })
  }
  updateChitActivity(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chitActivity + id, body, { 'headers': headers })
  }
  approveData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.chitActivity + 'approve/' + id, body, { 'headers': headers })
  }
  deleteChitActivity(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.configService.project_config.chitActivity + id, { 'headers': headers })
  }
  // Master file access
  getSupervisorData(): Observable<any> {
    return this.http.get(this.configService.project_config.master)
  }
  getOodData(): Observable<any> {
    return this.http.get(this.configService.project_config.schedule)
  }

  // Officer

  // getOfficerDataList(): Observable<any> {
  //   return this.http.get(this.configService.project_config.officer)
  // }
  // getOfficerData(id): Observable<any> {
  //   return this.http.get(this.configService.project_config.officer+id)
  // }
  // addOfficer(data): Observable<any> {
  //   const headers = { 'content-type': 'application/json'}
  //   const body=JSON.stringify(data);
  //   return this.http.post(this.configService.project_config.officer, body,{'headers':headers})
  // }
  // deleteOfficer(id: string): Observable<any> {
  //   const headers = { 'content-type': 'application/json'}
  //   return this.http.delete(this.configService.project_config.officer + '/' + id, {'headers':headers})
  // }

  getOfficerDataList(): Observable<any> {
    return this.http.get(this.baseURL)
  }
  getOfficerData(id): Observable<any> {
    return this.http.get(this.baseURL + id)
  }
  addOfficer(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.baseURL, body, { 'headers': headers })
  }
  updateOfficer(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.baseURL + id, body, { 'headers': headers })
  }
  deleteOfficer(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.baseURL + '/' + id, { 'headers': headers })
  }
  // Rounds
  getRoundsDataList(): Observable<any> {
    return this.http.get(this.baseURLRounds)
  }
  getRoundsData(id): Observable<any> {
    return this.http.get(this.baseURLRounds + id)
  }
  addRounds(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.baseURLRounds, body, { 'headers': headers })
  }
  updateRounds(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.baseURLRounds + id, body, { 'headers': headers })
  }
  deleteRounds(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.baseURLRounds + '/' + id, { 'headers': headers })
  }
  approveRoundsData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.baseURLRounds + 'approve/' + id, body, { 'headers': headers })
  }
  //  Reload data
  sendReload(reload: string) {
    this.subject.next({ text: reload });
  }
  getReload(): Observable<any> {
    return this.subject.asObservable();
  }
  getScheduleData(): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.get(this.baseURLSchedule, { 'headers': headers })
  }
  shedulerFilter(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.baseURLSchedule + 'filter/Shedulerfilter', body, { 'headers': headers })
  }

  // notification
  getMessageData(): Observable<any> {
    return this.http.get(this.configService.project_config.notification);
  }
  updateNotification(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.notification + 'notificationUpdate/', body, { 'headers': headers })
  }

  // Rounds
  getScheduleDataList(): Observable<any> {
    return this.http.get(this.configService.project_config.scheduleApp)
  }
  getScheduleDatas(id): Observable<any> {
    return this.http.get(this.configService.project_config.scheduleApp + id)
  }
  addSchedule(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.scheduleApp, body, { 'headers': headers })
  }
  updateSchedule(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.scheduleApp + id, body, { 'headers': headers })
  }
  deleteSchedule(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.scheduleApp + '/' + id, { 'headers': headers })
  }
  approveScheduleData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.scheduleApp + 'approve/' + id, body, { 'headers': headers })
  }
  /* * *
* visitor API 
* * */
  getVisitorScanData(id): Observable<any> {
    return this.http.get(this.configService.project_config.visitor + 'visitor_history/' + id);
  }
  getVisitorData(): Observable<any> {
    return this.http.get(this.configService.project_config.visitor + 'visitor/');
  }

  getVisitorDataById(id): Observable<any> {
    return this.http.get(this.configService.project_config.visitor + 'visitor/' + id);
  }

  addVistiorData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.visitor + 'visitor/', body, { 'headers': headers })
  }

  updateVisitorData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.visitor + 'visitor/' + id, body, { 'headers': headers })
  }

  deleteVisitorData(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.visitor + 'visitor/' + id, { 'headers': headers });
  }
  reSendQrCode(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.visitor + 'visitor/reSendQrCode', body, { 'headers': headers })
  }


  //*** Contractor API***/
  addContractor(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.contractor, body, { 'headers': headers })
  }
  updateContractor(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.contractor + id, body, { 'headers': headers })
  }
  getContractorDet(): Observable<any> {
    return this.http.get(this.configService.project_config.contractor)
  }
  getContractorList(id: string): Observable<any> {
    return this.http.get(this.configService.project_config.contractor + id)
  }
  deleteContractor(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.contractor + id, { 'headers': headers })
  }
  addVistiorManyData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.visitor + 'visitor/insertMany/', body, { 'headers': headers })
  }

  //*** Location Master API***/
  createLocation(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.locationMaster, body, { 'headers': headers })
  }
  updateLocation(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.locationMaster + id, body, { 'headers': headers })
  }
  getLocationList(): Observable<any> {
    return this.http.get(this.configService.project_config.locationMaster)
  }
  getLocation(id: string): Observable<any> {
    return this.http.get(this.configService.project_config.locationMaster + id)
  }
  deleteLocation(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.locationMaster + id, { 'headers': headers })
  }

  /* * *
  * Log Book API start region
  */
  createLogBook(data) {
    let logBookLayout = this.tagServ.layoutData.find(layout => layout.layoutName && layout.layoutName.toLowerCase() == "logbook");
    if (logBookLayout) {
      let obj = {
        "layoutId": logBookLayout['_id'] || '',
        "typeCollection": data.typeCollection || '',
        "tagName": data.tagName || '',
        "description": data.description || '',
        "startDate": data.startDate || new Date(),
        "endDate": data.endDate || '',
        "startTime": data.startTime || '',
        "endTime": data.endTime || '',
        "formStatus": data.formStatus || 'pending',
        "venue": this.tagServ.port
      }
      this.createLogBookData(obj)
        .subscribe((res) => {
          console.log(" logBook is created ", res);
        }, error => {
          console.error('There was an log book creation error!');
        })
    }
  }
  getLogBookData(): Observable<any> {
    return this.http.get(this.configService.project_config.logBook);
  }
  createLogBookData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.logBook, body, { 'headers': headers });
  }
  deleteLogBook(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.logBook + id, { 'headers': headers });
  }
  /* * *
  * Log Book API end region
  */

  /* * *
* Hot Workings API start region
*/

  addHotwork(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.hotWorkings, body, { 'headers': headers })
  }

  getHotworkDatas(id): Observable<any> {
    return this.http.get(this.configService.project_config.hotWorkings + id)
  }

  updateHotwork(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.hotWorkings + id, body, { 'headers': headers })
  }

  deleteHotworking(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.hotWorkings + id, { 'headers': headers })
  }
  /* * *
* Hot Workings API end region
*/

  /* * *
* visitor scanning Api
* * */
  visitorSignin(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/signin', body, { 'headers': headers })
  }

  visitorSignout(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/signout', body, { 'headers': headers })
  }

  visitorScan(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/scan', body, { 'headers': headers })
  }
  // Teams
  getTeamsDataList(): Observable<any> {
    return this.http.get(this.configService.project_config.teamsApi)
  }
  getTeamsData(id): Observable<any> {
    return this.http.get(this.configService.project_config.teamsApi + id)
  }
  addTeams(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.configService.project_config.teamsApi, body, { 'headers': headers })
  }
  updateTeams(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.teamsApi + id, body, { 'headers': headers })
  }
  deleteTeams(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.configService.project_config.teamsApi + '/' + id, { 'headers': headers })
  }
  approveTeamsData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.configService.project_config.teamsApi + 'approve/' + id, body, { 'headers': headers })
  }

}
