import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { MatStepper } from '@angular/material/stepper';
import { Output } from '@angular/core';
import moment from 'moment';
import { ControllerService } from '../services/controller.service';

// import {MatStepper} from '@angular/material';
import { RadhazVisService } from "../services/radhaz.service";
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfigService } from '../services/config.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { start } from 'repl';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss']
})
export class VisitorComponent implements OnInit {
  isReadonly: boolean = false;
  broadcastSubs: any;
  selectedLayoutList: any;
  selectedTagItem: any;
  visitorWindowState: boolean = false;
  CreatevisitorButton: boolean = true;
  visitorCollied: boolean = false;
  visitorFormGroup: FormGroup;
  visitorLists: any = [];
  filterDate: any;
  visitorListDataRes = [];
  visitorListData: any;
  userExistData: any;
  visitorId: any;
  qrCode: any ="";
  visitorHistory:boolean = false;
  visitorHistoryData:boolean = false;
  visitorNoDataRecord:boolean = false;
  displayedColumns = ['firstName', 'lastName', 'rank','role','scanLocation','scandatetime'];
  visitorHistoryId: any;
  selectedTagNotesItem: any;
  editChitIcon:boolean = false;
  cancelEditChitIcon:boolean = false;
  startTime = '08:00';
  endTime = '16:00';
  constructor(private radhazVisServ: RadhazVisService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService, private ctrlServ: ControllerService, private controllerService: ControllerService, private configService: ConfigService, private snackbar: MatSnackBar) { }
  editNotesData:any;
  locationForm:boolean = false;
  location: any = "";
  ngOnInit(): void {
    this.visitorList();
    this.visitorFormGroup = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/)
      ]),
      "mobile": new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      rank: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      signin: new FormControl(''),
      signout: new FormControl(''),
      scan: new FormControl(''),
      qrcode: new FormControl(''),
      lastScanLocation: new FormControl(''),
      lastScanDateTime: new FormControl(''),
      visitorID: new FormControl(''),
      Start: new FormControl(''),
      End: new FormControl(''),
      description:new FormControl('', Validators.required)
    });

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "sidepanel"){
          if (data.event === 'visitor') 
            {
              var keyElem = data.key
              if (data.selectedLayoutList) {
                this.selectedLayoutList = data.selectedLayoutList
                this.selectedTagItem = data.selectedTag
              }
              if (keyElem == "dashboard") {
                this.visitorWindowState = true;
                this.CreatevisitorButton = true;
                this.filterDate = data.filterDate != undefined ? data.filterDate : '';
                // this.isReadOnlyDashboard = false;
                // var vessel;
                // if (this.configService['loginInfo'] != undefined) {
                //   vessel = this.configService['loginInfo']['vessel']
                // }
                // // var vessel = (this.configService['loginInfo']['vessel'] ? this.configService['loginInfo']['vessel'] : ""); 
                // var formData = data.value;
                // var filterDate = data.filterDate != undefined ? data.filterDate : '';
                // this.createDashboardRoundsFormGroup.setValue({
                //   rounds: (""),
                //   starttime: (""),
                //   endtime: (""),
                //   date: (new Date(filterDate) ? new Date(filterDate) : ""),
                //   email: (""),
                //   vessel: (vessel ? vessel : ""),
                //   details: (""),
                // });
                // if (formData) {
                //   this.createDashboardRoundsFormGroup.setValue({
                //     rounds: (formData.rounds ? formData.rounds : ""),
                //     starttime: (formData.starttime ? formData.starttime : ""),
                //     endtime: (formData.endtime ? formData.endtime : ""),
                //     date: (new Date(filterDate) ? new Date(filterDate) : ""),
                //     email: (formData.email ? formData.email : ""),
                //     vessel: (formData.vessel ? formData.vessel : vessel ? vessel : ""),
                //     details: (formData.details ? formData.details : ""),
                //   });
                // }

              }
            }
            if (data.event === 'scheduleSelectedData') {
              this.selectedTagNotesItem = data.data;
            }
          if (data.event === 'visitorDashboardDataViews') {
            this.visitorWindowState = true;
            this.CreatevisitorButton = false;
            this.visitorId = data.data;
            this.visitorFormGroup.disable();
            this.editChitIcon = true;
            this.getvisitorData(this.visitorId);
            this.editNotesData = data['notesData'];
            var notes = data.notesData;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
          }
          if (data.event === 'visitorList') {
            var datas = data.data
            this.visitorHistoryData = true;
            this.visitorId = datas;
            this.visitorWindowState = true;
            this.CreatevisitorButton = false;
            this.visitorId = data.data;
            this.visitorFormGroup.disable();
            this.getvisitorData(this.visitorId);
            
          }
        }
        if (data.src == "layoutPannel") {
          if (data.event == "visitor_sendLocation") {
            this.locationForm = false
            this.visitorWindowState = true;
            this.CreatevisitorButton = true;
            this.location = data.location;
          }
        }
      })
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  visitorList() {
    this.chitService.getVisitorData().subscribe(element => {
      console.log("visitorList", element)
      this.visitorListDataRes = element// this.visitorListDataRes
      this.visitorLists = this.visitorListDataRes;
      // this.visitorListData = this.visitorListDataRes
      // this.visitorListData = this.visitorListDataRes.slice();
    })
  }
  filterData = [];
  DataIdx = 0;
  filterCheck = [];
  createVisitor() {
    if (this.visitorId) {
      if (this.visitorFormGroup.value) {
        let pushParams = Object.assign({},this.visitorFormGroup.value,{'Date':this.datePipe.transform(this.filterDate, 'MMM,dd,YYY')})
        this.chitService.updateVisitorData(this.visitorId, pushParams)
          .subscribe((res) => {
            this.visitorWindowState = false;
            let formData = this.visitorFormGroup.value
            this.editNotesData['tagName'] = formData['firstName'] + " " + formData['lastName'];
            this.editNotesData['props'] = { Vessel: formData['role'], activity: formData['firstName'] + " " + formData['lastName'] };
            let tagLocation, locationShape;
            if (this.location != "") {
              tagLocation = JSON.parse(this.location);
              locationShape = "avatar2";
            } else {
              tagLocation = {
                "x": 0,//(Math.random() * (max - min + 1)) + min,
                "y": 0,//(Math.random() * (max - min + 1)) + min,
                "z": 0,//(Math.random() * (max - min + 1)) + min,
              }
              locationShape = "avatar2";
            }
            var chitData = [{ 'typeCollectionId': this.visitorId, 'typeCollection': 'Visitor', "purpose": this.visitorFormGroup.value['description'],"tagShapeId":this.selectedTagNotesItem.tagShapeId,"tagLocation": tagLocation, "locationShape": locationShape }]
            var chit = {
              "Location": this.visitorFormGroup.value.location,
              "Vessel": this.visitorFormGroup.value.role,
              "activity": this.visitorFormGroup.value['firstName'] + " " + this.visitorFormGroup.value['lastName'],
              "emitterLocation": this.visitorFormGroup.value.rounds,
              "startTime":this.visitorFormGroup.value['Start'],
              "endTime":this.visitorFormGroup.value['End']
            }
            var logBookData = {
              'typeCollection': 'Visitor',
              "tagName": chit.activity,
              "startDate": this.datePipe.transform(this.filterDate, 'yyyy-MM-dd'),
              "startTime": this.visitorFormGroup.value['Start'],
              "endTime": this.visitorFormGroup.value['End']
            };
            this.chitService.createLogBook(logBookData);
            this.broadcastInfo({ src: 'chit', event: 'VisitorCreate', data: chitData, chit: chit, seletedCollection: this.selectedTagNotesItem, formdate: this.datePipe.transform(this.filterDate, 'MMM,dd,YYY, h:mm a') });
            
            this.broadcastInfo({ src: 'chit', event: 'visitorUpdate', data: this.editNotesData });
            this.broadcastInfo({ src: 'sidepanel', event: 'visitorAppClose', data: this.visitorWindowState, key: "" });
          }, error => {
            console.error('There was an error!');
          })
      }
    } else {
      var filterdataDate = this.visitorLists.filter(ele => ele.Date == this.datePipe.transform(this.filterDate, 'MMM,dd,YYY'))
      if(filterdataDate.length != 0){
        var filterdata = filterdataDate.filter(elem => elem.email == this.visitorFormGroup.value.email || elem.mobile == this.visitorFormGroup.value.mobile)
        console.log(filterdata);
        this.userExistData = filterdata[0];
        if(filterdata.length != 0){
          this.filterData = filterdata
          this.startFilterCheck()
        }else{
          this.createVisitorData()
        }
      }else{
        this.createVisitorData()
      }
    }
  }

  startFilterCheck() {
    if (this.filterData.length > this.DataIdx) {
      let filterdata = this.filterData[this.DataIdx];
      if (filterdata.Start != null && filterdata.End != null) {
        if ((filterdata.Start > this.visitorFormGroup.value.Start && filterdata.Start > this.visitorFormGroup.value.End) ||
          (filterdata.End < this.visitorFormGroup.value.Start && filterdata.End < this.visitorFormGroup.value.End)
        ) {
          this.filterCheck.push(true);
          this.DataIdx = this.DataIdx + 1;
          this.startFilterCheck();
        } else {
          this.filterCheck.push(false)
          this.DataIdx = this.DataIdx + 1;
          this.startFilterCheck();
        }
      }else{
        this.filterCheck.push(true);
        this.DataIdx = this.DataIdx + 1;
        this.startFilterCheck();
      }
    }else{
      this.DataIdx = 0;
      console.log('visitor',this.filterCheck);
      if(!this.filterCheck.includes(false)){
        this.createVisitorData();
      }else{
        this.visitorWindowState = false;
        this.visitorCollied = true;
      }
    }
  }

  createVisitorData(){
    if (this.visitorFormGroup.value ) {
      this.visitorFormGroup.value.email = this.visitorFormGroup.value.email.toLowerCase();
      let tagShapeId = this.getUIDData();
      let pushParams = {
        "tagShapeId":tagShapeId,
        'Date':this.datePipe.transform(this.filterDate, 'MMM,dd,YYY')
      }
      let ObjData = Object.assign({},this.visitorFormGroup.value,pushParams);
      this.chitService.addVistiorData(ObjData).subscribe(ele => {
        console.log(ele)
        var visitorId = ele.insertedId;
        this.visitorWindowState = false;
        let tagLocation, locationShape;
        if (this.location != "") {
          tagLocation = JSON.parse(this.location);
          locationShape = "avatar2";
        } else {
          tagLocation = {
            "x": 0,//(Math.random() * (max - min + 1)) + min,
            "y": 0,//(Math.random() * (max - min + 1)) + min,
            "z": 0,//(Math.random() * (max - min + 1)) + min,
          }
          locationShape = "avatar2";
        }
        var chitData = [{ 'typeCollectionId': visitorId, 'typeCollection': 'Visitor', "purpose": this.visitorFormGroup.value['description'],"tagShapeId":tagShapeId,"tagLocation": tagLocation, "locationShape": locationShape }]
        var chit = {
          "Location": this.visitorFormGroup.value.location,
          "Vessel": this.visitorFormGroup.value.role,
          "activity": this.visitorFormGroup.value['firstName'] + " " + this.visitorFormGroup.value['lastName'],
          "emitterLocation": this.visitorFormGroup.value.rounds,
          "startTime":this.visitorFormGroup.value['Start'],
          "endTime":this.visitorFormGroup.value['End']
        }
        var logBookData = {
          'typeCollection': 'Visitor',
          "tagName": chit.activity,
          "startDate": this.datePipe.transform(this.filterDate, 'yyyy-MM-dd'),
          "startTime": this.visitorFormGroup.value['Start'],
          "endTime": this.visitorFormGroup.value['End']
        };
        this.chitService.createLogBook(logBookData);
        this.broadcastInfo({ src: 'chit', event: 'VisitorCreate', data: chitData, chit: chit, seletedCollection: this.selectedTagItem, formdate: this.datePipe.transform(this.filterDate, 'MMM,dd,YYY, h:mm a') });
        this.broadcastInfo({ src: 'sidepanel', event: 'visitorAppClose', data: this.visitorWindowState, key: "" });
      })
    }
  }

  getUIDData() {
    return Math.random().toString(36).substr(2, 9);
  }

  closeVisitor() {
    delete this.editNotesData;
    this.visitorWindowState = false;
    this.broadcastInfo({ src: 'sidepanel', event: 'visitorAppClose', data: this.visitorWindowState, key: "" });
  }
  visitorCollisionCancel() {
    this.visitorWindowState = false;
    this.visitorCollied = false;
    this.visitorCollied = false;
    this.broadcastInfo({ src: 'sidepanel', event: 'visitorAppClose', data: this.visitorWindowState, key: "" });
  }
  visitorCollisionContinue() {
    this.chitService.reSendQrCode(this.userExistData).subscribe((eleme) => {
      if ( eleme == 'success') {
        this.visitorWindowState = false;
        this.broadcastInfo({ src: 'sidepanel', event: 'visitorAppClose', data: this.visitorWindowState, key: "" });
      }
    }, error => {
      console.error('There was an error!');
      
      this.visitorList()
    })
  }

  getvisitorData(id){
    this.chitService.getVisitorDataById(id).subscribe(
      (response) => {
        console.log("getvisitorData",response);
        if (response.length > 0) {
          this.visitorFormGroup.disable();
          var repos = response;
          var visitorData = repos[0]
          this.visitorFormGroup.patchValue(visitorData);
          this.qrCode = visitorData.qrcode;
          this.visitorHistoryId = visitorData.visitorID
          if (this.visitorHistoryData) {
            this.showVisitorHistory(this.visitorHistoryId)
          }
        }
      },
      error => {
        console.error('Request failed with error')
        console.log(error)
      })
  }
  showVisitorHistory(id){
    this.visitorHistory = true;
    this.visitorWindowState = false;
    this.chitService.getVisitorScanData(this.visitorHistoryId).subscribe(element => {
      console.log("getVisitorList",element)
      if (element.length == 0) {
        this.visitorNoDataRecord = true
      }
      this.visitorListDataRes =element// this.visitorListDataRes
      this.visitorListData = this.visitorListDataRes
      this.visitorListData = this.visitorListDataRes.slice();
    })
  }
  closeVisitorHistory(){
    this.visitorHistory = false;
    this.visitorWindowState = true;
  }
  getDateFormat(date) {
    return this.datePipe.transform(date, 'MMM d, y, h:mm:ss a');
  }
  visitorUpdateButton: boolean = false;
  visitorEditForm(){
    this.cancelEditChitIcon = true;
    this.editChitIcon = false;
    this.visitorFormGroup.enable();
    this.visitorUpdateButton = true;
  }
  visitorCloseForm(){
    this.cancelEditChitIcon = false;
    this.editChitIcon = true;
    this.visitorFormGroup.disable();
    this.visitorUpdateButton = false;
  }

  pickLocation() {
    this.locationForm = true;
    this.visitorWindowState = false;
    this.CreatevisitorButton = false;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'visitor';
  }

}
