import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { TagService } from '../services/tag.service';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material/icon';
import { ControllerService } from '../services/controller.service';

@Component({
  selector: 'app-hot-workings',
  templateUrl: './hot-workings.component.html',
  styleUrls: ['./hot-workings.component.scss']
})
export class HotWorkingsComponent implements OnInit {
  @Input() filterdate: any;
  broadcastSubs: any;
  hotWorkingsAppWindowState: any = true;
  dashboardHeading: any = "Create Hot Work Certificate";
  isReadOnlyDashboard: any = false;
  createHotworkingFormGroup!: FormGroup;
  filterDate: any = new Date("01-MAR-2021");
  startTime = "08:00";
  endTime = "20:00";
  selectedLayoutList: any;
  selectedTagItem: any;
  hotworkingId: any;
  hotWorkingNotes: any;
  selectedTagNotesItem: any;
  updateHWResponseDashboard: boolean = false;
  editResponseDashboardIcon: boolean = false;
  isReadOnly: boolean = false;
  closeResponseDashboardIcon: boolean = false;
  viewResponseDashboard: boolean = false;
  locationForm: boolean = false;
  location: any = "";
  constructor(private ctrlServ: ControllerService, private tagServ: TagService, private configService: ConfigService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService) {
    this.createHotworkingFormGroup = this._formBuilder.group({
      safeForHotWork: [''],
      customer: ['', Validators.required],
      workStarted: [' '],
      compartmentChecked: [' '],
      certificate: [''],
      description: ['', Validators.required],
      certifiedBy: [''],
      signature: [''],
      job: [''],
      startTime: [''],
      endTime: [''],
      startDate: ['', Validators.required],
      endDate: [''],
    });

    this.filterDate = this.datePipe.transform(this.filterdate, 'yyyy-MM-dd');
    // this.filterDate = this.ctrlServ.formatUTCTZ(this.filterDate);
    this.createHotworkingFormGroup.controls.startDate.setValue(this.filterDate);
    this.createHotworkingFormGroup.controls.startTime.setValue(this.startTime);
    this.createHotworkingFormGroup.controls.endTime.setValue(this.endTime);
    this.createHotworkingFormGroup.patchValue({
      startDate: new Date(this.ctrlServ.formatUTCTZ(this.filterDate, "DD-MMM-YYYY")) 
    });
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.filterDate = this.datePipe.transform(data.sliderDate, 'yyy-MM-dd');
            this.createHotworkingFormGroup.controls.startDate.setValue(this.filterDate);
            this.createHotworkingFormGroup.patchValue({
              Date: new Date(this.ctrlServ.formatUTCTZ(this.filterDate, "DD-MMM-YYYY"))
            });
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.filterDate = this.datePipe.transform(data.endDate, 'yyy-MM-dd');
            this.createHotworkingFormGroup.controls.startDate.setValue(this.filterDate);
            this.createHotworkingFormGroup.patchValue({
              startDate: new Date(this.ctrlServ.formatUTCTZ(this.filterDate, "DD-MMM-YYYY"))
            });
          }
        };

        if (data.src === "sidepanel") {
          if (data.event === 'hot-workings-app') {
            this.updateHWResponseDashboard = false;
            this.closeResponseDashboardIcon = false;
            this.isReadOnly = false;
            this.filterDate = this.datePipe.transform(data.filterDate, 'yyy-MM-dd');
            this.createHotworkingFormGroup.controls.startDate.setValue(this.filterDate);
            this.createHotworkingFormGroup.patchValue({
              startDate: new Date(this.ctrlServ.formatUTCTZ(this.filterDate, "DD-MMM-YYYY"))
            });

            var keyElem = data.key
            if (keyElem == "hot-workings-app") {
            }
            if (data.selectedLayoutList) {
              this.selectedLayoutList = data.selectedLayoutList
              this.selectedTagItem = data.selectedTag
            }
          }

          if (data.event === 'HotWorkingViews') {
            var data = data['data']
            this.dashboardHeading = "View Hot Work Certificate";
            this.hotworkingId = data;
            this.isReadOnly = true;
            this.closeResponseDashboardIcon = false;
            this.viewResponseDashboard = true;
            this.hotWorkingNotes = data.notes;
            this.editResponseDashboardIcon = true;
            this.getHotworking(this.hotworkingId);
            this.createHotworkingFormGroup.disable();
          }

          if (data.event === 'scheduleSelectedData') {
            this.selectedTagNotesItem = data.data;
          }
        }

        if (data.src == "layoutPannel") {
          if (data.event == "hotworking_sendLocation") {
            this.locationForm = false;
            this.location = data.location;
          }
        }
      })
  }

  getHotworking(hotworkingId) {
    this.chitService.getHotworkDatas(hotworkingId).subscribe(
      (response) => {
        var repos = response;
        if (repos.length !== 0) {
          var data = repos[0];
          let safeForHotWork = data.safeForHotWork != "" && data.safeForHotWork == true ? 'YES' : 'NO';
          this.createHotworkingFormGroup.setValue({
            safeForHotWork: (safeForHotWork),
            customer: (data.customer ? data.customer : ""),
            workStarted: (data.workStarted ? data.workStarted : ""),
            startDate: (new Date(data.startDate) ? new Date(this.ctrlServ.formatUTCTZ(data.startDate, "DD-MMM-YYYY")) : ""),
            endDate: (new Date(data.endDate) ? new Date(this.ctrlServ.formatUTCTZ(data.endDate, "DD-MMM-YYYY")) : ""),
            compartmentChecked: (data.compartmentChecked ? data.compartmentChecked : ""),
            certificate: (data.certificate ? data.certificate : ""),
            description: (data.description ? data.description : ""),
            certifiedBy: (data.certifiedBy ? data.certifiedBy : ""),
            signature: (data.signature ? data.signature : ""),
            job: (data.job ? data.job : ""),
            startTime: (data.startTime ? data.startTime : "08:00"),
            endTime: (data.endTime ? data.endTime : "20:00"),
          });
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }

  createHotwork() {
    if (this.createHotworkingFormGroup.valid) {
      let pushParams = {
        "safeForHotWork": this.createHotworkingFormGroup.value['safeForHotWork'] == 'YES' ? true : false,
        "formStatus": 'Pending',
        "venue": this.tagServ.port,
        "startDate": this.datePipe.transform(this.createHotworkingFormGroup.value.startDate, 'yyy-MM-dd')
      }
      let objData = Object.assign({}, this.createHotworkingFormGroup.value, pushParams);
      this.chitService.addHotwork(objData)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          console.log(res)
          var hotworkingId = res.insertedId;
          this.hotworkingId = res.insertedId

          let tagLocation, locationShape;
          if (this.location != "") {
            tagLocation = JSON.parse(this.location);
            locationShape = "cylinder";
          } else {
            tagLocation = {
              "x": 0,//(Math.random() * (max - min + 1)) + min,
              "y": 0,//(Math.random() * (max - min + 1)) + min,
              "z": 0,//(Math.random() * (max - min + 1)) + min,
            }
            locationShape = "cylinder";
          }

          var chitData = [{ 'typeCollectionId': hotworkingId, 'typeCollection': 'Hot Workings', "purpose": this.createHotworkingFormGroup.value.description, "tagLocation": tagLocation, "locationShape": locationShape }]
          var logBookData = {
            'typeCollection': 'Hot Workings',
            "tagName": this.createHotworkingFormGroup.value.customer,
            "description": this.createHotworkingFormGroup.value.details,
            "startDate": this.datePipe.transform(this.createHotworkingFormGroup.value.startDate, 'yyyy-MM-dd'),
            "startTime": this.createHotworkingFormGroup.value.startTime,
            "endTime": this.createHotworkingFormGroup.value.endTime
          };
          var chit = {
            "Location": "",
            "Vessel": this.configService['loginInfo']['vessel'],
            "activity": this.createHotworkingFormGroup.value.customer,
            "emitterLocation": "",
            "startTime": this.createHotworkingFormGroup.value.startTime,
            "endTime": this.createHotworkingFormGroup.value.endTime
          }
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'chit', event: 'hotworkCreate', data: chitData, chit: chit, seletedCollection: this.selectedTagItem, formdate: this.datePipe.transform(this.createHotworkingFormGroup.value.startDate, 'd-MMMM-y') });
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'hot-workings-app', data: false, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  updateHotworking() {
    if (this.createHotworkingFormGroup.valid) {
      let pushParams = {
        "safeForHotWork": this.createHotworkingFormGroup.value['safeForHotWork'] == 'YES' ? true : false,
        "formStatus": 'Pending',
        "venue": this.tagServ.port,
        "startDate": this.datePipe.transform(this.createHotworkingFormGroup.value.startDate, 'yyy-MM-dd')
      }
      let objData = Object.assign({}, this.createHotworkingFormGroup.value, pushParams);
      this.chitService.updateHotwork(this.hotworkingId, objData)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          let tagLocation, locationShape;
          if (this.location != "") {
            tagLocation = JSON.parse(this.location);
            locationShape = "cylinder";
          } else {
            tagLocation = {
              "x": 0,//(Math.random() * (max - min + 1)) + min,
              "y": 0,//(Math.random() * (max - min + 1)) + min,
              "z": 0,//(Math.random() * (max - min + 1)) + min,
            }
            locationShape = "cylinder";
          }

          var chitData = [{ 'typeCollectionId': this.hotworkingId, 'typeCollection': 'Hot Workings', "purpose": this.createHotworkingFormGroup.value.description, "tagLocation": tagLocation, "locationShape": locationShape, "tagShapeId": this.selectedTagNotesItem.tagShapeId }]
          var logBookData = {
            'typeCollection': 'Hot Workings',
            "tagName": this.createHotworkingFormGroup.value.customer,
            "description": this.createHotworkingFormGroup.value.details,
            "startDate": this.datePipe.transform(this.createHotworkingFormGroup.value.startDate, 'yyyy-MM-dd'),
            "startTime": this.createHotworkingFormGroup.value.startTime,
            "endTime": this.createHotworkingFormGroup.value.endTime
          };
          var chit = {
            "Location": "",
            "Vessel": this.configService['loginInfo']['vessel'],
            "activity": this.createHotworkingFormGroup.value.customer,
            "emitterLocation": "",
            "startTime": this.createHotworkingFormGroup.value.startTime,
            "endTime": this.createHotworkingFormGroup.value.endTime
          }
          this.broadcastInfo({ src: 'chit', event: 'hotworkCreate', data: chitData, chit: chit, seletedCollection: this.selectedTagNotesItem, formdate: this.datePipe.transform(this.createHotworkingFormGroup.value.date, 'd-MMMM-y') });
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'hot-workings-app', data: false, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  hotworkEditForm() {
    this.dashboardHeading = "Edit Hot Work Certificate ";
    this.editResponseDashboardIcon = false;
    this.closeResponseDashboardIcon = true;
    this.isReadOnly = false;
    this.createHotworkingFormGroup.enable();
    this.updateHWResponseDashboard = true;
    this.viewResponseDashboard = false;
    this.isReadOnly = false;
  }

  closeEditHWDashboard() {
    this.isReadOnlyDashboard = true;
    this.dashboardHeading = "View Hot Work Certificate";
    this.editResponseDashboardIcon = true;
    this.closeResponseDashboardIcon = false;
    this.updateHWResponseDashboard = false;
    this.viewResponseDashboard = true;
    this.createHotworkingFormGroup.disable();
    this.isReadOnly = false;
  }

  hotworkingDeleteActivity() {
    this.chitService.deleteHotworking(this.hotworkingId)
      .subscribe((res) => {
        this.broadcastInfo({ src: 'sidepanel', event: 'hot-workings-app', data: false });
        this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
      }, error => {
        console.error('There was an error!');
      })
  }

  closeHotworkingScreen() {
    this.isReadOnly = true;
    this.broadcastInfo({ src: 'sidepanel', event: 'hot-workings-app', data: false, key: "" });
  }

  pickLocation() {
    this.locationForm = true;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'hotworking';
  }

}
