import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit, ElementRef, HostListener} from '@angular/core';
import { DataService } from './dataservice.service';
declare var System;

let modelDefn: Array<any>;
const modelMap = {};
declare var window;
@Component({
  selector: 'lib-datauxview',
  templateUrl: './datauxview.component.html',
  styleUrls: ['./datauxview.component.scss']
})
export class DatauxviewComponent implements OnInit {

  //screen veriable...
  offHeight: string;
  winWidth: string;
  winHeight: string;
  show_full_screen: boolean;
  private _allElements = {};
  JsonPath: string;
  xlPath: string;
  socketPath: string;
  socketport: string
  datauxSocket;
  xlapipath: any;
  project_settings:any
  venue_orientation=0.001;
  yaw_2d=0.001;
  yaw_3d=0.001;
  pitch_3d=45;
  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  /**
   * variable to store dataux library
   */
  datauxlib: any;
  socketdata: any;
  /**
   * variable to store the datascape instance
   * it controls the loading/creation/interactions of 3D objects
   */
  _datascape = null;

  constructor(private _DataService: DataService) {

  }

  ngOnInit() {

  }

  lastEvent: any;
  isDragmove = false;
  @HostListener('click', ['$event']) onMouseup(event) {
    if (this.lastEvent.clientX === event.clientX && this.lastEvent.clientY === event.clientY) {
      this.isDragmove = false;
    } else {
      this.isDragmove = true;
    }
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    this.lastEvent = event;
  }

  @HostListener('document:keydown', ['$event'])handleKeyboardEvent(event: KeyboardEvent) {
        if(!event.key) return;
        const key = event.key.toString().toLowerCase();
        if (document.activeElement.classList.contains('rendererCanvas') ) {
          if (key === 'l' || key === 'r' || key === 'f' || key === 'b') {
              event.preventDefault();
          }
       }
     }
  isMouseDown = false;
  @HostListener('document:mousedown', ['$event']) handleMouseDownEvent(event: MouseEvent) {
    this.isMouseDown = true;
  }
  loadCanvas(filename, format, _callback) {
    System.import('../../dfx_lib/earcut.js').then(earcutlib => {
      if(!window.earcut){
        window.earcut=earcutlib.default
      }
    });
    System.import('../../dfx_lib/dataux.js').then(datauxlib => {
      this.datauxlib = datauxlib;
      this._DataService.getCanvasData(filename, format, _callback, (_data) => {
        let data = Object.assign(_data,this.project_settings)
        this._startDataUX(data, _callback);
/*
        this._DataService.init_socket((data) => {
          this.socketData(data);
        });
  */
      });
    });
  }
  setProjectSettins(config){
    this.project_settings=config;
    this.setJSONPath(this.project_settings.JsonPath);
  }
  setJSONPath(path){
    this._DataService.JsonPath=path;
  }

  loadAssets(filename, format, _callback) {
    this._DataService.getAssetsData(filename, format, _callback, (jsonObj) => {
      this._createModels(jsonObj['shapes'], _callback);
    });
  }
  loadData(filename, format, _callback) {
    this._DataService.getData(filename, format, ()=>{}, (jsonObj) => {
      _callback(jsonObj);
    });
  }
  renderAssets(filename, format, _callback, onGentrateAnimation) {
    this._DataService.getObjectData(filename, format, _callback, onGentrateAnimation, (jsonObj) => {
      let modelDefn = jsonObj['objects'];
      this.addElements(modelDefn, _callback, onGentrateAnimation);
    });
  }

  assignAssetsProperties(filename, format, _callback) {
    this.modifyElements(filename, format, _callback);
  }

  AnimateAssets(filename, format, _callback) {
    this._DataService.getAnimationData(filename, format, _callback, (jsonObj) => {
      Object.entries(jsonObj['objects']).forEach(([key, value]) => {

      });
    });
  }

  addSettings(_dfx_settings, _callback){
    let json_options = { canvas: this.rendererCanvas.nativeElement, settings: _dfx_settings };
      this.datauxlib.Datascape(json_options).then((instance) => {
        // Save the datascape pointer for global use
        this._setDatascape(instance);
        instance.start(); // started dataux libarary
        _callback(_dfx_settings,true);
      },(err)=>{
        alert(err);
      });
  }
  validateLicence(licenseinfo){

    // let old =localStorage.getItem("dfx::licence::info");
    // if(old){
    //   localStorage.removeItem("dfx::licence::info")
    // }
    let iObj = JSON.parse(licenseinfo);
    if(iObj.licencetype==='full'){
      return "Valid"
    }
    let startDate = new Date(iObj.installed);
    let currentDate = new Date();
    let difference_In_Time = currentDate.getTime() - startDate.getTime();
    let difference_In_Days = Math.floor(difference_In_Time / (1000 * 3600 * 24));
    let expiresIn=iObj.trialperiod;
    if(difference_In_Days<expiresIn){
      let daysLeft = expiresIn - difference_In_Days;
      return 'Trial-daysleft-'+daysLeft;
    }else{
      return 'Trial Expired';
    }
  }
  licenceTermsCallback(){
    alert("Show Terms of licence here.")
  }
  cameraview = {}
  _startDataUX(_dfx_settings, _callback): void {
    _dfx_settings.licenceValidator=(linfo)=>{return this.validateLicence(linfo)};
    _dfx_settings.licenceTermsCallback=()=>{return this.licenceTermsCallback()};
    this.cameraview = _dfx_settings.camera.views;
    var dynamicshape =  false;
        dynamicshape = _dfx_settings.createDynamicShape;
    if(dynamicshape) {
      _callback(_dfx_settings,false);
    } else {
     this.addSettings(_dfx_settings, _callback);
    }
  }

  socketData(_data) {
    console.log('_data : ', _data);
    this.socketdata = _data;
    const data = JSON.parse(_data);
    var _this = this;
    if (data.settings) {
      _this._startDataUX(data.settings, (settingstatus) => {
        if (settingstatus) {
          console.log("socket's settings is loaded successfully");
        }
      });
    }
    if (data.shapes) {
      _this._createModels(data.shapes, (objectstatus) => {
        if (objectstatus) {
          console.log("socket's shapes is loaded successfully");
        }
      });
    }
    if (data.objects) {
      _this.addElements(data.objects, (objectstatus) => {
        if (objectstatus) {
          console.log("socket's elements is loaded successfully");

        }
      }, null);
    }
  }

  // load all model maps here
  _createModels(_modelDefn, _callback) {
    let odatascape = this.getDatascape();
    // console.log('_modelDefn', _modelDefn);
    for (const val of Object.values(_modelDefn)) {
      const modname = Object.keys(val)[0];
      let trans = 0;
      const size = Object.values(val)[0].size;
      const tagprops = Object.assign({ label: Object.keys(val)[0] },Object.values(val)[0].tagprops||{})
      const modelProps = {
        shape: Object.values(val)[0].model,
        id: Object.keys(val)[0],
        initialGeometry: { orientation: { x: 0, y: 0, z: 0 }, position: { x: 0, y: 0, z: 0 }, size },
        profiles: {
          regular: {
            material: Object.values(val)[0].material,
            transition: trans,   // in milliseconds
            visible: true,
            shadow: true,
            selectable: true,
            wireframe:false

          },
          invisible: {
            material: Object.values(val)[0].material,
            transition: 0,
            visible: false,
            shadow: !true,
            selectable: false
          },
          transparent: {
            material: 'transparent',
            transition: 500,   // in milliseconds
            visible: true
          },
          blue: {
            material: 'red',
            transition: trans,   // in milliseconds
            visible: true,
            shadow: true,
            selectable: true,
            wireframe:false
          },
          red:{
            material: 'red_mtl',
            transition: 500,
            visible: true,
            shadow: true,
            selectable: true,
            wireframe:false
          },
          green: {
            material: 'green_mtl',
            transition: 500,
            visible: true,
            shadow: true,
            selectable: true,
            wireframe:false
          }
        },
        tag: tagprops
      };
      modelProps.profiles['colour_mtl'] = {
        material: 'colour_mtl',
        transition: 500,
        visible: true,
        shadow: true,
        selectable: true,
        wireframe:false
      };
      const model = odatascape.model(modelProps);
      modelMap[Object.keys(val)[0]] = model;
    }
    _callback(true);
  }
  addModel(dfx,opt){

    return dfx.model(opt)
  }
  addElement(shape,id,profiles,settings,tag=null){
    let dfx = this.getDatascape();
    let opt={
      shape,
      id:'e_'+shape,
      profiles
    }
    if(tag){
      opt['tag']=tag;
    }
    let m = this.addModel(dfx,opt);
    let el = dfx.add({
      id: id,
      model: m,
      geometry: settings.geometry,
      tag:tag||null
    });
    if(settings.scale){
      dfx.setElementScale(el,settings.scale)
    }
    this._allElements[id]=el;
  }
  addElements(modelDefn, _callback, onGentrateAnimation) {
    Object.entries(modelDefn).forEach(([key, value]) => {
      let model;
      let id;
      let size;

      model = value['model'];
      id = value['name'];
      size = value['place']['size'];
      const elemGeometry = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, 'position': { x: 0, y: 0, z: 0 } , 'size': size} };
      elemGeometry['geometry']['position'] = value['place']['pos'];
      elemGeometry['geometry']['orientation'] = value['place']['rot'];
      const newElem = this.getDatascape().add({
        id: id,
        model: modelMap[model],
        geometry: elemGeometry['geometry']
      });
      this.getDatascape().attach(newElem, {
        actions: {
            pickDown: [(evt, elem) => {
              if (evt.button === 0) {
                onGentrateAnimation(this.getDatascape().props(elem)['id'], 'pickDown');
              }
            }],
            pickUp: [(evt, elem) => {
              if (evt.button === 0) {
                onGentrateAnimation(this.getDatascape().props(elem)['id'], 'pickUp');
              }
            }],
            pickLeft: [(evt, elem) => {
              if (evt.button === 0) {
                onGentrateAnimation(this.getDatascape().props(elem)['id'], 'pickLeft');
              }
            }],
            pickDouble: [(evt, elem) => {
              if (evt.button === 0) {
                onGentrateAnimation(this.getDatascape().props(elem)['id'], 'pickDouble');
              }
            }]
        }
      });
      this._allElements[id] = newElem;
      const mesh = this.getDatascape().getElementMesh(this._allElements[id]);
      if (value['type'] === 'billboard') {
        mesh.billboardMode = 7;
      }
      if (value['parent']) {
        const parent = this._allElements[value['parent']];
        this.getDatascape().setAsParent(parent, [this._allElements[id]]);
      }
    });
    _callback(modelDefn);
  }

  getElementsid() {
    var keys = [];
    Object.entries(this._allElements).forEach(([key, value]) => {
      keys.push(key);
    });
    return keys;
  }

  getElementId(elementID) {
    return this._allElements[elementID];
  }

  allObjects(){
    return this._allElements;
  }


  /**
   * @GetEulers_deg - get mesh degree
   */
  // tslint:disable-next-line: member-ordering
  PI = 3.1415926;
  GetEulers_deg(mesh) {
    let eulers_deg;
    if (mesh.rotationQuaternion) {
        eulers_deg = mesh.rotationQuaternion.toEulerAngles().scale(180 / this.PI);
    } else {
        eulers_deg = mesh.rotation.scale(180 / this.PI);
    }
    return eulers_deg;
  }

  modifyElement(value) {
      const id = value['name'];
        const elemGeometry = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, 'position': { x: 0, y: 0, z: 0 } } };
        elemGeometry['geometry']['position'] = value['place']['pos'];
        elemGeometry['geometry']['orientation'] = value['place']['rot'];
        elemGeometry['geometry']['size'] = value['place']['size'];
        this.getDatascape().modify(this._allElements[id], { geometry: elemGeometry['geometry'], profile: value['profile']});
  }

  modifyElements(filename, format, _callback) {
    this._DataService.getObjectProperties(filename, format, _callback, (jsonObj) => {
      Object.entries(jsonObj['objects']).forEach(([key, value]) => {
        this.modifyElement(value);
      });
    });
  }
  // method to encapsulate datascape object
  _setDatascape(handle): void {
    this._datascape = handle;
  }

  getDatascape(): any {
    return this._datascape;
  }

  ngAfterViewInit(): void {
    this.position();
  }

  position(): any {
    console.log('fix position');
    const ht = window.innerHeight;
    // wht = 0;
    this.offHeight = ht + 'px';
    this.winHeight = window.innerHeight + 'px';

    if (this.show_full_screen === true) {
      this.winWidth = window.innerWidth + 'px';
    } else {
      this.winWidth = window.innerWidth + 'px';
    }
    this.triggerWindowResize();
  }

  triggerWindowResize() {
    window.dispatchEvent(new Event('resize'));
  }

  addDynamicShape(data) {
    this.addGLTFModel(this.getDatascape(), data);
  }

  addGLTFModel(dfx, gobj) {
    //let shapes=gobj
    dfx.addDynamicShapes(gobj).then((e) => {
      console.log(e);
      setTimeout(() => {
        let opt = {
          shape: '__root__',
          id: 'e_node1',

          profiles: {
            regular: {
              material: 'custom-included' /** material already defined in gltf */

            }
          }
        }
        let mymodel = dfx.model(opt)
        let el;
        setTimeout(() => {
           el = dfx.add({
            id: 'e_node1_1',
            model: mymodel,
            geometry: { position: { x: 0, y: 0.0, z: 0 }, size: 1 }
          });
          let mesh = dfx.getElementMesh(el);
          mesh.scaling.z=-1;
        })



      }, 0)


    })
  }

  mode2D = false;
  triggerKeyEvent(key) {
    if (key === 'escape') {
      // this.clearAllSelection(true);
      // this.clearSearch(null);
      return;
    }
    const node = this.rendererCanvas.nativeElement;
    const e: any = new Event('keydown');
    if (key.toLowerCase() === 'l' || key.toLowerCase() === 'r' || key.toLowerCase() === 'f' || key.toLowerCase() === 'b') {
      if (this.mode2D) {
        key = key.toUpperCase();
      } else {
        key = key.toLowerCase();
        // this.triggerCameraNaviagtion(key);
        return;
      }
    }
    e.key = key || 't';    // just enter the char you want to send
    e.keyCode = key === 'escape' ? 27 : e.key.charCodeAt(0);
    e.which = e.keyCode;
    e.altKey = false;
    e.ctrlKey = false;
    e.shiftKey = false;
    e.metaKey = false;
    node.focus();
    node.dispatchEvent(e);
  }

  /**
   * continuous zoom - start
   * on button press
   */
  // tslint:disable-next-line: member-ordering
  /**
   * continuous zoom - start
   * on button press
   */
  zoomIntervalId
  startZoom(key) {
    this.triggerKeyEvent(key);
    if (this.zoomIntervalId) {
      clearInterval(this.zoomIntervalId);
    }
    this.zoomIntervalId = setInterval(() => { this.triggerKeyEvent(key) }, 0)
  }
  /**
   * continuous zoom - stop
   * on button release
   */
  stopZoom(key=null) {
    clearInterval(this.zoomIntervalId)
  }

  Camera(view){
    if(this.cameraview){
      var data = this.cameraview[view];
      this.getDatascape().moveCamera(data);
    }
  }
last_pitch=null
  trigger3dmode() {
    this.getDatascape().switchPerspective({ pitch: this.last_pitch||this.pitch_3d, yaw:this.yaw_3d || this.venue_orientation });
  }

  /**
   * switch to 2D mode
   * @param key
   */
  trigger2dmode() {
    this.getDatascape().switchOrtho({yaw: this.yaw_2d||this.venue_orientation});
  }

  CameraMode(mode) {
    if(mode == '2D'){
      let cam = this.getDatascape().getCamera();
      this.last_pitch=cam.pitch_deg.value;
      this.trigger2dmode();
    } else if(mode == '3D') {
      this.last_pitch=null
      this.trigger3dmode();
    }
  }

  getObjectProperties(key){
    let elem = this.getDatascape().props(this._allElements[key]);
    this.showShapeProperties(key);
    return elem;
  }
  lastSelectedShape = null;
  showShapeProperties(shape) {
    const elem = this.getDatascape().props(this._allElements[shape]);
      if (elem) {
          let geopos = { 'position': { 'x': elem.geometry.position.x, 'y': elem.geometry.position.y, 'z': elem.geometry.position.z } };
          var mesh = this.getDatascape().getElementMesh(this._allElements[shape]);
          var target_pos = mesh.getAbsolutePosition();
          var data = { "target": { "x":  target_pos.x, "y": target_pos.y, "z": target_pos.z }};
          this.getDatascape().moveCamera(data);
          if(this.lastSelectedShape != null){
            if(this.lastSelectedShape == shape) {
              this.getDatascape().modify(this._allElements[this.lastSelectedShape], { profile: 'regular' });
              this.lastSelectedShape = null;
            }else {
              if (this.lastSelectedShape != shape) {
              this.getDatascape().modify(this._allElements[this.lastSelectedShape], { profile: 'regular' });
              this.getDatascape().modify(this._allElements[shape], { geometry: geopos, profile: 'blue' });
              }
              this.lastSelectedShape = shape;
            }
          }else{
            this.getDatascape().modify(this._allElements[shape], { geometry: geopos, profile: 'blue' });
            this.lastSelectedShape = shape;
          }
      }
  }

  triggerKey(_keyCode) {
    console.log('key triggered' + _keyCode);

    let event = document.createEvent('CustomEvent') as any;
    // event.which = 'a';
    // event.key = 'a';
    event.shiftKey = true;
    // event.bubbles = true;
    // event.cancelable = true;
    event.keyCode = _keyCode;
    event.initEvent('keydown', true, true);

    this.rendererCanvas.nativeElement.focus();
    this.rendererCanvas.nativeElement.dispatchEvent(event);
  }

  getElementMesh(elem){
   return this.getDatascape().getElementMesh(elem);
  }

  socketAnimation(data){
    this._DataService.getSocketData(data,(status)=> {
      console.log('status : success');
    });
  }

  attachDynamicObject(data,callback){
    var result;
    this.addSettings(data,(da, status)=>{
      callback(status);
    });
  }
};
