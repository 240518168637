import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ControllerService } from './controller.service';
import * as io from 'socket.io-client';
import { TagService } from './tag.service';
import { CommunicationService } from './communication.service';



@Injectable({
    providedIn: 'root'
})

export class SocketService {
    socket: any;
    selectedTagItem: any;
    selectedLayoutList: any;
    notificationData: any;
    approvalStatus: any;
    activityChangedMetaData: any;
    broadcastSubs: any;
    constructor(private ctrlServ: ControllerService, private configService: ConfigService, private tagServ: TagService, private communicationServ: CommunicationService) {
        this.broadcastSubs = this.communicationServ.getInstance()
            .subscribe((data: any) => {
                if (data.src === "comment") {
                    if (data.event == "userSocketSendInfo") {
                        this.sendUserDetailsInfo(data.data)
                    }
                }
                //this.currentLoginEmail = data.email
            })

    }
    /* * * * *
     * init socket service
     * * * * * */
    init_socket(projectConfig) {
        console.log('init_socket');
        var _this = this;
        let socketPath = projectConfig.socketport || 'http://localhost:3000';
        console.log("soket url", socketPath)
        if (_this.configService.loginInfo) {
            let socketport = `${socketPath}?userId=${_this.configService.loginInfo.userId}&username=${_this.configService.loginInfo.userName}`;
            _this.socket = io.connect(socketport);
            _this.socket.on('connect', onConnect);
            _this.socket.on('receiveMsg', onMessage);
            _this.socket.on('pushActivityChangedShips', onActivityChanged);
            _this.socket.on('disconnect', onDisconnect);
            _this.socket.on('connect_error', onError);
            _this.socket.on('reconnect_error', onError);

            function onConnect(evt) {
                console.log("socket connected!!");
                writeToScreen("CONNECTED");

                _this.socket.on("getNotification", data => {
                    console.log("getNotification ", data);
                    if (_this.configService.loginInfo.userId === data['userid']) {
                        _this.ctrlServ.openDialogBox(data['message']);
                    }
                });

                _this.socket.on("getKioskStatus", message => {
                    console.log("getKioskStatus :", message);
                    _this.broadcastInfo({ src: "socketMessage", event: "updateKioskStatus", data: message })
                });

                _this.socket.on("getInfo", message => {
                    _this.getInfo(message);

                });
                _this.socket.on("receiveMsg", message => {
                    // _this.getInfo(message);
                    if (message == 'success') {
                        _this.broadcastInfo({ src: "notification", event: "notificationData" })
                    }
                });
                _this.socket.on("getApproved", message => {
                    _this.broadcastInfo({ src: "socketMessage", event: "getApproved", data: message })
                    //_this.getApproved(message);
                });
            }

            function onMessage(message) {
                console.log("receiveMsg :", message);
                _this.broadcastInfo({ src: "socketMessage", event: "openPublishDialog", data: message })
            }
            function onDisconnect(evt) {
                writeToScreen("DISCONNECTED");
                console.log("socket disconnected!!");
            }

            function onError(message) {
                writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
            }

            function onActivityChanged(data) {
                writeToScreen('<span style="color: blue;">RESPONSE: ' + data + '</span>');
                console.log("activityChangedShips :", data);
                if (data.length > 0) {
                    _this.broadcastInfo({ src: "socketMessage", event: "activityChangedMetaData", data: data, notificationData: this.notificationData })
                }
            }

            function doSend(message) {
                writeToScreen("SENT: " + message);
                _this.socket.emit('sendMsg', message);
            }

            function writeToScreen(message) {
                var pre = document.createElement("p");
                pre.style.wordWrap = "break-word";
                pre.innerHTML = message;
                //output.appendChild(pre);
            }

        }
    }

    broadcastInfo(data: any) {
        this.communicationServ.getInstance().next(data);
    }

    /**
     * method recive info broadcast message through socket
     */
    getInfo(data) {
        console.log("getInfo ", data);
        if (data.userList && data.userList.length > 0 && data['eventName'] !== 'chat-communication') {
            this.notificationData = data;
            data.userList.forEach(ele => {
                if (ele.email == this.configService.accountEmail) {
                    this.approvalStatus = ele.Approval || 0
                    //this.openDialog("notificationData")
                    this.broadcastInfo({ src: "material", event: "notificationData", data: this.approvalStatus, notificationData: this.notificationData })
                    //   this.commetsWindow = true;
                    //   var layout = this.layoutData.filter(obj => obj._id == data.layoutId)
                    //   this.selectedLayoutList = layout[0];
                    // //   this.statusArray = this.selectedLayoutList.statusCollection;
                    // //   console.log(this.selectedLayoutList)
                    //   this.selectedTagItem = this.showTagPoint.find(obj => obj.tagShapeId == data.tagShapeId)
                    //   console.log(this.selectedTagItem)
                }
            })
        }

        if (data['AppName'] && data['AppName'].toLowerCase() === 'sdms' && data['type'] == 'chitcreation' && data['eventName'] !== 'chat-communication') {
            if (this.configService.loginInfo.userId !== data['userId']) {
                console.log("getInfo ", data);
                //this.broadcastInfo({ src: 'chitform', event: 'chitNotification', data: this.chitNotification, key: "" });
            }
        }

        if (data['AppName'] && data['AppName'].toLowerCase() === 'sdms' && data['eventName'] && data['eventName'] == 'chat-communication' && data['senderEmail'] && data.userList && data.userList.length > 0) {
            // const found = data['userList'].some(el => el.senderEmail != this.configService['loginInfo']['accountEmail']);
            if (data['senderEmail'] !== this.configService['loginInfo']['accountEmail']) {
                console.log("chat-communication ", data);
                this.broadcastInfo({ src: 'socketServ', event: 'show-chat-notification', data: data, key: "" });
            }
        }
    }

    /**
     * method send info broadcast message through socket
     */
    sendUserDetailsInfo(obj) {
        this.socket.emit('sendInfo', obj);
    }

    notificationUpdate(obj) {
        this.socket.emit('ackCmd', obj);
    }

}