import { Component, EventEmitter, Input, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TagoutService } from '../services/tagout.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  key(arg0: string, key: any) {
    throw new Error('Method not implemented.');
  }
  broadcastSubs;
  projectConfig: any;
  loginUser: any;
  userId: any;
  roundsDataList: any;
  tagoutDataList: any = [];
  tagoutDataListResponse: any = [];
  data: any;
  tagoutDataLength: any;
  subscription: Subscription;
  tagoutWindowState: any = false;
  tagoutMasterDetails: any;
  tagoutMasterScheduleDuty: any = [];
  // rounds
  scheduleWindowState: boolean = false;
  expandDateList: any;
  sidepanelUpdatedData: any;
  selectedDate: any = new Date();

  // chit
  chitDataList: any = [];
  chitWindowState: boolean = false;
  viewDataCalender = 'chits';
  chitResponseList: any = [];
  counter = 0;
  dataPassing: boolean = false;
  selectedCity = "Halifax"
  selectedCityURL = 'https://forecast7.com/en/44d65n63d58/halifax/'
  // cites = [{
  //   value: "Halifax",
  //   URL: 'https://forecast7.com/en/44d65n63d58/halifax/'
  // }, {
  //   value: "Esquimalt",
  //   URL: 'https://forecast7.com/en/48d44n123d41/esquimalt/'
  // }]
  weatherApidata ={
    "city": 'halifax',
    'days': 1,
    "apiKey" : "440741e8c6284e16b1763751220405"
  }
  weatherData =[]
  constructor(public dialog: MatDialog, private configService: ConfigService, private datePipe: DatePipe, private chitService: ChitService, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private tagoutService: TagoutService) {
    this.subscription = this.chitService.getReload().subscribe(reload => {
      var roundsData = reload.text;
      if (roundsData == 'reload') {
        this.getRoundsData();
        this.getChitActivityData();
      }
    });
    this.getWeatherdata()
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  openDialog(item) {
    this.dialog.open(DialogStatusUpdate, {
      data: {
        data: item,
        key: "rounds",
      },
    });
  }
  openDialogs(item) {
    this.dialog.open(DialogStatusUpdate, {
      data: {
        data: item,
        key: "chits",
        userId: this.userId,
      },
    });
  }
  deleteChit(id) {
    this.chitService.deleteChitActivity(id)
      .subscribe((res) => {
        console.log("deleteChit", res);
      }, error => {
        console.error('There was an error!');
      })
  }
  ngOnInit(): void {
    this.getTagoutMasterData(() => {
      this.getTagoutData();
    });

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "app-main") {
          if (data.event === 'getUserDetail') {
            // console.log("login User", data.data);
            this.loginUser = data.data;
            this.userId = data.key;
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'schedule') {
            this.scheduleWindowState = data.data;
          }
          if (data.event === 'scheduleDataInsert') {
            var data = data['data']
            if (data == 'true') {
              this.getRoundsData();
              this.dataPassing = true;
            }
          }
          if (data.event === 'stepper') {
            this.chitWindowState = data.data;
          }
        }
        if (data.src === "tagout") {
          if (data.event === 'closeTagout') {
            this.tagoutWindowState = data.data;
          }
          if (data.event === 'tagoutDataModified') {
            var data = data.data;
            if (data == 'true') {
              this.getTagoutData();
            }
          }
        }
        if (data.src === "chitApp") {
          if (data.event === 'chitDataModified') {
            var data = data.data;
            if (data == 'true') {
              this.getChitActivityData();
              this.dataPassing = true;
            }
          }
        }
        if (data.src === "calendar") {
          if (data.event == "updateTimeline") {
            if (data.key == "rounds") {
              this.updatedRoundDatas(data.data);
            }
            if (data.key == "chits") {
              let chit = data.data;
              chit['Start'] = chit['starttime'];
              chit['End'] = chit['endtime'];
              chit['Date'] = chit['date'];
              chit['activity'] = chit['rounds'];
              chit['OnsiteName'] = chit['email'];
              // delete chit['starttime'];
              // delete chit['endtime'];
              // delete chit['date'];
              // delete chit['rounds'];
              // delete chit['email'];
              this.updatedChit(chit);
            }
          }
          if (data.event === 'viewTimeline') {
            this.openRundsData(data.data)
          }
          if (data.event === 'createSchedule') {
            this.scheduleCreate(data.data)
          }
          if (data.event == 'selectedDate') {
            this.selectedDate = data.data;
            if (this.selectedDate) {
              this.counter++;
            }
            if (this.counter > 2) {
              if (this.roundsDataList) {
                // this.loginUser='vengad.s@datifex.com';
                var dateMatch = this.roundsDataList.filter(t => this.dateFormat(t.date) == this.dateFormat(this.selectedDate));
                this.updatedRoundData = dateMatch;
              }
              if (this.chitResponseList) {
                // this.loginUser='gokul.r@datifex.com';
                var dateMatch = this.chitResponseList.filter(t => this.dateFormat(t.Date) == this.dateFormat(this.selectedDate));
                this.chitDataList = dateMatch;
              }
              // if (this.tagoutDataListResponse) {
              //   // this.loginUser='gokul.r@datifex.com';
              //   var dateMatch = this.tagoutDataListResponse.filter(t => this.dateFormat(t.date) == this.dateFormat(this.selectedDate));
              //   this.tagoutDataList = dateMatch;
              // }
            }
            // if (this.selectedDate) {
            this.getTagoutData();
            // }
          }
          if (data.event === 'viewChits') {
            this.openChitData(data.data)
          }
          if (data.event === 'createChits') {
            this.chitCreate(data.data)
          }
        }

      })
    this.getRoundsData();
    this.getChitActivityData();
    // setTimeout(() => {
    //   let chitDelete = ["6176ad2b6e5fc516ecaa3b2b","6188c5ddb642573754745365","618bf8543ee8050036c4e6b7","618b5fb960ecb51294bedad8","618a1f8d754963102c11a75f","6182436ad7b3d1003674a388"];
    //   chitDelete.forEach(e=>{
    //     this.deleteChit(e);
    //   })
    // }, 2000);

  }
  datauxView() {
    this.broadcastInfo({ src: 'dashboard', event: 'datauxView', data: "dataux", key: "" });
  }
  updatedChit(data) {
    console.log("updatedChit", data);
    this.chitService.updateChitActivity(data['_id'], data)
      .subscribe((res) => {
        console.log("updatedChit", res);
        this.getChitActivityData();
      }, error => {
        console.error('There was an error!');
      })
  }
  updatedRoundDatas(data) {
    console.log("updatedRoundDatas", data);
    this.chitService.updateRounds(data._id, data)
      .subscribe((res) => {
        console.log("updatedRoundDatas", res);
        if (res['status'] === '200') {
        }

        this.getRoundsData();
      }, error => {
        console.error('There was an error!');
      })
  }
  updatedRoundData: any = [];
  getRoundsData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
      this.chitService.getRoundsDataList().subscribe(response => {
        this.roundsDataList = response;
        if (this.viewDataCalender == "rounds") {
          this.broadcastInfo({ src: 'dashboard', event: 'getRoundsData', data: this.roundsDataList, key: this.viewDataCalender });
        }
        // var dateMatch = response.filter(t => t.email == "diane.b@datifex.com");
        // var dateMatch = response.filter(t => t.email.toLowerCase() == this.loginUser.email.toLowerCase());
        // this.broadcastInfo({ src: 'dashboard', event: 'getRoundsData', data: response });
        // var dateMatch = response.filter(t => t.email == this.loginUser&&this.dateFormat(t.date)==this.dateFormat(this.selectedDate));
        // this.updatedRoundData = dateMatch;
        if (this.dataPassing == true) {
          var dateMatch = this.roundsDataList.filter(t => this.dateFormat(t.date) == this.dateFormat(this.selectedDate));
          this.updatedRoundData = dateMatch;
        } else {
          var dateMatch = this.roundsDataList.filter(t => this.dateFormat(t.date) == this.dateFormat(this.selectedDate));
          this.updatedRoundData = response;
        }

      })
    })
  }
  getChitActivityData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
      this.chitService.getChitActivityDataList().subscribe(response => {
        // this.chitDataList = response;
        // this.loginUser='gokul.r@datifex.com';
        this.chitResponseList = response;
        // this.broadcastInfo({ src: 'dashboard', event: 'getChitsData', data: this.chitResponseList, key: this.viewDataCalender });
        // var dateMatch = response.filter(t => this.dateFormat(t.Date)==this.dateFormat(this.selectedDate));
        // var dataMatch = dateMatch.filter(t => t.SupName == this.loginUser || t.OnsiteName == this.loginUser);
        // this.chitDataList = dataMatch;
        if (this.viewDataCalender == "chits") {
          this.broadcastInfo({ src: 'dashboard', event: 'getChitsData', data: this.chitResponseList, key: this.viewDataCalender });
        }
        var sideHeader = ['Raj', 'Gokul', 'Sathesh', 'Harris', 'Chris', 'Chris.Erickson@datifex.com', 'demodfx@outlook.com', null, '']
        var removeNull;
        sideHeader.forEach(element => {
          let key = response.findIndex(e => e.OnsiteName == element)
          if (key > -1) {
            response.splice(key, 1);
          }
        });
        removeNull = response.filter(x => x.OnsiteName !== null)
        if (this.dataPassing == true) {
          var dateMatch = this.chitResponseList.filter(t => this.dateFormat(t.Date) == this.dateFormat(this.selectedDate));
          this.chitDataList = dateMatch;
        } else {
          var dateMatch = this.chitResponseList.filter(t => this.dateFormat(t.Date) == this.dateFormat(this.selectedDate));
          this.chitDataList = dateMatch;
        }

        // this.chitDataList = response;

        // if(response.length > 0) {
        //   var selectedDate = this.datePipe.transform(this.selectedDate, 'dd-MMM-yy');
        //   var currentDateData = response.filter(el => {
        //     let date = this.datePipe.transform(el.date, 'dd-MMM-yy');
        //     if (new Date(date).getTime() === new Date(selectedDate).getTime()) {
        //       return el;
        //     }
        //   })
        //   var dateMatch = currentDateData.filter(t => t.SupName == this.loginUser || t.OnsiteName == this.loginUser)
        //   this.chitDataList = dateMatch;
        // } 
      })
    })
  }

  weatherDateFormat(date){
    if (date) {
      return this.datePipe.transform(date, 'EEEE  MMMM d  y  h:mm a')
    }
  }
  weatherTimeFormat(date){
    if (date) {
      return this.datePipe.transform(date, 'h a')
    }
  }
  dateFormat(date) {
    if (date) {
      date = new Date(date);
      return this.datePipe.transform(date, 'MMMM d, y')
    }
  }
  getDayLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    return (days + 1) + " day(s)";
  }
  getDayHoursLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
  }
  formateDate(date) {
    if (date) {
      return this.datePipe.transform(date, 'dd-MMM-yy');
    } else {
      return '-'
    }
  }
  getDayHoursLength2(startDate, startTime, endDate, endTime) {
    startDate = this.datePipe.transform(startDate, 'dd-MMM-yy') + ' ,' + startTime;
    endDate = this.datePipe.transform(endDate, 'dd-MMM-yy') + ' ,' + endTime;
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
  }
  openTagoutDialog() {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'createTagoutDialog', data: this.tagoutDataLength, key: "" });
    }, 100);
  }
  openTagoutApproved(item) {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'openTagoutApproved', data: item, key: "approved", master: this.tagoutMasterScheduleDuty });
    }, 100);
  }
  openTagoutCompleted(item) {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'openTagoutComplete', data: item, key: "complete", master: this.tagoutMasterScheduleDuty });
    }, 100);
  }
  openTagoutGuarantee(item) {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'openTagoutComplete', data: item, key: "guarantee", master: this.tagoutMasterScheduleDuty });
    }, 100);
  }
  openTagoutMaintainer(item) {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'openTagoutComplete', data: item, key: "maintainer", master: this.tagoutMasterScheduleDuty });
    }, 100);
  }

  getTagoutData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.projectConfig = project_config;
      this.tagoutService.getTagoutData().subscribe(response => {
        // this.tagoutDataList = response;
        this.tagoutDataListResponse = response;
        this.tagoutDataLength = response.length;
        if (this.tagoutMasterScheduleDuty.length > 0) {
          var selectedDate = this.datePipe.transform(this.selectedDate, 'dd-MMM-yy');
          var currentDateData = response.filter(el => {
            let date = this.datePipe.transform(el.date, 'dd-MMM-yy');
            if (new Date(date).getTime() === new Date(selectedDate).getTime()) {
              return el;
            }
          })
          var dateMatch = currentDateData.filter(t => this.tagoutMasterScheduleDuty.some(el => el.scheduleDuty == t.status))
          // var dateMatch = response.filter(t => this.tagoutMasterScheduleDuty.some(el => el.scheduleDuty == t.status))
          this.tagoutDataList = dateMatch;
        }
      })
    })
  }
  getWeatherdata(){
    this.configService.getWeatherData(this.weatherApidata).then((response: any) => {
      console.log("getWeather ", response);
      this.weatherData =[]
      this.weatherData.push(response)
    })
  }
  getTagoutMasterData(cbck) {
    this.tagoutService.getTagoutMasterData().subscribe(response => {
      //  var dateMatch = response.filter(t => t.name == "gokul.r@datifex.com");
      var dateMatch = response.filter(t => t.name == this.loginUser);
      if (dateMatch.length > 0) {
        this.tagoutMasterDetails = dateMatch[0];
        this.getRolesMasterData(dateMatch[0]._id);
        setTimeout(() => cbck(), 100);
      }
    })
  }
  getRolesMasterData(id) {
    this.tagoutService.getUserRoles(id).subscribe(response => {
      this.tagoutMasterScheduleDuty = response;
    })
  }

  openTagoutData(item) {
    this.tagoutWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'dashboard', event: 'openTagoutData', data: item, key: item._id });
    }, 100);
  }
  // Rounds form view 
  scheduleCreate(e: any) {
    this.scheduleWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e });
    }, 100);
  }
  openRundsData(data) {
    this.scheduleWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard" });
      this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDashboardData', data: data, key: "", });
    }, 100)
  }
  roundsExtent(i) {
    if (this.expandDateList == undefined) {
      this.expandDateList = i;
    } else {
      if (this.expandDateList == i) {
        delete this.expandDateList
      } else {
        this.expandDateList = i;
      }
    }
  }
  openChitData(data) {
    this.chitWindowState = true;
    setTimeout(() => {
      // this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.chitWindowState, key: "dashboard" });
      this.broadcastInfo({ src: 'sidepanel', event: 'chitViewData', data: data, key: "dashboard", });
    }, 100)
  }
  chitCreate(e: any) {
    this.chitWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'dashboardChit', data: this.scheduleWindowState, key: "dashboard", value: e });
    }, 100)
  }
  getOfficerData() {
    this.chitService.getOfficerDataList().subscribe(
      (response) => {
        this.sidepanelUpdatedData = response;

      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
  }
  regEx(location) {
    return location;
  }
  regExReverse(location) {
    return location.replace(/\//g, "_").replace(/\s/g, '');
  }
  scheduleData(data) {
    this.scheduleWindowState = true;
    // var activityDate = this.datePipe.transform(date.groupValue, 'MM-dd-yyy') ;
    this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'scheduleData', data: data, key: data.activity, });
    }, 100)
  }
  activeCard(e) {
    if (e == "chits") {
      this.counter = 1;
      this.viewDataCalender = 'chits';
      this.broadcastInfo({ src: 'dashboard', event: 'getChitsData', data: this.chitResponseList, key: this.viewDataCalender });
    }
    if (e == "rounds") {
      this.counter = 1;
      this.viewDataCalender = 'rounds';
      this.broadcastInfo({ src: 'dashboard', event: 'getRoundsData', data: this.roundsDataList, key: this.viewDataCalender });
    }
  }
//   changeCity(){
//     if(this.city.toLowerCase() == 'halifax' || this.province.toLowerCase() == 'halifax' || this.country == "CN" ){
//       this.selectedCity = this.cites[0].value;
//       this.selectedCityURL = this.cites[0].URL;
//     } else {
//       this.selectedCity = this.cites[1].value;
//       this.selectedCityURL = this.cites[1].URL;
//     }
// }
}


@Component({
  selector: 'status',
  templateUrl: 'status.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DialogStatusUpdate {
  notes: any;
  status: any;
  roundsId: any;
  statusUpdate: any;
  chitStatusDetails: any;
  userId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DashboardComponent, public dialogRef: MatDialogRef<DashboardComponent>, public dialog: MatDialog, private chitService: ChitService) {
    var roundsData = data.data;
    this.statusUpdate = data.key;
    this.userId = data.userId;
    this.notes = roundsData.notes;
    this.status = roundsData.status;
    this.roundsId = roundsData._id;
    this.chitStatusDetails = roundsData;
  }
  roundsStatusCompleted() {
    this.chitService.approveRoundsData(this.roundsId, { status: this.status })
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        this.dialogRef.close();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }
  chitStatusCompleted() {
    // var data = JSON.parse(this.chitStatusDetails.notification);
    // data.find(item => item._id == this.userId).status = this.status;
    // notification:JSON.stringify(data), 
    var data = this.chitStatusDetails.notification;
    this.chitService.approveData(this.roundsId, { notification: data, status: this.status, comments: this.notes })
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        this.dialogRef.close();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }
}
