import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ControllerService } from '../services/controller.service';
// import { KHASystemService } from '../services/systems/kha-systems.service';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  providers: [DatePipe]
})
export class CommentsComponent implements OnInit, AfterViewInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  // @Input() shipCompartment: any
  @Input() compComments: any
  // @Input() selectedKhaSystems: any
  // @Input() selectedGroupData: any
  @Input() selectedLayoutList: any
  @Input() LayoutMode: any;
  @Input() selectedTagItem: any;
  @Input() layoutHeading: any = "";
  commentsWindowState: boolean = true;
  isCommentTyped = false;
  addUserCard: boolean = false;
  addCommentCard: boolean = true;
  displayedColumns: string[];
  dataSource: any;
  usersDetails: any = [];
  userList: any = [];
  selection = new SelectionModel<any>(false, []);
  shipCompartmentDetails: any = [];
  isActivityTyped = false;
  compActivity: any = [];
  activityMessage: any;
  activityDetail: any = [];
  loginUserdata: { fName: any; lName: any; email: any; };
  activityMessageIdx: any;
  broadcastIframeSubs: any;
  deleteIdx: any;
  notificationNo: any;
  surveyNo: any;
  materialNo: any;
  status: any = {};
  statusId: any;
  adminEmailId: any;
  // public allColors: any[] = [
  //   { value: '#FF0000', name: 'BLOCKED' },
  //   { value: '#FFBF00', name: 'INPROGRESS' },
  //   { value: '#00FF00', name: 'COMPLETED' },

  // ];
  allColors: any;
  colorchanges: any;
  StatusName: any;
  startDate: any;
  broadcastSubs: any;
  formData: any = new FormData();
  uploadFile: any = [];
  fileExtension: any;
  userListStatus = false;
  selecteduser: any;
  approvalStatus: any = 0;
  filterDate: any = new Date("01-MAR-2021");
  approval = [{ "id": 0, name: "Not Required" }, { "id": 1, name: "Required" }]
  selectedTag: any;
  notesData: any;
  groupCollection: any;
  markerData: any;
  grpcolorchanges: any;
  groupName: any;
  groupId: any;
  group: { value: any; name: any; statusId: any; };
  commentsId: any = '';
  tagStatus: boolean = false;
  commentsData: any;
  location: any;
  constructor(public ctrlServ: ControllerService, public communicationServ: CommunicationService, private datePipe: DatePipe, private configService: ConfigService) {

  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngAfterViewInit(): void {
    this.getUserData();
    console.log("tagListComment", this.LayoutMode, this.selectedLayoutList);
    if (this.LayoutMode) {
      // this.getCompartmentDetails();
      console.log("tagListComment", this.selectedLayoutList);
      // if (this.selectedTagItem) {
      //   this.getLayoutTag()
      // }

    } else {
      // this.getCompartmentDetails();
    }


  }
  getUserData() {
    this.configService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
        console.log(this.usersDetails)
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        if (userData.length > 0) {
          this.loginUserdata = {
            "fName": userData[0]['First Name'],
            "lName": userData[0]['Last Name'],
            "email": userData[0].email
          }
          // this.userList.push(this.loginUserdata);
        }
      },
      error => {
        console.error('Request failed with error');
      })
  }
  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "sidepanel") {
          if (data.event == "dashboardComments") {
            if (data.selectedTag) {
              this.selectedTag = data.selectedTag.layoutName;
              this.selectedLayoutList = data.selectedLayoutList
              this.selectedTagItem = data.selectedTag;
              let statusCollection = data.selectedTag.statusCollection || [];
              this.allColors = [];
              statusCollection.forEach(sl => {
                let found = this.allColors.some(el => el.name.toLowerCase() === sl.name.toLowerCase())
                if (!found) {
                  this.allColors.push(sl);
                }
              })             
              this.groupCollection = data.selectedTag.groupCollection;
              this.markerData = data.markerData;
            }
          } else if (data.event == 'viewComments') {
            this.selectedLayoutList = data.data
            this.commentsId = data.commentsId;
            this.notesData = data.notes;
            this.getCommentsData();
          }
        }
        if (data.src === "navyApp") {
          if (data.event === 'statusData') {
            this.allColors = data.data;
            console.log("this.allColors", this.allColors);
            this.selectedLayoutList = data.selectedLayoutList
            this.commentsData = data.commentsData
            this.tagStatus = true;

            let selectSData = this.commentsData['status']
            this.colorchanges = selectSData.value;
            this.StatusName = selectSData.name;
            this.statusId = selectSData._id;
            this.status = {
              'value': this.colorchanges,
              "name": this.StatusName,
              'statusId': this.statusId
            }

            let selectGData = this.commentsData['group']
            this.grpcolorchanges = selectGData.color;
            this.groupName = selectGData.name;
            this.groupId = selectGData._id;
            this.group = {
              'value': this.grpcolorchanges,
              "name": this.groupName,
              'statusId': this.groupId
            }

            this.compComments = this.commentsData.description || '';
            this.isCommentTyped = true;

            this.commentsId = this.commentsData.typeCollectionId;
          }
          if (data.event == "layoutNotesData") {
            if (this.selectedTagItem) {
              this.selectedTagItem['tagComments'] = data.data
            }
          }
          if (data.event == "userList-add-appoved") {
            this.userList = data.data
          }
        }
        if (data.src == "ship-detail") {
          if (data.event == "babcock_sendLocation") {
            this.ctrlServ.isAddLocationState = false;
            this.isCommentTyped = true;
            this.commentsWindowState = true;
            this.location = data.data.position;
            console.log("location1112 ", data.location)
          }
        }
      })
    this.broadcastIframeSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "commentScreen") {
          if (data.event === 'deleteComment') {
            this.deleteComment()
          }
          if (data.event == 'closeUser') {
            this.UserChecked()
          }
          if (data.event == 'userListAddyes') {
            this.adduser()
          } if (data.event == "approvalStatus") {
            this.approvalStatus = data.data
          }
        }
        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.filterDate = this.datePipe.transform(data.sliderDate, 'yyy-MM-dd');
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.filterDate = this.datePipe.transform(data.endDate, 'yyy-MM-dd');
          }
        }
      })
  }
  deleteComment() {
    if (this.deleteIdx != undefined || this.deleteIdx != null) {
      this.configService.deleteData(this.configService.project_config.activityMessage + this.activityDetail[this.deleteIdx]._id).subscribe(result => {
      })
      this.activityDetail.splice(this.deleteIdx, 1)
    }
    this.deleteIdx = null;
  }
  onCancel(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false
    } else {
      this.isActivityTyped = false
    }
  }
  onComment(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else {
      this.isCommentTyped = false;
    }
  }

  onClose() {
    // this.cancel.emit(false)
    this.broadcastInfo({ src: 'commentData', event: 'closeCommentsForm', status: false });
  }

  onSave(data) {
    if (data == 'Comments') {
      this.isCommentTyped = false;
      this.saveData();
    } else {
      this.isActivityTyped = false;
      this.saveMessageData()
    }
  }

  onActivity(e) {
    if (e.target.value.trim() != '') {
      this.isActivityTyped = true;
      this.activityMessage = e.target.value
    } else {
      this.isActivityTyped = false;
    }
  }

  saveData() {
    var CommentScreendata = this.getCommentScreen();
    if (this.commentsId != "") {
      this.putData(CommentScreendata);
    } else {
      this.getCommentId();
    }
  }

  getCommentId(status = null) {
    var CommentScreendata = this.getCommentScreen();
    this.configService.postData(this.configService.project_config.commentsApi, CommentScreendata).subscribe(result => {
      console.log('CommentScreendata', result);

      let tagLocation, locationShape;

      if (status == 'activity') {
        this.commentsId = result.insertedId
        this.getMessageData()
      } else {
        this.commentsId = result.insertedId;
        if (this.tagStatus) {
          this.putData(CommentScreendata);
        } else {
          let tagLocation, markerId, markerData;
          if (this.markerData) {
            markerData = this.markerData.markerData || [];
            markerId = this.markerData.mname;
            tagLocation = this.markerData.location || this.location;
          } else {
            tagLocation = this.location;
          }
          locationShape = "avatar1";
          this.broadcastInfo({ src: 'commentData', event: 'createComments', CommentScreendata: CommentScreendata, commentsId: this.commentsId, filterDate: this.filterDate, typeCollection: 'Defects', seletedCollection: this.selectedTagItem, update: false, tagLocation: tagLocation, locationShape: locationShape, markerId: markerId, markerData: markerData });
          this.broadcastInfo({ src: 'commentData', event: 'closeCommentsForm', status: false });
        }
      }
    })
  }

  putData(CommentScreendata) {
    this.configService.putData(this.configService.project_config.commentsApi + this.commentsId, CommentScreendata).subscribe(result => {
      console.log('CommentScreendata', result);
      let tagLocation, markerId, markerData;
      if (this.markerData) {
        markerData = this.markerData.markerData || [];
        markerId = this.markerData.mname;
        tagLocation = this.markerData.location || this.location;
      } else {
        tagLocation = this.location;
      }
      let locationShape = "avatar1";
      this.broadcastInfo({ src: 'commentData', event: 'createComments', CommentScreendata: CommentScreendata, commentsId: this.commentsId, filterDate: this.filterDate, typeCollection: 'Defects', seletedCollection: this.selectedTagItem, update: true, tagLocation: tagLocation, locationShape: locationShape, selectTag: this.notesData, layOut: this.selectedLayoutList, markerId: markerId, markerData: markerData });
      this.broadcastInfo({ src: 'commentData', event: 'closeCommentsForm', status: false });
    })
  }

  getCommentsData() {
    const ObjLen = 0;
    this.configService.getApiCall(this.configService.project_config.commentsApi + this.commentsId).subscribe(result => {
      console.log('CommentScreendata', result);
      this.setFormValues(result[ObjLen])
    })
  }

  setFormValues(commentsData) {
    this.compComments = commentsData.comments ? commentsData.comments : '';
    this.activityDetail = commentsData.activityMessage ? commentsData.activityMessage : '';
    this.userList = commentsData.userList ? commentsData.userList : [];
    this.notificationNo = commentsData.notificationNo ? commentsData.notificationNo : [];
    this.surveyNo = commentsData.surveyNo ? commentsData.surveyNo : '';
    this.materialNo = commentsData.materialNo ? commentsData.materialNo : '';
    this.status = commentsData.status ? commentsData.status : {};
    this.startDate = commentsData.startDate ? commentsData.startDate : new Date();
    this.uploadFile = commentsData.uploadFile ? commentsData.uploadFile : [];
    this.statusId = commentsData.statusId ? commentsData.statusId : '';
    if (this.status) {
      this.colorchanges = this.status.value ? this.status.value : '';
      this.StatusName = this.status.name ? this.status.name : '';
    }
    this.grpcolorchanges = this.notesData.group ? this.notesData.group['color'] : '';
    this.groupName = this.notesData.group ? this.notesData.group['name'] : '';
  }

  saveDatas() {
    //   if (this.selectedTagItem && this.selectedTagItem.tagComments) {
    //     var CommentScreendata = this.getCommentScreen();
    //     this.broadcastInfo({ src: 'comment', event: 'update-comment-data', data: CommentScreendata });
    //   } else {
    //     var CommentScreendata = this.getCommentScreen();
    //     CommentScreendata = Object.assign({}, this.selectedTagItem, CommentScreendata)
    //     this.broadcastInfo({ src: 'comment', event: 'create-comment', data: CommentScreendata });
    //   }
    // // if (this.layoutHeading == '') {
    // //   var CommentScreendata = this.getCommentScreen()
    // //   this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutName', data: CommentScreendata });
    // // }
    // // else {
    // //   var CommentScreendata = this.getCommentScreen();
    // //   this.broadcastInfo({ src: 'comment', event: 'update-comment', data: CommentScreendata });
    // // }
  }
  getLayoutTag() {
    if (this.selectedLayoutList) {
      if (this.selectedLayoutList['tagPoints']) {
        if (this.selectedLayoutList['tagPoints'].length > 0) {
          this.selectedLayoutList['tagPoints'].forEach(element => {
            if (this.selectedTagItem.tagComments) {
              if (element.tagComments['_id'] == this.selectedTagItem.tagComments._id) {
                if (element.tagComments) {
                  console.log("comments", element, element.tagComments)
                  this.compComments = element.tagComments.description ? element.tagComments.description : '';
                  this.activityDetail = element.tagComments.activityMessage ? element.tagComments.activityMessage : '';
                  this.userList = element.tagComments.userList ? element.tagComments.userList : [];
                  // this.notificationNo = element.tagComments.notificationNo ? element.tagComments.notificationNo : [];
                  // this.surveyNo = element.tagComments.surveyNo ? element.tagComments.surveyNo : '';
                  // this.materialNo = element.tagComments.materialNo ? element.tagComments.materialNo : '';
                  this.status = element.tagComments.status ? element.tagComments.status : {};
                  this.startDate = element.tagComments.startDate ? element.tagComments.startDate : new Date();
                  this.uploadFile = element.tagComments.uploadFile ? element.tagComments.uploadFile : [];
                  // this.statusId = element.tagComments.statusId ? element.tagComments.statusId : '';
                  if (this.status) {
                    this.colorchanges = this.status.value ? this.status.value : '';
                    this.StatusName = this.status.name ? this.status.name : '';
                  }
                }
              }
            }

          });
        }
      }
    }
  }


  UserChecked() {
    if (this.selectedTagItem) {
      this.usersDetails.forEach(element => {
        this.userList.forEach(ele => {
          if (ele.email == element.email) {
            element['isChecked'] = true;
          }
        })
      });
    }
  }

  addUserSelected(selectData, $event: any) {
    //this.userListStatus = true;
    this.selecteduser = selectData

    $event.stopPropagation();
    if (this.userList.length != 0) {
      var userDataIdx = this.userList.findIndex(obj => obj.email == this.selecteduser.email)
      if (userDataIdx != -1) {
        this.userList.splice(userDataIdx, 1)
        this.saveData()
      } else {
        this.broadcastInfo({ src: 'material', event: 'userListAddConfrim', data: '' });
      }
    } else {
      this.broadcastInfo({ src: 'material', event: 'userListAddConfrim', data: '' });
    }
  }
  getCommentScreen() {
    var commentScreenData = {
      "comments": this.compComments,
      "status": this.status,
      'startDate': this.startDate ? this.startDate : new Date(),
      'userList': this.userList,
      "uploadFile": this.uploadFile,
      "statusId": this.statusId,
      'notificationNo': this.notificationNo,
      'surveyNo': this.surveyNo,
      'materialNo': this.materialNo,
      'group': this.group
    }
    console.log("this.shipCompartmentDetails[0]", commentScreenData)
    return commentScreenData
  }
  editActivity(i) {
    this.activityMessageIdx = i;
    this.deleteIdx = null;
  }
  saveActivity(i) {
    this.activityMessageIdx = null;
    this.activityDetail[i].Messagedate = new Date()
    this.configService.putData(this.configService.project_config.activityMessage + this.activityDetail[i]._id, this.activityDetail[i]).subscribe(result => {
      // this.getCompartmentDetails()
      this.getMessage()
      console.log("ade", result)
    })
  }
  deleteActivity(i) {
    this.broadcastInfo({ src: 'material', event: 'commentDelate-popup', data: "", sub: null });
    this.deleteIdx = i
  }
  CancelActivity() {
    this.activityMessageIdx = null
  }
  getDateTime() {
    var date = new Date();
    var dateTime = date.toString()
    var data = dateTime.split(' ')
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes > 10 ? 0 + minutes : minutes
    var FullDateTime = data[1] + " " + data[2] + "," + data[3] + " " + hours + ":" + minutes + " " + ampm
    return FullDateTime
  }

  statusChange(e) {
    var colr = e.split(' ')
    var selectData = this.allColors.filter(t => t._id == e);
    this.colorchanges = selectData[0].value;
    this.StatusName = selectData[0].name;
    this.statusId = selectData[0]._id;
    this.status = {
      'value': this.colorchanges,
      "name": this.StatusName,
      'statusId': this.statusId
    }
    this.isCommentTyped = true;
  }

  groupChange(e) {
    var grup = e.split(' ')
    var selectData = this.groupCollection.filter(t => t._id == e);
    this.grpcolorchanges = selectData[0].color;
    this.groupName = selectData[0].name;
    this.groupId = selectData[0]._id;
    this.group = {
      'value': this.grpcolorchanges,
      "name": this.groupName,
      'statusId': this.groupId
    }
    this.isCommentTyped = true;
  }


  notificationNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  surveyNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  materialNoValue(e) {
    if (e.target.value.trim() != '') {
      this.isCommentTyped = true;
    } else if (this.notificationNo && this.surveyNo && this.materialNo) {
      this.isCommentTyped = false;
    }
  }
  createStatus() {
    this.broadcastInfo({ src: "sidepanel", event: "createStatus", data: "" })
  }

  //selectFile(event) { //Angular 8
  selectFile(event: any) { //Angular 11, for stricter type
    // // if(!event.target.files[0] || event.target.files[0].length == 0) {
    // // 	this.msg = 'You must select an image';
    // // 	return;
    // // }

    // // var mimeType = event.target.files[0].type;

    // // if (mimeType.match(/image\/*/) == null) {
    // // 	this.msg = "Only images are supported";
    // // 	return;
    // // }

    // var reader = new FileReader();
    // reader.readAsDataURL(event.target.files[0]);

    // reader.onload = (_event) => {
    // 	this.msg = "";
    // 	this.url = reader.result; 
    // }
    console.log(event.target.files)
    for (var i = 0; i < event.target.files.length; i++) {
      this.formData.append('file', event.target.files[i])
    }
    if (this.formData.has('file')) {
      this.configService.postUploadData(this.configService.project_config.fileUpload, this.formData).subscribe(result => {
        this.formData = new FormData()
        if (result.uploadPath.length) {
          result.uploadPath.forEach(ele => {
            this.uploadFile.push(ele.uploadPath)
          })
          this.saveData()
          if (result.invaildData) {
            this.broadcastInfo({ src: 'material', event: 'uploadFailedWarning', data: result.invaildData });
          }
        } else {
          this.broadcastInfo({ src: 'material', event: 'uploadFailedWarning', data: result.invaildData });
        }
      })
    }
  }

  getExention(data) {
    this.fileExtension = data.split('/').pop().split('.')[1];
    var checkimg = (this.fileExtension.toLowerCase()).trim();
    if (checkimg.match(/(\jpg|\png|\JPG|\PNG|\jpeg|\JPEG|\mp4|\MP4|\flv|\FLV|\mkv|\MKV|\gif)$/)) {
      return true;
    } else if (checkimg.match(/(\pdf|\doc|\docx)$/)) {
      return false;
    }
  }

  deleteFile(data, i) {
    var fileDelete = {
      'filePath': data
    }
    this.configService.postUploadData(this.configService.project_config.fileUpload + '/delete', fileDelete).subscribe(result => {
      this.formData = new FormData()
      this.uploadFile.splice(i, 1)
      //this.uploadFile.push(result.uploadPath)
      this.saveData()
    })
  }

  getMessageData() {
    var messageData = {
      "notesId": this.commentsId,
      "layoutId": this.selectedLayoutList._id,
      "Messagedate": new Date(),
      "email": this.loginUserdata.email || "",
      "name": this.loginUserdata.fName + '' + this.loginUserdata.lName || "",
      "comments": this.activityMessage || '',
    }
    this.configService.postData(this.configService.project_config.activityMessage, messageData).subscribe(result => {
      this.getMessage()
      console.log("ade", result)
    })
  }
  saveMessageData() {
    if (this.commentsId == '') {
      this.commentsId = this.getCommentId('activity');
    } else {
      this.getMessageData();
    }
  }
  getMessage() {
    if (this.selectedTagItem.tagComments) {
      this.configService.getApiCall(this.configService.project_config.activityMessage + this.selectedTagItem.tagComments._id).subscribe(result => {
        this.activityDetail = result
      })
    }
  }
  adduser() {
    var userData: any = [];
    userData = this.selecteduser;
    var data = {
      "fName": userData['First Name'],
      "lName": userData['Last Name'],
      "email": userData.email,
      "Approval": this.approvalStatus
    }
    this.userList.push(data);
    this.saveData()
  }
  someMethod(event) {
    console.log(event)
    this.approvalStatus = event;
  }
  getDate(date) {
    return this.datePipe.transform(date, "MMM d, y, h:mm a")
  }
  userdataSocket() {
    var obj = {
      "userList": this.userList,
      "tagShapeId": this.selectedTagItem.tagShapeId,
      "layoutId": this.selectedTagItem.layoutId,
      "markerId": this.selectedTagItem.markerId,
      "AppName": "SDMS",
      "messageData": this.getMessageData(),
      "CommentScreendata": this.getCommentScreen(),
    }
    this.activityMessage = ""
    console.log(obj)
    this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj, email: this.loginUserdata.email });
  }

  pickLocation() {
    this.commentsWindowState = false;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = "Defects";
  }
}