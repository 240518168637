import { Injectable } from '@angular/core';
import { isString, isNumber } from 'util';
import { JsonService } from './json.service';
import { XldataService } from './xldata.service';
import { SocketdataService } from './socketdata.service';
import {io}  from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  setting = {
    baseUrl: "",
    xlapipath: "",
    actions: {
      keyDown: {

      }
    },
    camera: {},
    lights: {},
    materials: {
      all: ""
    },
    shapes: {},
    venue: ""
  }
  keyDown = {
    h: [],
    a: [],
    s: [],
    x: [],
    z: [],
    t: []
  }
  camera = {
    type: "",
    yaw: 1000,
    pitch: 1000,
    minDistance: 1000,
    maxDistance: 1000,
    distance: 1000,
    target: { x: 1000, y: 1000, z: 1000 },
    fov: 1000,
    inertia: 1000,
    groundLevel: 1000,
    tumbleSensitivity: 1000,
    trackSensitivity: 1000,
    wheelPrecision: 1000,
    views: {}
  }
  light = {
    type: "",
    direction: { x: 1000, y: 1000, z: 1000 },
    diffuseColor: { r: 1000, g: 1000, b: 1000 },
    specularColor: { r: 1000, g: 1000, b: 1000 },
    castShadow: false
  }
      
  object = {
    
  }
  
  constructor(private _JsonService: JsonService, private _XldataService: XldataService, private _SocketdataService : SocketdataService) { 
     
  }

  JsonPath = '../assets/dfx-static';
  xlPath:string;
  socketPath: string;
  socketport:string;
  key: string;
  project: string;

  setPaths(data){
    console.log('setpaths: ',data);
      this.JsonPath = data.JsonPath;
      this.xlPath = data.xlPath;
      this.socketPath = data.socketPath;
      this.socketport = data.socketport;
      this.key = "socketdata";
      this.project = data.project;
  }

  getPaths(srcType) {
    let apiPath = this.JsonPath;
    if(srcType == 'json') {
      apiPath = this.JsonPath;
    } else if(srcType == 'xl') {
      apiPath = this.xlPath;
    } else if(srcType == 'socket') {
      apiPath = this.socketPath;
    }
    return apiPath;
  }

  validateCanvasData(data) {
    this.setPaths(data);
    for (let m in this.setting) {     
      if (!data[m]) {
        console.log(m + ' is undefined');
      } else {
        if (m == 'actions') {
          for (let k in this.keyDown) {
            if (!data.actions.keyDown[k]) {
              console.log(k + ' key is undefined');
            } else if (!data.actions.keyDown[k][0].view) {
              console.log(k + ' key value is not define');
            } else if (!isString(data.actions.keyDown[k][0].view)) {
              console.log(k + ' key value is not a string');
            }
          }
        } else if (m == 'camera') {
          for (let c in this.camera) {
            if (!data.camera[c]) {
              console.log("camera's " + c + ' property is undefined');
            } else if (c == 'type' && !isString(data.camera[c])) {
              console.log("camera's " + c + " value is not a string");
            } else if (c == 'views') {
              for (let k in this.keyDown) {
                if (!data.camera[c][k]) {
                  console.log("camera's " + k + ' key is undefined');
                }
              }
            } else if (c == 'target') {
              for (let t in data.camera[c]) {
                if (!data.camera[c][t]) {
                  console.log("camera's " + c + ' ' + t + ' value is undefined');
                }
              }
            } else {
              if (!isNumber(c !== 'type' && data.camera[c])) {
                console.log("camera's " + c + " value is not a number");
              }
            }
          }
        } else if (m == 'lights') {
          for (let l in data.lights) {
            for (let t in this.light) {
              // data.lights[l][t]
            }
          }
        }
      }
    }
    return true;
  }

  validateAssetsData(data) {
    // for (let m in this.object) {
    //   if (!data[m]) {
    //     console.log(m + ' is undefined');
    //   } else {
    //     if (m == 'actions') {
    //       for (let k in this.keyDown) {
    //         if (!data.actions.keyDown[k]) {
    //           console.log(k + ' key is undefined');
    //         } else if (!data.actions.keyDown[k][0].view) {
    //           console.log(k + ' key value is not define');
    //         } else if (!isString(data.actions.keyDown[k][0].view)) {
    //           console.log(k + ' key value is not a string');
    //         }
    //       }
    //     } else if (m == 'camera') {
    //       for (let c in this.camera) {
    //         if (!data.camera[c]) {
    //           console.log("camera's " + c + ' property is undefined');
    //         } else if (c == 'type' && !isString(data.camera[c])) {
    //           console.log("camera's " + c + " value is not a string");
    //         } else if (c == 'views') {
    //           for (let k in this.keyDown) {
    //             if (!data.camera[c][k]) {
    //               console.log("camera's " + k + ' key is undefined');
    //             }
    //           }
    //         } else if (c == 'target') {
    //           for (let t in data.camera[c]) {
    //             if (!data.camera[c][t]) {
    //               console.log("camera's " + c + ' ' + t + ' value is undefined');
    //             }
    //           }
    //         } else {
    //           if (!isNumber(c !== 'type' && data.camera[c])) {
    //             console.log("camera's " + c + " value is not a number");
    //           }
    //         }
    //       }
    //     } else if (m == 'lights') {
    //       for (let l in data.lights) {
    //         for (let t in this.light) {
    //           // data.lights[l][t]
    //         }
    //       }
    //     }
    //   }
    // }
    return true;
  }

  validateObjectData(data) {
    return true;
  }

  validateObjectProperties(data) {
    return true;
  }

  validateAnimationData(data) {
    return true;
  }

  validateData(data){
    return true;
  }
  
  datauxSocket; 
  init_socket(_callback) {
    var _this = this;
    if(_this.socketPath && _this.socketport && _this.key) {
      _this.datauxSocket = io(_this.socketport);
      _this.datauxSocket.on(_this.key, function (data) {
          _callback(data);
      });
    }
  }

  getCanvasData(jsonFile, srcType, _callback, _datacallback) {
    let promise;
    var apiPath = this.getPaths(srcType);
    if (srcType === 'json') {
      promise = this._JsonService.getCanvasData(apiPath, jsonFile).then(
        res => {
          if (this.validateCanvasData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'xl') {
      promise = this._XldataService.getCanvasData(apiPath, jsonFile, this.project).then(
        res => {
          if (this.validateCanvasData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      promise = this._SocketdataService.getCanvasData(apiPath, data).then(
        res => {
          if (res['status'] === 'Success') {
            setTimeout (() => {
              _callback(true);         
            },5000);         
          }
        });
    } 
  }

  getAssetsData(jsonFile, srcType, _callback, _datacallback) {
    let promise;
    var apiPath = this.getPaths(srcType);
    if (srcType === 'json') {
      promise = this._JsonService.getAssetsData(apiPath, jsonFile).then(
        res => {
          if (this.validateAssetsData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'xl') {
      promise = this._XldataService.getAssetsData(apiPath, jsonFile, this.project).then(
        res => {
          if (this.validateAssetsData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      promise = this._SocketdataService.getAssetsData(apiPath, data).then(
        res => {
          if (res['status'] === 'Success') {
            _callback(true);
          }
        });
    } 
  }

  getObjectData(jsonFile, srcType, _callback, onGentrateAnimation, _datacallback) {
    let promise;
    var apiPath = this.getPaths(srcType);
    if (srcType === 'json') {
      promise = this._JsonService.getObjectData(apiPath, jsonFile).then(
        res => {
          if (this.validateObjectData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'xl') {
      promise = this._XldataService.getObjectData(apiPath, jsonFile, this.project).then(
        res => {
          if (this.validateObjectData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      promise = this._SocketdataService.getObjectData(apiPath, data).then(
        res => {
            if (res['status'] === 'Success') {
              setTimeout (() => {
                _callback(true);         
              },5000);   
            }
        });
    } 
  }

  getObjectProperties(jsonFile, srcType, _callback, _datacallback) {
    let promise;
    var apiPath = this.getPaths(srcType);
    if (srcType === 'json') {
      promise = this._JsonService.getObjectProperties(apiPath, jsonFile).then(
        res => {
          if (this.validateObjectProperties(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'xl') {
      promise = this._XldataService.getObjectProperties(apiPath, jsonFile, this.project).then(
        res => {
          if (this.validateObjectProperties(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      promise = this._SocketdataService.getObjectProperties(apiPath, data).then(
        res => {
          if (res['status'] === 'Success') {
            setTimeout (() => {
              _callback(true);         
            },5000);    
          }
        });
    } 
  }

  getAnimationData(jsonFile, srcType, _callback, _datacallback) {
    let promise;
    var apiPath = this.getPaths(srcType);
    if (srcType === 'json') {
      promise = this._JsonService.getAnimationData(apiPath, jsonFile).then(
        res => {
          if (this.validateAnimationData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'xl') {
      promise = this._XldataService.getAnimationData(apiPath, jsonFile, this.project).then(
        res => {
          if (this.validateAnimationData(res)) {
            _datacallback(res);
          }
        });
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      promise = this._SocketdataService.getAnimationData(apiPath, data).then(
        res => {
          if (res['status'] === 'Success') {
            setTimeout (() => {
              _callback(true);         
            },5000);    
          }
        });
    } 
  }

  getData(jsonFile, srcType, _callback, _datacallback) {
    if (srcType === 'json') {
      this.getJsonData(jsonFile, _datacallback);
    } else if (srcType === 'xl') {
      this.getXlData(jsonFile, _datacallback);
    } else if (srcType === 'socket') {
      const data = [{'name': jsonFile}];
      this.getSocketData(data, _callback);
    } 
  }

  getJsonData(jsonFile, _datacallback){
    let promise;
    promise = this._JsonService.getData(this.JsonPath, jsonFile).then(
      res => {
        if (this.validateData(res)) {
          _datacallback(res);
        }
      });
  }

  getXlData(jsonFile, _datacallback){
    let promise;
    promise = this._XldataService.getData(this.xlPath, jsonFile, this.project).then(
      res => {
        if (this.validateAnimationData(res)) {
          _datacallback(res);
        }
      });
  }

  getSocketData(data, _callback){
    let promise;
    promise = this._SocketdataService.updateStatus(this.socketPath, data).then(
      res => {
        if (res['status'] === 'Success') {
          setTimeout (() => {
            _callback(true);         
          },5000);    
        }
      });
  }

}
