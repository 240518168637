import { Component, EventEmitter, AfterViewInit, Input, OnInit, Output, HostListener } from '@angular/core';
import { FormControl } from "@angular/forms";
import { FileValidators } from "ngx-file-drag-drop";
import { ConfigService } from '../services/config.service';
import { CommunicationService } from "../services/communication.service";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import * as ProjectConfig from '../../assets/project.config.json';
import * as io from 'socket.io-client';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent implements OnInit, AfterViewInit {
  @Output() loadData: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() changePort: EventEmitter<any> = new EventEmitter<any>();
  @Input() isAppLoaded: boolean = false;
  @Input() activePort: any = 'cfbhalifax';
  planType: any = 'PTP';
  @Input() set onPlanType(v: any) {
    if (v) {
      this.planType = v;
      if (this.planType == 'GDP') {
        this.uploadPopupToggle()
      }
    }
  }

  formData = new FormData();
  broadcastSubs: any;
  process_txt = "Processing...";
  pcsDemos = []
  selectedDemo = this.pcsDemos[0] || "";
  showBlockLoading: boolean = false;
  portList = [{ key: 'cfbhalifax', name: 'CFB Halifax' }, { key: 'cfbesquimalt', name: 'CFB Esquimalt' }];
  setInterval: any;
  queryParams: any;
  planUploadStatus: boolean = true;
  activityUploadStatus: boolean = true;
  uploadedFiles: any = {};
  uploadPopupState: boolean = false;
  portSelection: any = '';
  halifaxState = false;
  esquimaltState = false;
  userId: any = '';
  userName: any = '';

  constructor(private communicationServ: CommunicationService, private configService: ConfigService, private translate: TranslateService, private language: LanguageService) {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
        }
      });
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    }) 
  }
  uploadPopupToggle() {
    this.uploadPopupState = !this.uploadPopupState;
    this.portSelection = '';
  }
  jetyyFileControl = new FormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(1)]
  );

  activityFileControl = new FormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(1)]
  );

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * * * *
  * method for get query param values from url
  * * * * * */
  getQueryParamValues() {
    if (window.location.search) {
      this.queryParams = window.location.search.split('?')[1].split('&');
      for (var i = 0; i < this.queryParams.length; i++) {
        let searchUrl = this.queryParams[i].split("=");
        if (searchUrl[0] == "planType") {
          this.planType = searchUrl[1].toUpperCase();
        }
        if (searchUrl[0] == "userId") {
          console.log(" userid ", searchUrl[1]);
          this.userId = searchUrl[1];
        }
        if (searchUrl[0] == "username") {
          console.log(" username ", searchUrl[1]);
          this.userName = decodeURIComponent(searchUrl[1]);
        }
      }
    }
    console.log("planType :", this.planType);
  }

  ngOnInit(): void {
    this.getQueryParamValues();
    // this.jetyyFileControl.valueChanges.subscribe((files: File[]) => { })
    //   // console.log(this.jetyyFileControl.value, this.jetyyFileControl.valid)
    // );
    // this.activityFileControl.valueChanges.subscribe((files: File[]) => { })
    //   // console.log(this.activityFileControl.value, this.activityFileControl.valid)
    // );
  }

  ngAfterViewInit() {
    this.placeHolderUpdate('both');
  }

  /* * * * *
  * init socket service
  * * * * * */
  socket
  init_socket() {
    console.log('plan init_socket');
    let socketPath = this.configService.project_config.socketport || 'http://localhost:3000';
    var _this = this;
    if (_this.userId) {
      let socketport = `${socketPath}?userId=${_this.userId}&username=${_this.userName}`;
      _this.socket = io.connect(socketport);
      _this.socket.on('connect', onConnect);
      _this.socket.on('disconnect', onDisconnect);
      _this.socket.on('connect_error', onError);
      _this.socket.on('reconnect_error', onError);

      function onConnect(evt) {
        console.log("plan socket connected!!");
        writeToScreen("CONNECTED");

        _this.socket.on("getNotification", data => {
          console.log("getNotification ", data);
          if (_this.userId === data['userid']) {
            _this.openPublishDialog.emit(data['message']);
          }
        });
      }

      function onDisconnect(evt) {
        writeToScreen("DISCONNECTED");
        console.log("socket disconnected!!");
      }

      function onError(message) {
        writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
      }

      function writeToScreen(message) {
        var pre = document.createElement("p");
        pre.style.wordWrap = "break-word";
        pre.innerHTML = message;
        //output.appendChild(pre);
      }

    }
  }


  placeHolderUpdate(e) {
    setTimeout(() => {
      if (e == 'plan') {
        var jettyPlanElem = document.getElementById('jettyplan');
        jettyPlanElem.children[0].innerHTML = 'Drag Jetty plan file or select file';
      }
      if (e == 'activity') {
        var actPlanElem = document.getElementById('activityplan');
        actPlanElem.children[0].innerHTML = 'Drag Activity plan file or select file';
      }
    }, 50);
    if (e == 'both') {
      var jettyPlanElem = document.getElementById('jettyplan');
      jettyPlanElem.children[0].innerHTML = 'Drag Jetty plan file or select file';
      var actPlanElem = document.getElementById('activityplan');
      actPlanElem.children[0].innerHTML = 'Drag Activity plan file or select file';
    }
  }

  /* * *
  * go button activie status
  * * */
  getActiveStatus() {
    return (this.activityUploadStatus === true && this.planUploadStatus === true && this.portSelection != '');
  }

  /* * *
  * reset plan file 
  * * */
  resetPlanFile() {
    console.log("resetPlanFile ");
    this.formData = new FormData();
    this.jetyyFileControl.reset();
    this.jetyyFileControl.setValue([]);
    this.placeHolderUpdate('plan');
    this.planUploadStatus = false;
  }

  /* * *
  * reset activity file 
  * * */
  resetActivityFile() {
    console.log("resetActivityFile ");
    this.formData = new FormData();
    this.activityFileControl.reset();
    this.activityFileControl.setValue([]);
    this.placeHolderUpdate('activity');
    this.activityUploadStatus = false;
  }

  /* * *
  * reset both file 
  * * */
  resetFile() {
    this.formData = new FormData();
    this.planUploadStatus = false;
    this.activityUploadStatus = false;
    this.jetyyFileControl.reset();
    this.activityFileControl.reset();
    this.jetyyFileControl.setValue([]);
    this.activityFileControl.setValue([]);
  }

  /* * *
  * plan file upload 
  * * */
  onPlanValueChange(file: File[], fileType) {
    if (file.length > 0) {
      console.log(file, "File changed!", fileType);
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file[0].name)[1];
      if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
        this.formData.append('port', this.activePort);
        this.formData.append('type', this.planType);
        this.formData.append('file', file[0]);
        this.showBlockLoading = true;
        let planApi = this.configService.project_config.planUpload;
        this.configService.uploadExcelData(planApi, this.formData, this.planUploadSuccess);
      } else {
        this.openPublishDialog.emit('Unknown File Format !!!');
        this.resetPlanFile();
        return;
      }
    } else {
      this.planUploadStatus = false;
      this.placeHolderUpdate(fileType);
    }
  }

  /* * *
  * plan file uploaded successfully
  * * */
  planUploadSuccess = ((response) => {
    this.showBlockLoading = !true;
    let isJson = this.hasJsonStructure(response);
    if (isJson) {
      response = JSON.parse(response);
      console.log("plan response : ", response);
      if (response['result'] == 'success') {
        this.uploadedFiles['planFile'] = response['filets'];
        this.planUploadStatus = true;
      } else {
        this.resetPlanFile();
        this.openPublishDialog.emit('Unknown File Format !!!');
      }
    } else {
      this.resetPlanFile();
      this.openPublishDialog.emit('Unknown File Format !!!');
    }
  });

  /* * *
  * activity file upload 
  * * */
  onActivityValueChange(file: File[], fileType) {
    if (file.length > 0) {
      console.log(file, "File changed!", fileType);
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file[0].name)[1];
      if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
        this.formData.append('port', this.activePort);
        this.formData.append('type', this.planType);
        this.formData.append('file', file[0]);
        this.showBlockLoading = true;
        let activityApi = this.configService.project_config.activityUpload;
        this.configService.uploadExcelData(activityApi, this.formData, this.activityUploadSuccess);
      } else {
        this.openPublishDialog.emit('Unknown File Format !!!');
        this.resetActivityFile();
        return;
      }
    } else {
      this.activityUploadStatus = false;
      this.placeHolderUpdate(fileType);
    }
  }

  /* * *
  * activity file uploaded successfully
  * * */
  activityUploadSuccess = ((shipObjects) => {
    this.showBlockLoading = !true;
    let isJson = this.hasJsonStructure(shipObjects);
    if (isJson) {
      shipObjects = JSON.parse(shipObjects);
      console.log("activity response : ", shipObjects);
      if (shipObjects) {
        this.uploadedFiles['activityFile'] = shipObjects['filets'];
        this.activityUploadStatus = true;
      } else {
        this.resetActivityFile();
        this.openPublishDialog.emit('Unknown File Format !!!');
      }
    } else {
      this.resetActivityFile();
      this.openPublishDialog.emit('Unknown File Format !!!');
    }
  });

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  /* * *
  * load data from uploaded file
  * * */
  loadExcelData() {
    this.showBlockLoading = true;
    let api = this.configService.project_config.xlUploadedData;
    let obj = Object.assign({}, this.uploadedFiles, { type: this.planType, port: this.activePort });
    console.log("loadExcelData ", obj);
    this.configService.getData(api, obj, this.excelUploadSuccess);
  }

  excelUploadSuccess = ((shipObjects) => {
    console.log("upload response : ", shipObjects);
    if (shipObjects) {
      if (!shipObjects['result']) {
        let uploadFileJson = shipObjects['filets'];
        if (shipObjects['ships'].length > 0) {
          let obj = { name: 'upload', metaData: shipObjects, planType: this.planType, uploadedJsonFile: uploadFileJson };
          console.log("excelUploadSuccess ", obj);
          this.pushMetaData(obj);
        } else {
          this.showBlockLoading = !true;
          this.resetFile();
          this.openPublishDialog.emit('No Metadata to Process !!');
        }
      } else {
        this.showBlockLoading = !true;
        this.resetFile();
        this.openPublishDialog.emit('Invalid Data !!');
      }
    } else {
      this.showBlockLoading = !true;
      this.resetFile();
      this.openPublishDialog.emit('Invalid Data !!');
    }

  });

  /**
   * method for check if main app is loaded
   */
  pushMetaData(obj) {
    let animation = (timer) => {
      if (this.isAppLoaded) {
        setTimeout(() => { this.showBlockLoading = !true }, 500)
        this.loadData.emit(obj);
        cancelAnimationFrame(this.setInterval);
      } else {
        this.setInterval = window.requestAnimationFrame(animation);
      }
    }
    this.setInterval = window.requestAnimationFrame(animation);
  }

  /* * *
  * load port
  * * */
  loadPortData(e) {
    this.showBlockLoading = true;
    this.portSelect({ 'value': e });
    setTimeout(() => {
      this.loadDemoData();
    }, 500);
  }

  /* * *
  * data load from server
  * * */
  loadDemoData() {
    let obj = { name: 'server', metaData: [], planType: 'DEMO' }
    if (!this.showBlockLoading) {
      this.showBlockLoading = true;
    }
    this.pushMetaData(obj);
  }

  drop(e) {
    console.log(e);
  }

  portToggle(e, key) {
    this.portSelect({ 'value': key });
    if (key == 'cfbesquimalt') {
      // if(e.checked){
      this.halifaxState = false;
      this.esquimaltState = true;
      this.portSelection = key;
      this.portSelect({ value: key })
      // }else{
      //   this.esquimaltState=false;
      //   if(!this.halifaxState){
      //     this.portSelection='';
      //   }

      // }
    }
    
    if (key == 'cfbhalifax') {
      // if(e.checked){
      this.halifaxState = true;
      this.esquimaltState = false;
      this.portSelection = key;
      this.portSelect({ value: key })
      // }else{
      //   this.halifaxState=false;
      //   if(!this.esquimaltState){
      //     this.portSelection='';
      //   }
      // }
    }
  }

  portSelect(e) {
    console.log(e)
    this.resetFile();
    this.activePort = e.value;
    let val = this.activePort.split(" ").join("").toLowerCase();
    this.changePort.emit(val);
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    console.log(event, document.getElementById('popupUpload').style.display)
    let parentIdx = event.path.findIndex(e => e.id == 'popupUpload')
    let idx = event.path.findIndex(e => e.id == 'popupUpload_child')
    if (idx == -1 && parentIdx > -1) {
      // document.getElementById('pyramid_popup').style.display='none'
      // this.uploadPopupToggle()
    }
    // var element = document.getElementById('pyramid_popup')
    // if(this.nativeElement.contains(event.target)) {
    //   this.text = "clicked inside";
    // } else {
    //   this.text = "clicked outside";
    // }
  }
}



