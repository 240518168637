import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { ChitService } from '../services/chit.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-turnover-sheet-card',
  templateUrl: './turnover-sheet-card.component.html',
  styleUrls: ['./turnover-sheet-card.component.scss']
})
export class TurnoverSheetCardComponent implements OnInit, AfterViewInit {
  orderList: any = [1, 2, 3, 4, 5, 6, 7, 8];
  broadcastSubs: any;
  layoutData: any = [];
  sidepanelLayoutData: any = [];
  sidepanelNotesData: any = [];
  sidepanelStatusObject: any = [];
  sidepanelStatusData: any = [];
  turnOverFormList: any = [{
    name: "OOD", formFields: [
      { 'label': "Officer of the Day", 'field': "OOD1", 'value': "",'col':12,'scol':12  },
      { 'label': "FP State", 'field': "FBstate", 'value': "", 'type': "toggle", 'col':12,'scol':4 },
      { 'label': "Date", 'field': "oodDate", 'value': "", 'type': "date", 'col':6,'scol':6  },
      { 'label': "Location", 'field': "location", 'value': "", 'col':6,'scol':4  },
      { 'label': "2OOD", 'field': "OOD2", 'value': "", 'col':6 ,'scol':4  },
      { 'label': "DPO", 'field': "dpo", 'value': "", 'col':6 ,'scol':4  },

      // { 'label': "Personnel", 'field': "personnel", 'value': "", 'col':6,'scol':4  },
      // { 'label': "Duty Coxn", 'field': "duty-coxn", 'value': "", 'col':6 ,'scol':4  },
      // { 'label': "Duty Tech", 'field': "duty-tech", 'value': "", 'col':6 ,'scol':4  },
      // { 'label': "MSOD", 'field': "msod", 'value': "", 'col':6 ,'scol':4  },
      // { 'label': "FPO", 'field': "fpo", 'value': "", 'col':6 ,'scol':4  },
      // { 'label': "DAIRO", 'field': "dairo", 'value': "", 'col':6 ,'scol':4  },
      // { 'label': "Duty Cheif", 'field': "duty-cheif", 'value': "", 'col':6 ,'scol':4  }
    ], order: 1
  },
  {
    name: "Admin/ceremonial",
    formFields: [
      { 'label': "SCOPA", 'field': "scopa", 'value': "",'col':12,'scol':12  },
      { 'label': "RDS", 'field': "rds", 'value': "",'col':12 ,'scol':4 },
      { 'label': "Sunrise", 'field': "sunrise", 'value': "",'type': "time",'col':6 ,'scol':4  },
      { 'label': "Sunset", 'field': "sunset", 'value': "",'type': "time",'col':6 ,'scol':4  },
      { 'label': "Routine", 'field': "routine", 'value': "", 'type': "dropdown",'col':12 ,'scol':4 },

      // { 'label': "Adjacent Ships", 'field': "adjacent-ships", 'value': "",'col':6 ,'scol':6 },
      // { 'label': "FP Boats", 'field': "FPBoats", 'value': "",'col':6 ,'scol':6 },
      //     { 'label': "Ceremonial", 'field': "ceremonial", 'value': "",'col':6 ,'scol':4},
      // { 'label': "Passing Ships", 'field': "passing-ships", 'value': "",'col':6,'scol':6 },
      // { 'label': "Harbour Master", 'field': "harbour-master", 'value': "",'col':6 ,'scol':6}
    ], order: 2
  },
  {
    name: "Equipment", formFields: [
      { 'label': "Ship's Vehicle", 'field': "ship-vehicle", 'value': "",'col':12 ,'scol':12},
      { 'label': "OOD Cellphone", 'field': "ood-cellphone", 'value': "", 'type': "number",'col':12,'scol':4 },
      // { 'label': "Charged", 'field': "charged", 'value': "", 'type': "toggle",'col':12,'scol':4 },
      { 'label': "FP Box", 'field': "FBBox", 'value': "",'col':12,'scol':4 },
      { 'label': "QM Equipment", 'field': "QM-equipment", 'value': "",'col':12,'scol':12 },
      { 'label': "OOD Pack", 'field': "ood-pack", 'value': "",'col':12,'scol':4 },
      { 'label': "Exercise Log", 'field': "exercise-log", 'value': "",'col':12,'scol':12 },
      { 'label': "Roundsbook", 'field': "roundsbook", 'value': "",'col':6 ,'scol':4},
      { 'label': "Log Book", 'field': "log-book", 'value': "",'col':6 ,'scol':4},
      { 'label': "Important Message Traffic", 'field': "important-msg-traffic", 'value': "", 'type': "textarea",'col':12,'scol':12 },
      { 'label': "Keys", 'field': "keys", 'value': "", 'type': "textarea",'col':12,'scol':12 }], order: 3
  },
  {
    name: "Engineering", formFields: [
      { 'label': "Power", 'field': "power", 'value': "", 'type': "toggle",'col':6,'scol':4 },
      { 'label': "Freshwater", 'field': "freshwater", 'value': "", 'type': "toggle",'col':6,'scol':4 },
      { 'label': "Steam", 'field': "stream", 'value': "", 'type': "toggle",'col':6,'scol':4 },
      { 'label': "NFP", 'field': "nfp", 'value': "",'col':12,'scol':4 },
      { 'label': "Generators", 'field': "Generators", 'value': "", 'type': "toggle",'col':12 ,'scol':4},
      
      { 'label': "Fire pump", 'field': "fire-pump", 'value': "", 'type': "toggle",'col':12,'scol':4 },
      { 'label': "MD", 'field': "md", 'value': "", 'type': "radio",'col':12 ,'scol':4},
      { 'label': "DD", 'field': "dd", 'value': "", 'type': "radio",'col':12,'scol':4 },
      { 'label': "FM Pressure", 'field': "eng-FM-pressure", 'value': "", 'type': "toggle",'col':6 ,'scol':4},
      // { 'label': "Upper Deck Hydrants", 'field': "upper-deck-hydrants", 'value': "", 'type': "toggle",'col':6,'scol':12 },
      // { 'label': "Notes (Defects/Work)", 'field': "defects-notes", 'value': "", 'type': "textarea",'col':12,'scol':12 }
    ], order: 4
  },
  {
    name: "Deck", formFields: [
      { 'label': "Lines", 'field': "lines", 'value': "", 'type': "toggle",'col':12,'scol':12 },
      //
      { 'label': "FM Pressure", 'field': "Deck-FM-pressure", 'value': "", 'type': "toggle",'col':6,'scol':4 },
      // { 'label': "Where in water", 'field': "rib-water", 'type': "hidden",'value': "",'col':6,'scol':4 },

      
      { 'label': "Anchor", 'field': "anchor", 'value': "", 'type': "toggle",'col':12,'scol':4 },
      { 'label': "Meter", 'field': "anchor-meter", 'value': "", 'type': "hidden",'col':12 ,'scol':4},

      { 'label': "Rib", 'field': "rib", 'value': "", 'type': "toggle",'col':12,'scol':4 },
      { 'label': "Where in water", 'field': "rib-water", 'type': "hidden",'value': "",'col':12,'scol':4 },
      { 'label': "S Zodiac", 'field': "s-zodiac", 'type': "toggle",'value': "",'col':6,'scol':4 },
      { 'label': "P Zodiac", 'field': "p-zodiac", 'type': "toggle",'value': "",'col':6 ,'scol':4},
      { 'label': "Where in water", 'field': "s-water", 'value': "", 'type': "hidden",'col':6,'scol':4 },
      { 'label': "Where in water", 'field': "p-water", 'value': "",'type': "hidden",'col':6,'scol':4 },

      // { 'label': "Hurricane Hawser", 'field': "hurricane-hawser", 'value': "", 'type': "toggle",'col':6,'scol':6 },
      
      // { 'label': "Notes", 'field': "deck-notes", 'value': "", 'type': "textarea",'col':12,'scol':12 }
    ], order: 5
  },
  {
    name: "Other Activities", formFields: [
      { 'label': "Other Activities ", 'field': "other-activities", 'value': "",'col':12,'scol':12 }], order: 6
  },
  { name: "Notes", formFields: [{ 'label': "Notes", 'field': "notes", 'value': "", 'type': "textarea",'col':12,'scol':12 }], order: 7 },
  {
    name: "Signature", formFields: [
      { 'label': "Send to CO", 'field': "co", 'value': "",'type': "toggle",'col':6,'scol':6 },
      { 'label': "Send to XO ", 'field': "xo", 'value': "",'type': "toggle",'col':6,'scol':6 },
      // { 'label': "Off-Going OOD", 'field': "off-going-ood", 'value': "",'type': "toggle",'col':6 },
     
      { 'label': "Send to On-Coming OOD", 'field': "on-coming-ood", 'value': "",'type': "toggle",'col':12,'scol':12 },
      // { 'label': "Send to XO ", 'field': "xo", 'value': "",'col':6 },
      { 'label': "What is your grandmother's last name? ", 'field': "your-answer", 'value': "",'col':12,'scol':12 },
      { 'label': "Time Submitted", 'field': "submitted-time", 'value': "", 'type': "date", 'col':12 ,'scol':6},], order: 8
      
    }];
  // turnoversheetForm=[{
  //      name: "turnover", formFields: [
  //       { 'label': "Officer of the Day", 'field': "ood_OOD", 'value': "", 'type': "date",'col':6 },
  //       { 'label': "Date", 'field': "ood_oodDate", 'value': "", 'type': "date",'col':6 },
  //       { 'label': "Location", 'field': "ood_location", 'value': "",'col':6 },
  //       { 'label': "FB State", 'field': "ood_FBstate", 'value': "", 'type': "dropdown",'col':6 },
  //       { 'label': "2OOD", 'field': "ood_OOD2", 'value': "",'col':6 },
  //       { 'label': "DPO", 'field': "ood_DPO", 'value': "",'col':6 },
        
        
  //       { 'label': "SCOPA", 'field': "admin_scopa", 'value': "",'col':6 },
  //       { 'label': "RDS", 'field': "admin_rds", 'value': "",'col':6 },
  //       { 'label': "Routine", 'field': "admin_routine", 'value': "", 'type': "dropdown",'col':6 },
  //       { 'label': "Sunrise", 'field': "admin_sunrise", 'value': "",'col':6 },
  //       { 'label': "Sunset", 'field': "admin_sunset", 'value': "",'col':6 },
  //       { 'label': "Ceremonial", 'field': "admin_ceremonial", 'value': "",'col':6 },
  //       { 'label': "Adjacent Ships", 'field': "admin_adjacent-ships", 'value': "",'col':6 },
  //       { 'label': "Passing Ships", 'field': "admin_passing-ships", 'value': "",'col':6 },
  //       { 'label': "FP Boats", 'field': "admin_FPBoats", 'value': "",'col':6 },


  //     { 'label': "Ship's Vehicle", 'field': "Equipment_ship-vehicle", 'value': "",'col':6 },
  //     { 'label': "OOD Cellphone", 'field': "Equipment_ood-cellphone", 'value': "", 'type': "number",'col':6 },
  //     { 'label': "FB Box", 'field': "Equipment_FBBox", 'value': "",'col':6 },
  //     { 'label': "QM Equipment", 'field': "Equipment_QM-equipment", 'value': "",'col':6 },
  //     { 'label': "OOD Pack", 'field': "Equipment_ood-pack", 'value': "",'col':6 },
  //     { 'label': "Exercise Log", 'field': "Equipment_exercise-log", 'value': "",'col':6 },
  //     { 'label': "Roundsbook", 'field': "Equipment_roundsbook", 'value': "",'col':6 },
  //     { 'label': "Log Book", 'field': "Equipment_log-book", 'value': "",'col':6 },
  //     { 'label': "Important Message Traffic", 'field': "Equipment_important-msg-traffic", 'value': "", 'type': "textarea" },
  //     { 'label': "Keys", 'field': "Equipment_keys", 'value': "", 'type': "textarea",'col':6 },

  //     { 'label': "Freshwater", 'field': "Engineering_freshwater", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "Power", 'field': "Engineering_power", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "Stream", 'field': "Engineering_stream", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "NFP", 'field': "Engineering_nfp", 'value': "",'col':6 },

  //     { 'label': "Generators", 'field': "Engineering_Generators", 'value': "", 'type': "dropdown",'col':6 },
  //     { 'label': "Jokey", 'field': "Engineering_jockey", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "DD", 'field': "Engineering_jockey-dd", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "FM Pressure", 'field': "Engineering_FM-pressure", 'value': "", 'type': "toggle",'col':6 },
  //     { 'label': "Upper Deck Hydrants", 'field': "Engineering_upper-deck-hydrants", 'value': "", 'type': "toggle",'col':6 },
      
  //     { 'label': "MD", 'field': "Engineering_fire-pump-md", 'value': "",'col':6 },


  // ]}

  // ];
  activeTag: any;
  @Input() activeLayoutId: any;
  layoutAxis: any = [
    { left: "3", top: "2", right: "", bottom: "" },
    { left: "3", top: "35", right: "", bottom: "" },
    { left: "3", top: "", right: "", bottom: "3" },
    { left: "27", top: "", right: "", bottom: "3" },
    { left: "", top: "", right: "27", bottom: "3" },
    { left: "", top: "", right: "4", bottom: "3" },
    { left: "", top: "35", right: "4", bottom: "3" },
    { left: "", top: "2", right: "4", bottom: "" }]// left = 0-100%, top = 0-100%, right = 0-100%, bottom = 0-100%

  selectedStateBoardData: any;
  orderListMode: any = 'view';
  userRoleFilter: any;
  stateboardList = [];
  selected:any = 'MD';
  selectedDD = 'DD';
  constructor(private chitService: ChitService,private configService: ConfigService, private datepipe: DatePipe, private communicationServ: CommunicationService, private fb: FormBuilder) {
    this.arrangeOrder();

  }

  supervisorListData:any=[];
  supervisorList() {
    this.configService.getProjectConfig().then((project_config: any) => {
    // this.chitService.getOodData()
    this.chitService.getScheduleData()
      .subscribe(
        (response) => {
          var repos = response;
          this.supervisorListData = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
      });
  }
  /* * *
 * get State board Form
 * * */
  getStateBoardFormDataList: any = [];
  getStateBoardDataForm(formId = undefined) {
    this.configService.getStateBoardFormListData().then((response: any) => {
      this.intervalState = false;
      console.log("getStateBoardForm", response);
      this.getStateBoardFormDataList = response;
      // this.stateBoardDataList = response.filter(e => e.port == this.cfbport);
      // if(this.getStateBoardFormDataList.length>0){
      //   this.getStateBoardFormDataList.forEach(e=>{
      //     this.deleteTurnoverForm(e._id);
      //   })
      // }
      formId=formId?formId:this.selectedStateBoardForm?this.selectedStateBoardForm._id:undefined;
      if(formId){
        this.getTurnoverSheet(formId);
      }
     

    }).catch((error) => {
      // console.log("error ", error);
      // cbck(error)
    });
  }

  /* * *
  * Create State board Form
  * * */
  createStateBoardForm(data, cbck) {
    this.configService.createStateBoardForm(data).then(data => {
      if (data) {
        console.log("Created StateBoard form", data);
        this.getStateBoardDataForm();
        cbck(data);
      }
    }).catch((error) => {
      // console.log("error ", error);
      cbck(error);
    });
  }

  /* * *
  * Update State board Form
  * * */
  updateStateBoardForm(data) {
    console.log("update", data);
    if (data) {
      this.configService.updateStateBoardForm(data._id, data).then(data => {
        if (data) {
          console.log("stateborad updated ", data);
          this.getStateBoardDataForm();
          // cbck(data)
        }
      }).catch((error) => {
        // console.log("error ", error);
        // cbck(error)
      });
    }

  }

  /* * *
  * Delete State board Form
  * * */
  // deleteStateBoardForm(_id) {
  //   this.configService.deleteStateBoardForm(_id).then(data => {
  //     if (data) {
  //       console.log("group deleted ", data);
  //       this.getStateBoardDataForm();
  //     }
  //   }).catch((error) => {
  //     // console.log("error ", error);
  //   });
  // }


  onChangeFirepump(e){
    console.log(this.dynamicForm.value)
    if(e=='md'){

    }
  }
  /* * *
  * Create Form builder
  * * */
  dynamicForm: FormGroup;
  fields: any = []
  formCreate() {
    this.dynamicForm = this.fb.group(this.buildForm());
  }

  /* * *
  * Create dynamic Form
  * * */
  buildForm() {
    var fields = {};
    this.fields = this.selectedFormData.formFields;
    if (this.fields.length > 0) {
      this.fields.forEach(e => {
        if(e.field=='OOD1'&&e.value==''){
          fields[e.field] = [this.selectedStateBoardForm?this.selectedStateBoardForm['assignUserID']:''];
        }else if(e.field=='oodDate'&&e.value==''){
          fields[e.field] = [this.selectedStateBoardForm?this.selectedStateBoardForm['insertDate']:''];
        }else if(e.field=='submitted-time'&&e.value==''){
          var dateSub:any = this.selectedStateBoardForm?new Date(this.selectedStateBoardForm['insertDate']):'';
         if(dateSub){
          dateSub.setDate(dateSub.getDate() + 1);
         }
          fields[e.field] = [dateSub];
        }else{
          fields[e.field] = [e.value];
        }
        // if(e.field=='md'||e.field=='dd'||e.field=='anchor-meter'||e.field=='s-water'||e.field=='p-water'||e.field=='rib-water'){
          
        // }else{
          // fields[e.field] = [e.value, [Validators.required]];
       // }
      
      })
      if (this.assignUpdateDataList.length > 0) {
        var signForm = this.assignUpdateDataList.filter(ab => ab.name == "Signature");
        var data = signForm[0];
        data.formFields.forEach(element => {
          if (!element.value) {
            if (element.field == 'co') {
              if (element.value == 'no') {
                const index = this.userListNotification.findIndex(user => user.Role == 'CO'); 
                this.userListNotification.splice(index, 1);
              }
            }
            if (element.field == 'xo') {
              if (element.value == 'no') {
                const index = this.userListNotification.findIndex(user => user.Role == 'URFSO'); 
                this.userListNotification.splice(index, 1);
              }
            }
            if (element.field == 'on-coming-ood') {
              if (element.value == 'no') {
                const index = this.userListNotification.findIndex(user => user.Role == 'OOD'); 
                this.userListNotification.splice(index, 1);
              }
            }
          }
          
        });
        // if (this.assignUpdateDataList.name.formFields) {
          
        // }
      }
    }
    console.log("buildForm", fields, this.userListNotification);
    return fields;

  }

  /* * *
  * Open Form model
  * * */
  formStateName: any;
  selectedFormData: any;
  formState: any = false;
  openForm(form) {
    this.selectedFormData =form;// this.turnoversheetForm[0];//;
    this.formState = true;
    this.formCreate();
    // if (form.name == 'Signature') {
    //   var statusData = []
    //   this.formComplition.forEach(element => {
    //     if (element.status == true) {
    //       statusData.push(element)
    //     }
    //   });
      
    //   if (statusData.name == 'OOD' && statusData.name == 'Admin/ceremonial' && statusData.name == 'Equipment' && statusData.name == 'Engineering' && statusData.name == 'Deck' && statusData.name == 'Other Activities' && statusData.name == 'Notes') {
    //     this.formState = true;
    //   this.formCreate();
    //   }else{
    //     this.broadcastInfo({ src: 'sidepanel', event: 'turnoverNotifyPopup', data: "please complete other forms." });
    //   }
    // }else{
    //   this.formState = true;
    //   this.formCreate();
    // }

  }

  /* * *
  * Close Form model
  * * */
  closeForm() {
    this.formState = false;
    delete this.formStateName;
    // delete this.selectedFormData;
  }

  /* * *
  * Save Form Data
  * * */
  save() {
    let fields = Object.keys(this.dynamicForm.value);
    var data = [];
    if (fields.length > 0) {
      fields.forEach(e => {
        let idx = this.selectedFormData['formFields'].findIndex(ele => ele['field'] == e);
        if (idx > -1) {
          this.selectedFormData['formFields'][idx]['value'] = this.dynamicForm.value[e];
        }
        data = this.selectedFormData['formFields'];
      })
    }

    let formData = { "name": this.selectedFormData['name'], "formFields": data };
    console.log(this.dynamicForm.value, formData);
    if (this.selectedFormData['_id']) {
      formData['_id'] = this.selectedFormData['_id'];
      this.updateStateBoardForm(formData);
    } else {
      this.createStateBoardForm(formData, (obj) => {
        let updateData = { _id: obj['insertedId'], name: this.selectedFormData['name'], order: this.selectedFormData['order'] };
        // this.broadcastInfo({ src: "turnoverSheet", event: "updatedStateboard", data: updateData });
        this.updateTurnoverForm(updateData);
      });
    }
    if (this.selectedFormData['name'] == 'Signature') {
      this.userdataSocket();
    }
    if (this.selectedFormData['name'] == 'Notes') {
      if(this.activeNotesList.length>0&&this.selectedStateBoardId){
        let idx=this.activeNotesList.findIndex(e=>e['typeCollectionId']==this.selectedStateBoardId);
        if(idx>-1){
          console.log("turnover-Notes",formData);
          this.activeNotesList[idx]['description']=formData['formFields'][0]['value'];
          this.broadcastInfo({ src: 'turnoverSheet', event: 'turnoverUpdateNotes', data:this.activeNotesList[idx]});
        }
  
      }

    }
    this.closeForm();
  }
  getNotificationUser(fields, value){
    console.log(fields, value)
    this.userListNotification
    var userData = '';
    let role = fields =='xo'?'URFSO':fields=='on-coming-ood'?'OOD':fields;

  if (fields == 'co') {
    if (value == 'no') {
      if(this.userListNotification.length>0){
        const index = this.userListNotification.findIndex(user => user.Role == 'CO'); 
        if (index !== -1) {
          this.userListNotification.splice(index, 1);
        }
      }
    }
    if (value == 'yes') {
      const index = this.userList.filter(user => user.Role == 'CO');
      if(this.userListNotification.length>0){
        const data = this.userListNotification.filter(user => user.Role == 'CO');
        if (data.length == 0&&index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }else{
        if (index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }
    }
  }
  if (fields == 'xo') {
    if (value == 'no') {
      if(this.userListNotification.length>0){
        const index = this.userListNotification.findIndex(user => user.Role == 'URFSO'); 
        if (index !== -1) {
          this.userListNotification.splice(index, 1);
        }
      }
    }
    if (value == 'yes') {
      const index = this.userList.filter(user => user.Role == 'URFSO');
      if(this.userListNotification.length>0){
        const data = this.userListNotification.filter(user => user.Role == 'URFSO');
        if (data.length == 0&&index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }else{
        if (index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }

    }
  }
  if (fields == 'on-coming-ood') {
    if (value == 'no') {
      if(this.userListNotification.length>0){
        const index = this.userListNotification.findIndex(user => user.Role == 'OOD'); 
        if (index !== -1) {
          this.userListNotification.splice(index, 1);
        }
      }
    }
    if (value == 'yes') {
      const index = this.userList.filter(user => user.Role == 'OOD');
      if(this.userListNotification.length>0){
        const data = this.userListNotification.filter(user => user.Role == 'OOD');
        if (data.length == 0&&index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }else{
        if (index.length > 0) {
          this.userListNotification.push(index[0])
        }
      }

    }
  }
    console.log(this.userListNotification, role, value)
  }

  turnoverSheetCreate(notes){
    this.configService.loginInfo['accountEmail'];
    if(this.supervisorListData.length>0){
      let date = notes['startDate'];
      var idx = this.supervisorListData.findIndex(e=> e.vessel == this.configService.loginInfo['vessel']&&new Date(notes['startDate']).toDateString()>=new Date(e.start_date).toDateString()&&new Date(e.end_date).toDateString()>=new Date(notes['startDate']).toDateString()&&e['role']=='OOD');
    }
    let data = { "formList": [],
    "role": this.supervisorListData[idx]?this.supervisorListData[idx]['role'] :'',
    "assignUserID": this.supervisorListData[idx]?this.supervisorListData[idx]['userId']:'',
    "status": "pending",
    "date":notes['startDate'],
    "vessel":this.configService.loginInfo['vessel'],
    'notification' : JSON.stringify(this.userListNotification)?JSON.stringify(this.userListNotification):'',
    }
    this.configService.turnoverSheetCreate(data).then(data => {
      if (data) {
        console.log("Created turnover form", data);
        // this.getStateBoardDataForm();
        notes['typeCollectionId']=data['insertedId'];
        notes['tagName']=this.supervisorListData[idx]?this.supervisorListData[idx]['userId']:'';
        this.broadcastInfo({ src: "turnoverSheet", event: "updateNotes", data: notes });
        // cbck(data);
        this.broadcastInfo({ src: 'sidepanel', event: 'stateBoardForm', data: true });
        this.getTurnoverSheet(data['insertedId']);
      }
    }).catch((error) => {
      // console.log("error ", error);
      // cbck(error);
    });
  }
  /* * *
  * Number Validator
  * * */
  numberOnly(field) {
    this.dynamicForm.controls[field].setValue(this.dynamicForm.value[field].replace(/[^0-9]/g, ''))
  }

  /* * *
  * Selected Form model value assign
  * * */
  assignUpdateDataList: any = [];
  assignUpdateData() {
    this.assignUpdateDataList = JSON.parse(JSON.stringify(this.turnOverFormList));
    if (this.selectedStateBoardForm && this.selectedStateBoardForm['formList'] && this.selectedStateBoardForm['formList'].length > 0) {
      this.selectedStateBoardForm['formList'].forEach(ele => {
        let idx = this.getStateBoardFormDataList.findIndex(e => e['_id'] == ele['_id']);
        if (idx > -1) {
          let inx = this.assignUpdateDataList.findIndex(e => e['name'] == this.getStateBoardFormDataList[idx]['name']);
          if (inx > -1) {
            this.assignUpdateDataList[inx]['_id'] = this.getStateBoardFormDataList[idx]['_id'];
            this.assignUpdateDataList[inx]['formFields'] = this.getStateBoardFormDataList[idx]['formFields'];
          }
        }
      })
    }
    let assignIdx = this.assignUpdateDataList.findIndex(e => e.name == "Signature");
    if (assignIdx > -1) {
      var dateSub: any = this.selectedStateBoardForm ? new Date(this.selectedStateBoardForm['insertDate']) : '';
      if (dateSub) {
        dateSub.setDate(dateSub.getDate() + 1);
      }
      this.assignUpdateDataList[assignIdx]['formFields'][4]['value'] = dateSub ? dateSub : '';
    }
    let oodDateIdx = this.assignUpdateDataList.findIndex(e => e.name == "OOD");
    if (oodDateIdx > -1) {
      var dateSub: any = this.selectedStateBoardForm ? new Date(this.selectedStateBoardForm['insertDate']) : '';
      this.assignUpdateDataList[oodDateIdx]['formFields'][2]['value'] = dateSub ? dateSub : '';
    }
    // this.assignUpdateDataList.
    console.log("assignUpdateDataList", this.assignUpdateDataList, "user List Notification", this.userListNotification);
    this.turnoverSheetStatus();
    this.constrctOodForm();
  }


  /* * *
    * Close State board data
    * * */
  closeDynamicStateboard() {
    this.broadcastInfo({ src: 'dynamic-layout', event: 'closeTurnover', data: "", key: true });
  }

  interval: any;
  intervalState: boolean = true;
  ngAfterViewInit(): void {
    this.supervisorList();
    this.getTurnoverSheets();

    this.interval = setInterval(() => {
      if (this.intervalState) {
        this.getStateBoardDataForm();
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
    }, 3000);
    // this.deleteTurnoverListForm("62eb7c7cc5eb333e3cc01731");

  }
  /* * *
    * State board form click view
    * * */
  formStateBoard(data) {
    // this.broadcastInfo({ src: 'dynamic-layout', event: 'formStateboardView', data:data, key: true });
  }

 /* * *
    * State board click layout view
    * * */
  layoutStateboard(e) {
    this.broadcastInfo({ src: 'dynamic-layout', event: 'changeView', data: e, key: true });
  }
  /* * *
    * State board onchange view
    * * */
  stateboardChange(data) {
    console.log("stateboardChange", data);
    this.selectedStateBoardData = data;
    // this.broadcastInfo({ src: 'dynamic-layout', event: 'changeView', data:e, key: true });
    this.broadcastInfo({ src: 'dynamic-layout', event: 'formStateboardView', data: data, key: true });
  }


  selectedStateBoard: any;
  selectedStateBoardForm: any;
  selectedStateBoardId: any;
  activeNotesList:any=[];
  ngOnInit() {

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if(data.src=='tagPoint'){
          if(data.event=='turnoverSheetCreate'){
            console.log('turnoverSheetCreate',data['data']);
            this.turnoverSheetCreate(data['data']);
          }
        }
        if (data.src == 'admin-SB') {
          if (data.event == "orderLayout") {
            let adminSB = data['data'];
            if (adminSB) {
              this.selectedStateBoardData = adminSB;
            }
          }
          if (data.event == "stateBoardList") {
            this.userRoleFilter = data['userRoleFilter'];
            this.stateboardList = data['data'];
          }
          if (data.event == 'selectedStateBoard') {
            this.selectedStateBoard = data['data'];
          }
          if (data.event == "selectedStateBoardForm") {
            this.getStateBoardDataForm();
            this.selectedStateBoardForm = data['data'];
            this.getUserData();
            this.assignUpdateData();
          }
        }
        if(data.src=='sidepanel'){
          if(data.event == 'selectedStateBoardForm'){
          
            this.selectedStateBoardId = data['data'];
            this.getStateBoardDataForm(this.selectedStateBoardId);
            // this.getTurnoverSheet(data['data']);
          }
          if(data.event == 'deleteTurnover'){
            this.deleteTurnoverListForm(data['data']);
            // this.
          }
        }
        if (data.src == "dynamic-layout") {
          if (data.event == "closeTurnover") {
            delete this.selectedStateBoardForm;
            delete this.selectedStateBoard;
            this.formState = false;
            delete this.formStateName;
            delete this.priviewForm;
          }
        }
        if (data.src == "sidepanel") {
          if (data.event == "reconstruct") {
            this.sidepanelLayoutData = data['layout'].length > 0 ? data['layout'].filter((arr, index, self) =>
              index === self.findIndex((t) => (t.layoutId == arr.layoutId))) : [];
            // this.orderList.forEach((ele,i) => {
            //   this.sidepanelLayoutData[i]['order']=ele;
            // });
            // this.arrangeOrder();
            console.log("reconstruct-turnover", this.sidepanelLayoutData);
            this.sidepanelNotesData = data['notes']
          }
          if (data.event == "layoutTurnOver") {
            console.log("layoutTurnOver", data);
            this.activeTag = data['activeTag'];
            this.activeLayoutId = data['data']['_id'];
            this.activeNotesList = data['tagShow']?data['tagShow']:[];
          }
        }
        if (data.src == "navyApp") {
          if (data.event === 'updateStatus') {
            this.sidepanelStatusData = data.data;
            this.sidepanelStatusObject = data.data;
            console.log("updateStatus", data.data);
          }
          // if(data.event == "deleteLayoutUpdate"){
          //   let id= data['data'];
          //   let idx = this.adminLayoutOrderList.findIndex(e=>)
          // }
          if (data.event === 'layoutData') {
            console.log("layout", data['data']);
            this.layoutData = data['data'];
          }
        }
        if (data.src == "comment") {
          if (data.event === 'allUserNotification') {
            if (data.data == 'success') {
              console.log("All User Updated ");
            }
            if (data.data == 'no') {
              console.log("All User Not Updated ");
            }
          }
        }
      })
  }
  activeOrderData: any;
  activeOrder(item) {
    console.log("activeOrder", item);
    this.activeOrderData = item
  }

  cardLoader: boolean = false;
  selectLayout(item) {
    this.cardLoader = true;
    if (item == 'none') {
      console.log("selectLayout", item);
      let data = { layout: "none", order: this.activeOrderData['order'] };
      this.broadcastInfo({ src: 'dynamic-layout', event: 'addLayout', data: data, key: true });
    } else {
      console.log("selectLayout", item);
      let data = { layout: { id: item['_id'], layoutName: item['layoutName'] }, order: this.activeOrderData['order'] };
      this.broadcastInfo({ src: 'dynamic-layout', event: 'addLayout', data: data, key: true });
    }

  }

  adminEmptyOrderList: any = [];
  arrangeOrder() {
    this.adminEmptyOrderList = [];
    this.turnOverFormList.forEach((element, i) => {
      if (element['order']) {
        this.turnOverFormList[i]['axis'] = this.layoutAxis[element['order'] - 1];
      } else {

      }
    });
    this.orderList.forEach((element, i) => {
      let idx = this.turnOverFormList.findIndex(e => e['order'] == element);
      if (idx > -1) {
        this.turnOverFormList[idx]['axis'] = this.layoutAxis[element - 1];
      } else {
        let emptyList = { order: element, axis: this.layoutAxis[element - 1] };
        this.adminEmptyOrderList.push(emptyList);
      }
    })
  }



  stateboardNameEdit(data) {
    this.broadcastInfo({ src: 'dynamic-layout', event: 'editStateboardName', data: data, key: true });
  }
  /* * * * *
    * method for communicate event instance with data to access all components
    * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  filteredNotesList: any = [];
  selectedLayout: any;
  notesPanel: any = false;
  activeLayout(idx, layoutId, layout) {
    // this.selectedLayout = layout;
    // this.notesPanel = true;
    // console.log(layout,this.sidepanelNotesData );
    // this.filteredNotesList = this.sidepanelNotesData.filter(e=>e.layoutId==layout.layoutId)
    // console.log("layout",this.filteredNotesList );
    var data = { idx: idx, layoutId: layoutId, layout: layout }
    console.log("typeAction", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'activeLayout', data: data, key: true });
  }
  getDate(date) {
    return this.datepipe.transform(date, "MMM d, y, h:mm a")
  }
 getDateFormat(date) {
    return this.datepipe.transform(date, "MMM d, y")
  }
  OnDeleteLayout(idx, id) {
    var data = { index: idx, id: id }
    console.log("OnDeleteLayout", data);

    this.broadcastInfo({ src: 'dynamic-layout', event: 'deleteLayout', data: data, key: true });
  }
  deleteNotesConfirm(data) {
    console.log("deleteNotesConfirm", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'deleteNotesLayout', data: data, key: true });
  }
  statusChange(comm, layoutId, tagpoint, statusName) {
    var data = { comments: comm, layoutId: layoutId, tagpoint: tagpoint, statusName: statusName }
    console.log("statusChange", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'statusChange', data: data, key: true });
  }
  activeSingleLayout(event, notesIdx, layoutId, layoutIdx, tagPoint, layout) {
    if (event && event.target && event.target.tagName.toLowerCase() == 'mat-icon') {
      return;
    }
    var data = { event: event, notesIdx: notesIdx, layoutId: layoutId, layoutIdx: layoutIdx, tagPoint: tagPoint, layout: layout }
    console.log("activeSingleLayout", event, data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'activeSingleLayout', data: data, key: true });
  }
  createCollectionFormActive(type, layout) {
    var data = { type: type, layout: layout }
    console.log("createCollectionFormActive", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'createCollectionFormActive', data: data, key: true });
  }
  layoutTag(idx) {
    this.broadcastInfo({ src: 'dynamic-layout', event: 'layoutTag', data: idx, key: true });
  }
  typeAction(type, layout) {
    var data = { type: type, layout: layout }
    console.log("typeAction", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'typeAction', data: data, key: true });
  }
  viewDataTypeDetails(type, typeCollectionId, layout) {
    var data = { type: type, typeCollectionId: typeCollectionId, layout: layout }
    console.log("viewDataTypeDetails", data);
    this.broadcastInfo({ src: 'dynamic-layout', event: 'viewDataTypeDetails', data: data, key: true });
  }
  formComplition = [
    {name: 'OOD','status': false},
    {name: 'Admin/ceremonial','status': false},
    {name: 'Equipment','status': false},
    {name: 'Engineering','status': false},
    {name: 'Deck','status': false},
    {name: 'Other Activities','status': false},
    {name: 'Notes','status': false},
  ]
  turnoverSheetStatus(){
    var status =true;
    if (this.assignUpdateDataList) {
      this.assignUpdateDataList.forEach(element => {
        element['trunoverSheetDone'] = true;
        element.formFields.forEach(ele => {
          // element['trunoverSheetDone'] = false;
          if (ele.value!='' || ele.field == 'md' || ele.field == 'dd'|| ele.field == 'anchor-meter'|| ele.field == 's-water'|| ele.field == 'p-water'|| ele.field == 'rib-water') {
            this.formComplition.forEach(st => {
              if (element.name == st.name) {
                st.status = true;
              }
            });
            // element['trunoverSheetDone'] = true;
          }else{
            element['trunoverSheetDone'] = false;
            status = false;
          }
        });
      });
      if(status){
        // if(this.selectedStateBoardForm&&this.selectedStateBoardForm['status']!='completed'){
        //   this.selectedStateBoardForm['status']="completed";
        //   this.selectedStateBoardForm['notification'] = JSON.stringify(this.userList)?JSON.stringify(this.userList):'';
        //   this.updateTurnover(this.selectedStateBoardForm,true);
        // }
       
      }
     }
     console.log("turnoverSheetStatus", this.assignUpdateDataList)
  }
  updateTurnoverForm(data){
    let turnover = this.selectedStateBoardForm;
    if(turnover&&turnover.formList&&turnover.formList.length>0){
      let idx = turnover.formList.findIndex(e=> e['name']==data.name);
      if(idx>-1){
        turnover['formList'][idx]=data;
        turnover['notification'] = JSON.stringify(this.userListNotification)?JSON.stringify(this.userListNotification):'';
      }else{
        turnover['formList'].push(data);
        turnover['notification'] = JSON.stringify(this.userListNotification)?JSON.stringify(this.userListNotification):'';
      }
    }else{
      turnover['formList']=[];
      turnover['formList'].push(data);
      turnover['notification'] = JSON.stringify(this.userListNotification)?JSON.stringify(this.userListNotification):'';
    }
    console.log("updateStateBoardForm",turnover);
    this.updateTurnover(turnover);
  }
  getTurnoverSheet(data){
    this.configService.getTurnoverSheetData(data).then(response => {
      console.log('getturnover',response);
      this.selectedStateBoardForm = response[0];
      this.getUserData();
      this.assignUpdateData();
    })
  } 
  updateTurnover(data,status=undefined){
    this.configService.updateTurnoverData(this.selectedStateBoardForm._id,data).then(response => {
      console.log('getturnover',response);
      this.selectedStateBoardForm = response['value'];
      if(status){
        this.broadcastInfo({ src: 'turnoverSheet', event: 'getCollectionData' });
      }       
    })
  }
 /* * *
  * Delete Turnover Form
  * * */
  deleteTurnoverForm(id){
    // this.configService.getProjectConfig().then((project_config: any) => {
    this.configService.deleteTurnoverForm(id).then(response=>{
      console.log("deleteTurnoverForm",response);
    })
  // })
  }
  deleteTurnover(id){
    // this.configService.getProjectConfig().then((project_config: any) => {
      this.configService.deleteTurnover(id).then(response=>{
        console.log("deleteTurnover",response);
        this.broadcastInfo({ src: 'turnoverSheet', event: 'getCollectionData' });
      })
    // })
  }

  deleteTurnoverListForm(id){
    this.configService.getProjectConfig().then((project_config: any) => {
    this.configService.getTurnoverSheetData(id).then(response => {
      console.log('getturnover',response);
      let turnover:any=response?response[0]:[];
      let status=false;
      if(turnover){
        if(turnover.formList.length>0){
          // if(turnover.formList.length>0){
            var formlengthIndex = turnover.formList.length-1;
            turnover.formList.forEach((e,i)=>{
              this.deleteTurnoverForm(e._id);

              if(formlengthIndex==i){
                if(this.selectedStateBoardId==turnover._id){
                   this.closeDynamicStateboard()
                }
                this.deleteTurnover(turnover._id);
              }
            })
          // }
        }else{
          if(this.selectedStateBoardId==turnover._id){
            this.closeDynamicStateboard()
          }
          this.deleteTurnover(turnover._id);
        }
      
      }

    })
  })
  }
  getTurnoverSheets(){
    this.configService.getProjectConfig().then((project_config: any) => {
      this.configService.getTurnoverSheetDatas().then(response => {
        console.log("getTurnoverSheets",response);
        // let turnover:any=response?response:[];
        // if(turnover.length>0){
        //   turnover.forEach(e=>{
        //     this.deleteTurnover(e['_id'])
        //   })
          
        // }
      })
    })
  }
  userList: any = [];
  userListNotification: any = [];
  userInfoDetails: any = [];
  usersDetails: any;
  oodScheduleData: any;
  currentOOD:any;
  userListData() {
    this.userList = [];
    this.userListNotification = [];
    var filterUserData: any = [];
    if(this.selectedStateBoardForm){
      let filterData = { vessel: this.selectedStateBoardForm.vessel, date: new Date(new Date(this.selectedStateBoardForm.insertDate).toDateString()) }
      this.chitService.shedulerFilter(filterData).subscribe(response => {
        console.log("response", response);
        filterUserData = response.filter(obj => obj.role == 'CO' || obj.role == 'URFSO');
        var filterOOD = response.filter(obj => obj.role == 'OOD')
        var filterCOOD =  filterOOD.length>0?filterOOD[0]:'';
        if(this.assignUpdateDataList){
          // if ( this.currentOOD) {
          //     var emailData = this.usersDetails.filter(element => element.email == filterCOOD.userId);
          //     if (emailData && data.length != 0) {
          //       this.currentOOD = emailData[0]['First Name']+ " " +emailData[0]['Last Name'];
          //     }
          // }
          let oodDateIdx =  this.assignUpdateDataList.findIndex(e=> e.name=="OOD");
          if(oodDateIdx>-1){
            this.currentOOD = filterCOOD['userId'];
           this.assignUpdateDataList[oodDateIdx]['formFields'][0]['value']= this.currentOOD;
          }
        }
        if (filterUserData.length > 0) {
          var data = filterUserData;
          if (data && data.length != 0) {
            data.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && data.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': ele.role,
                  'First Name': emailData[0]['First Name'],
                  'defalutDelete': false
                }
              }
              this.userList.push(loginUserdata);
              this.userListNotification.push(loginUserdata);
            })
          }
          // this.removeDuplicateUser();
        }
      })
      var date = new Date(this.selectedStateBoardForm.insertDate).setTime(new Date(this.selectedStateBoardForm.insertDate).getTime() + (24 * 60 * 60 * 1000));
      let filterDatas = { vessel: this.selectedStateBoardForm.vessel, date: new Date(new Date(date).toDateString()) }
      this.chitService.shedulerFilter(filterDatas).subscribe(response => {
        console.log("getOodScheduleData", response);
        var datas = response.filter(obj => obj.role == 'OOD');
        if (datas.length > 0) {
          var data = datas;
          if (data && data.length != 0) {
            data.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && data.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': ele.role,
                  'First Name': emailData[0]['First Name'],
                  'defalutDelete': false
                }
              }
              this.userList.push(loginUserdata);
              this.userListNotification.push(loginUserdata);
            })
          }
          // this.removeDuplicateUser();
        }
      })
    }

  }
  removeDuplicateUser() {
    this.userList.forEach((ele, idx) => {
      if (ele.email == this.configService.accountEmail) {
        this.userList.splice(idx, 1)
      }
    })
    console.log("user Data details", this.userList)
    // this.userdataSocket();
  }
  getUserData() {
    this.chitService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
        var loginUserdata = {};
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        if (userData.length > 0) {
          loginUserdata = {
            "fName": userData[0]['First Name'],
            "lName": userData[0]['Last Name'],
            "email": userData[0].email
          }
         // this.userList.push(this.loginUserdata);
        }
        this.userInfoDetails.push(loginUserdata)

        this.userListData();
      },
      error => {
        console.error('Request failed with error')
      })
  }

  getUser(e){
    var userData = '';
    let role = e.field =='xo'?'URFSO':e.field=='on-coming-ood'?'OOD':e.field;
   var data =  this.userList.filter(element => element.Role.toLowerCase() == role.toLowerCase());
   if(data.length > 0){
    userData = data[0].fName+ ' '+data[0].lName;
   }
    // e = data[0].fName+ +data[0].lName; on-coming-ood
    return userData
  }
  // socket
  currentEmailList: any;
    userdataSocket() {
    this.currentEmailList = this.configService.accountEmail
    let userListData = []
      var turnoverSheet = true
      userListData = this.userListNotification;//.filter(obj => obj.Role != 'OOD')
      // userListData.forEach((ele, idx) => {
      //   // if (ele.email == this.configService.accountEmail) {
      //   //   userListData.splice(idx, 1)
      //   // }
      // })
      var obj = {
        "userList": userListData,
        "tagShapeId": "tagShapeId",
        "layoutId": "layoutId",
        "markerId": "markerId",
        "AppName": "SDMS",
        'turnoverSheet': turnoverSheet,
        "messageData": this.getMessageData(),
        "CommentScreendata": "",
        'shipDetails': this.selectedStateBoardForm,
        "purpose": "purpose",
        'loginUser': this.userInfoDetails[0],
        "chitId": this.selectedStateBoardForm._id,
        "type": 'Turnover'
      }
      // var obj = {
      //   "userList": userListData,
      //   "tagShapeId": this.selectedTagItem.tagShapeId,
      //   "layoutId": this.selectedTagItem.layoutId,
      //   "markerId": this.selectedTagItem.markerId,
      //   "AppName": "SDMS",
      //   'chitForm': chitForm,
      //   "messageData": this.getMessageData(),
      //   "CommentScreendata": this.selectedTagItem,
      //   'shipDetails': this.createActivityFormGroup.value,
      //   "purpose": this.createActivityFormGroup.value.purpose,
      //   'loginUser': this.userInfoDetails[0],
      //   'collisionStatus': this.collision,
      //   'collisionShipInfo': this.collisionShipInfo,
      //   "chitId": this.chitId
      // }
      console.log("obj", obj)
      this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
      // this.saveMessageData();
  }
  getMessageData() {
    var messageData = {
      "notesId": '',
      "layoutId": this.selectedStateBoardId,
      "Messagedate": new Date(),
      "email": this.userInfoDetails[0].email || "",
      "name": this.userInfoDetails[0].fName + '' + this.userInfoDetails[0].lName || "",
      "comments": "Request For Approval" + '@' + this.currentEmailList,
    }
    return messageData
  }

  // saveMessageData() {
  //   var messagedata = this.getMessageData()
  //   this.configService.postData(this.configService.project_config.activityMessage, messagedata).subscribe(result => {
  //     console.log("ade", result)
  //   })
  // }
  priviewForm:any=false;
  openPreview(){
    this.priviewForm=true;
    this.constrctOodForm();
  }

  
  downLoadPdf() {
    document.getElementById("turnoverSheet").style.height = "842px";
    html2canvas(document.querySelector("#turnoverSheet"), {scale: 2}).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("turnoversheet.pdf");
      document.getElementById("turnoverSheet").style.height = "";
    });
  }
  
  oodForm: any = {};
  constrctOodForm() {
    this.assignUpdateDataList.forEach(formField => {
      formField.formFields.forEach(fields => {
        this.oodForm[fields.field] = fields.value || "";
      })
    })
    console.log(this.assignUpdateDataList,this.oodForm);
  }
  oodListNotification: any = [];
  sendNotificationNextOod(){
    var date = new Date(this.selectedStateBoardForm.insertDate).setTime(new Date(this.selectedStateBoardForm.insertDate).getTime() + (24 * 60 * 60 * 1000));
      let filterDatas = { vessel: this.selectedStateBoardForm.vessel, date: new Date(new Date(date).toDateString()) }
      this.chitService.shedulerFilter(filterDatas).subscribe(response => {
        var datas = response.filter(obj => obj.role == 'OOD');
        if (datas.length > 0) {
          var data = datas;
          if (data && data.length != 0) {
            data.forEach(ele => {
              var emailData = this.usersDetails.filter(element => element.email == ele.userId);
              if (emailData && data.length != 0) {
                var loginUserdata = {
                  "fName": emailData[0]['First Name'],
                  "lName": emailData[0]['Last Name'],
                  "email": emailData[0].email,
                  'status': 'inProgress',
                  'Role': ele.role,
                  'First Name': emailData[0]['First Name'],
                  'defalutDelete': false
                }
              }
              // this.userList.push(loginUserdata);
              this.oodListNotification.push(loginUserdata);
            })
          }
          // this.removeDuplicateUser();
          this.ooddataSocket();
        }
      })
  }
  nextEmailList: any;
  ooddataSocket() {
    this.currentEmailList = this.configService.accountEmail
    let userListData = []
      var turnoverSheet = true
      userListData = this.oodListNotification;
      var obj = {
        "userList": userListData,
        "tagShapeId": "tagShapeId",
        "layoutId": "layoutId",
        "markerId": "markerId",
        "AppName": "SDMS",
        'turnoverSheet': turnoverSheet,
        "messageData": this.getMessageData(),
        "CommentScreendata": "",
        'shipDetails': this.selectedStateBoardForm,
        "purpose": "purpose",
        'loginUser': this.userInfoDetails[0],
        "chitId": this.selectedStateBoardForm._id,
        "type": 'Turnover'
      }
      console.log("obj", obj)
      this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: obj });
  }
}
