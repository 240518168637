import { Component, HostListener } from '@angular/core';
import { ChitService } from '../app/services/chit.service';
import { ConfigService } from '../app/services/config.service';
import { CommunicationService } from "../app/services/communication.service";
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from './services/login.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import {User} from './user'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  appMode: any = ''; //dataux,normal,dashboard
  userName: any;
  accountEmail: any;
  loginInfo: any;
  broadcastIframeSubs: any;
  languageSelected: any;
  broadcastSubs;
  compToggle={key:"kys-kha",tab:"kha", selectMenu:''};
  subscriptions: Subscription[] = [];
  user: User;
  constructor(private broadcastService: BroadcastService,public authService:MsalService,public loginServ:LoginService,private chitService: ChitService, private configService: ConfigService, private communicationServ: CommunicationService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.communicationServ.createIframeInstance(data);
  }

  ngOnInit(): void {
    this.getQueryParamValues();
    this.initIframeMessage();
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if (data.src === "dashboard") {
        if (data.event === 'datauxView') {
          var data = data['data']
          // this.appMode = data;
        }
      }
    });

    this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
    .subscribe((data: any) => { 
      if (data.iframeConnection === "sdms-connection") {
        if (data.event === 'Translate') {
          this.languageSelected = data.data;
        }
        if (data.event === 'loginInfo') {
          this.userId = data.data['userId'];
          this.userName = data.data['userName'];
          this.configService.accountEmail = data.data['accountEmail'];
          this.loginInfo = data.data;
          this.configService.loginInfo = data.data;
          this.getVessel();
          this.getUserData();
          this.configService.accessToken = data.data['accessToken'];
          this.configService.refershToken = data.data['refershToken'];
          console.log("navysdms loginInfo ", this.userId, this.userName, data.data);
          this.broadcastInfo({ src: 'app-main', event: 'getUserDetail',  data: this.accountEmail, key: this.userId});
        }
      }     
    })
   /**
     * Login scripts 
     */
   let loginSuccessSubscription: Subscription;
   let loginFailureSubscription: Subscription;
    this.checkAccount();
    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);
  }
  getVessel(){
    this.configService.getProjectConfig().then((project_config: any) => {
    this.chitService.getOodData()
    .subscribe(
      (response) => {
        var repos = response;
        // this.supervisorListData = repos;
        console.log("getVessel",repos);
        let vesselData = repos.filter(e=> e['ood_email']==this.configService['loginInfo']['accountEmail'])
        this.configService['loginInfo']['vessel']=vesselData&&vesselData.length&&vesselData[0]?vesselData[0]['vessel']:'';
      },
      error => {
        console.error('Request failed with error')
        console.log(error)
      })
    });
  }
  getUserData(){
    this.configService.getProjectConfig().then((project_config: any) => {
      this.chitService.getUserData()
      .subscribe(
        (response) => {
          var repos = response;
          // this.supervisorListData = repos;
          console.log("getUserData",repos);
          let userData = repos.filter(e=> e['email']==this.configService['loginInfo']['accountEmail'])
          this.configService['loginInfo']['role']=userData[0]['Role'];
          console.log("get-loginInfo",this.configService['loginInfo']);
          // this.configService['userList'] = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
      });
  }
  initIframeMessage() {   
    this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'sdms-init', data: '', key: "" });
  }

  // @HostListener('window:message', ['$event'])
  // onmessage(e) {
  //   console.log(e)
  //   if (e) {
  //     if (e.data.for == "loginInfo") {
  //       this.userId = e.data.data['userId'];
  //       this.userName = e.data.data['userName'];
  //       this.accountEmail = e.data.data['accountEmail'];
  //       this.loginInfo = e.data.data;
  //       this.configService.accessToken = e.data.data['accessToken'];
  //       this.configService.refershToken = e.data.data['refershToken'];
  //       console.log("navysdms loginInfo ", this.userId, this.userName);
  //     }
  //   }
  //   this.getQueryParamValues();
  // }

  chitId: any;
  userId: any;
  selectedModel:any;
  getQueryParamValues() {
    if (window.location.search) {
      let queryParams = window.location.search.split('?')[1].split('&');
      for (var i = 0; i < queryParams.length; i++) {
        let searchUrl = queryParams[i].split("=");       
        if (i == 0) {
          if (searchUrl[0].toLowerCase() == 'appmode') {
            if (searchUrl[1].toLowerCase() == 'chit') {
              this.appMode = 'normalapp';
              if (queryParams.length == 1) {
                setTimeout(() => {
                  this.broadcastInfo({ src: 'app-main', event: 'getChitDetail', key: 'chitListView', data: { 'chit': this.currUserChit, 'user': this.currUserDetail } });
                }, 3500);
              } else {
                if (searchUrl[1] == 'chit') {
                  this.getChitData();
                }
              }
            }
            if (searchUrl[1].toLowerCase() == 'ship') {
              this.appMode = 'ship';
            }
            if (searchUrl[1].toLowerCase() == 'mepm') {
              this.appMode = 'mepm';
            }
            if (searchUrl[1].toLowerCase() == 'babcock') {
              this.appMode = 'babcock';
            }
            if (searchUrl[1].toLowerCase() == 'chat') {
              this.appMode = 'chat';
            }
            if (searchUrl[1].toLowerCase() == 'dfxmodels') {
              this.appMode = 'dfxmodels';
            }
          }else {
            this.appMode = 'dataux';
          }
        } else {
          if (searchUrl[0].toLowerCase() == 'chitid') {
            this.chitId = searchUrl[1];
          }
          if (searchUrl[0].toLowerCase() == 'userid') {
            this.userId = searchUrl[1];
          }
          if(searchUrl[0].toLowerCase() == 'model'){
            this.selectedModel=searchUrl[1];
          }
        }
      }
    } else {
      this.appMode = 'babcock';      
    }
    this.configService.appMode = this.appMode;
  }

  chitList: any = [];
  userList: any = [];
  getChitData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      // console.log("project_config",project_config)
      this.chitService.getChitActivityData(this.chitId)
        .subscribe(
          (response) => {
            this.currUserChit = response[0];
            console.log('response received', response)
            // var repos = response;
            // console.log("datat2222 : ", repos);
            // this.chitList=response;
            // if(this.chitId&&this.userId){
            //   this.getChitDetail();
            // }
          },
          error => {
            // console.error('Request failed with error')
            console.log(error)
          });
      this.chitService.getUserData().subscribe(
        (response) => {
          // console.log('response received')
          // this.loginUserID = "gokul.r@datifex.com";
          // console.log("ship user details", response);
          this.userList = response;
          if (this.userId) {
            this.getUserDetail();
          }
        },
        error => {
          // console.error('Request failed with error')
          console.log(error)
        })
      setTimeout(() => {
        this.broadcastInfo({ src: 'app-main', event: 'getChitDetail', key: 'chitView', data: { 'chit': this.currUserChit, 'user': this.currUserDetail } });
      }, 3500);
    })
  }
  currUserDetail: any;
  currUserChit: any;
  getChitDetail() {
    if (this.chitList.length > 0) {
      this.chitList.forEach(element => {
        console.log(element['_id'], this.chitId)
        if (element['_id'] == this.chitId && element['azureIdCtrl'] == this.userId) {
          console.log(element);
          this.currUserChit = element;
        }
      });
    }
  }
  getUserDetail() {
    if (this.userList.length > 0) {
      this.userList.forEach(element => {
        // console.log("user",element['_id'],this.userId)
        if (element['_id'] == this.userId) {
          // console.log(element);
          this.currUserDetail = element;

        }
      });
    }
  } 

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
loginInfoData:any={accessToken:"",accountEmail:"",refershToken:"",userId:"", userName:""};
  getAccessToken(cbck) {
    if (!!this.authService.getAccount()) {
      console.log("this.authService", this.authService);
      var accountEmail = this.authService['account']['userName'];
      var userId = this.authService['account']['accountIdentifier'];
      var userName = this.authService['account']['name'];
      let loginInfo = { userId, userName: userName, accountEmail: accountEmail };
      
      this.loginServ.getAccessToken(loginInfo).subscribe(data => {
        if (data['accessToken']) {
          this.loginServ.accessToken = data['accessToken'];
          this.loginServ.refershToken = data['refershToken'];
          this.loginInfoData = {userId:userId, userName: userName, accountEmail: accountEmail,accessToken:this.loginServ.accessToken,refershToken:this.loginServ.refershToken};
          this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'loginInfo', data: this.loginInfoData });
          cbck(true);
        } else {
          cbck(false);
        }
      },
        error => {
          console.log(error);
          cbck(false)
        });
    } else {
      cbck(false);
    }
  }
  // accountEmail: any = ''
  checkAccount() {
    this.getAccessToken((boo) => {
      console.log("loggedIn: ", boo);
      if (boo) {
        this.loginServ.loggedState = !!this.authService.getAccount();
        this.loginServ.loggedState ? this.loadData((data) => {
         console.log("loggedIn: ", data);
        }) : '';
      }
    })
  }
  /**
   * get account detail
   */
  getAccountDetail() {
    this.user = new User();
    this.user.userName = this.authService['account']['name'];
    this.user.userId = this.authService['account']['accountIdentifier'];
  }

  loadData(_callback) {
    var _this = this;
    this.getAccountDetail();
  }

  logout() {
    this.authService.logout();
  }
}
