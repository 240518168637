import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
@Component({
  selector: 'app-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit {
  scheduleWindowState: any = true;
  broadcastSubs: any;
  editIcon: boolean = false;
  cancelEditIcon: boolean = false;
  id: any;
  isReadOnly: boolean = false;
  isReadOnlyData: boolean = false;
  isReadOnlyDashboard: boolean = false;
  ReadOnlyData: boolean = true;
  scheduleCancel: boolean = false;
  scheduleUpdate: boolean = false;
  scheduleCreate: boolean = true;
  editOfficer: boolean = false;
  createOfficer: boolean = true;
  viewOfficer: boolean = false;
  disabled: boolean = true;
  submitted: boolean = false;
  officerName: any = ["Liam", "Jacob", "Leo", "Theo", "Owen", "Jayden", "Levi", "Connor", "Daniel", "Colton", "Isaac", "Myles", "Adam", "Luke", "Elijah", "Emmett", "Asher", "Samuel", "Olivia", "Emma", "Amelia", "Aria", "Charlotte", "Ava", "Mia", "Chloe", "Abigail", "Lily", "Layla", "Ella", "Maya", "Hannah", "Scarlett", "Harper", "Nora", "Hailey", "Charlie", "Sophie", "Ellie", "Isabelle", "Grace", "Lucy", "Aubrey", "Brooklyn", "Julia", "John Doe", "William", "Noah", "Logan", "Nathan", "Oliver", "Lucas", "Ethan", "Thomas", "Mason", "James", "Gabriel", "Alexander", "Jack", "Henry", "Jackson", "Aiden", "Felix", "Zachary", "Ryan", "Carter", "Charles", "Parker", "Hunter", "Matthew", "Caleb", "Nolan", "Hudson", "Raphael", "Evan", "Lincoln", "Dylan", "David", "Elliot", "Anthony", "Grayson", "Joshua", "Alexis", "Theodore", "Wyatt", "Tristan", "Austin", "Eli"];
  officerRole: any = ['Officer of the Day(OOD)', 'Duty Petty Officer(DPO)', 'Brow Watchkeeper/Quartermaster', 'Machine Control Room(MCR)', 'Watchkeeper', 'Duty Cook', "Bos'n's", "SSO States", "Extra Hand", "MM Martech(DCPO)"];
  rank: any = ['Any', 'LCdr-CPO2', 'PO1-PO2', 'MS-OS', 'LS-OS'];
  occupation: any = ['Any', 'Operation / logistics / Executive / Deck', 'Marine Technician / Engineering Technician', 'Cook', 'Executive Department', 'Operations', 'Combat System Engineering', 'Logistics', 'Deck', 'NETP', 'Fire Prevention'];
  // @Input() shipLocations: any = [];
  // @Input() shipVesselName: any = [];
  shipLocations: any = [{
    "key": "A1",
    "value": "A1"
  },
  {
    "key": "A1,2",
    "value": "A1,2"
  },
  {
    "key": "A2",
    "value": "A2"
  },
  {
    "key": "A2,2",
    "value": "A2,2"
  },
  {
    "key": "B1",
    "value": "B1"
  },
  {
    "key": "B1,2",
    "value": "B1,2"
  },
  {
    "key": "B2",
    "value": "B2"
  },
  {
    "key": "B2,2",
    "value": "B2,2"
  },
  {
    "key": "C1",
    "value": "C1"
  },
  {
    "key": "C1,2",
    "value": "C1,2"
  },
  {
    "key": "C2",
    "value": "C2"
  },
  {
    "key": "C2,2",
    "value": "C2,2"
  },
  {
    "key": "C3",
    "value": "C3"
  },
  {
    "key": "C3,2",
    "value": "C3,2"
  },
  {
    "key": "C4",
    "value": "C4"
  },
  {
    "key": "C4,2",
    "value": "C4,2"
  },
  {
    "key": "C4,3",
    "value": "C4,3"
  },
  {
    "key": "C4,4",
    "value": "C4,4"
  },
  {
    "key": "C5",
    "value": "C5"
  },
  {
    "key": "C5,2",
    "value": "C5,2"
  },
  {
    "key": "YN",
    "value": "YN"
  },
  {
    "key": "YN,2",
    "value": "YN,2"
  },
  {
    "key": "Y1",
    "value": "Y1"
  },
  {
    "key": "Y1,2",
    "value": "Y1,2"
  },
  {
    "key": "YS",
    "value": "YS"
  },
  {
    "key": "YS,2",
    "value": "YS,2"
  },
  {
    "key": "Y2",
    "value": "Y2"
  },
  {
    "key": "Y2,2",
    "value": "Y2,2"
  },
  {
    "key": "Y3",
    "value": "Y3"
  },
  {
    "key": "Y3,2",
    "value": "Y3,2"
  }];
  shipVesselName: any = {
    "HAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS HALIFAX",
      "number": "FFH 330"
    },
    "VAN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Vancouver",
      "number": "FFH 331"
    },
    "VDQ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VILLE DE QUEBEC",
      "number": "FFH 332"
    },
    "TOR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS TORONTO",
      "number": "FFH 333"
    },
    "REG": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Regina",
      "number": "FFH 334"
    },
    "CAL": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Calgary",
      "number": "FFH 335"
    },
    "MON": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS MONTREAL",
      "number": "FFH 336"
    },
    "FRE": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS FREDERICTON",
      "number": "FFH 337"
    },
    "WIN": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Winnipeg",
      "number": "FFH 338"
    },
    "CHA": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS CHARLOTTETOWN",
      "number": "FFH 339"
    },
    "STJ": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS ST JOHN’S",
      "number": "FFH 340"
    },
    "OTT": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS Ottawa",
      "number": "FFH 341"
    },
    "VSR": {
      "model": "Halifax",
      "class": "Halifax",
      "name": "HMCS VSR",
      "number": ""
    },
    "VIS": {
      "model": "vis-model",
      "class": "Halifax",
      "name": "HMNZS Te Mana",
      "number": "F111"
    },
    "ORI": {
      "model": "orca-model",
      "class": "Orca",
      "name": "HMCS ORIOLE",
      "number": "YAC 3"
    },
    "AST": {
      "model": "asterix-model",
      "class": "Asterix",
      "name": "MV ASTERIX",
      "number": "AST"
    },
    "HDW": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS HARRY DEWOLF",
      "number": "AOPV 430"
    },
    "MAR": {
      "model": "aops-model",
      "class": "DeWolf",
      "name": "HMCS MARGARET BROOKE",
      "number": "AOPV 431"
    },
    "VIC": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS VICTORIA",
      "number": "SSK 876"
    },
    "WSR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS WINDSOR",
      "number": "SSK 877"
    },
    "COR": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Corner Brook",
      "number": "SSK 878"
    },
    "CHI": {
      "model": "vcsub-model",
      "class": "Victoria",
      "name": "HMCS Chicoutimi",
      "number": "SSK 879"
    },
    "KIN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS KINGSTON",
      "number": " MM 700"
    },
    "GLA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GLACE BAY",
      "number": "MM 701"
    },
    "NAN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS NANAIMO",
      "number": "MM 702"
    },
    "EDM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Edmonton",
      "number": "MM 703"
    },
    "SHA": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SHAWINIGAN",
      "number": "MM 704"
    },
    "WHI": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS WHITEHORSE",
      "number": "MM 705"
    },
    "YEL": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Yellowknife",
      "number": "MM 706"
    },
    "GBY": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS GOOSE BAY",
      "number": "MM 707"
    },
    "MCT": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS MONCTON",
      "number": "MM 708"
    },
    "SAS": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SASKATOON",
      "number": "MM 709"
    },
    "BRN": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS Brandon",
      "number": "MM 710"
    },
    "SUM": {
      "model": "kingston-model",
      "class": "kingston",
      "name": "HMCS SUMMERSIDE",
      "number": "MM 711"
    },
    "SAC": {
      "model": "sackville-model",
      "class": "Boat",
      "name": "Sackville",
      "number": ""
    },
    "TUG": {
      "model": "tug-model",
      "class": "Boat",
      "name": "Tug",
      "number": ""
    },
    "ORC": {
      "model": "orca-boat-model",
      "class": "Boat",
      "name": "ORCA",
      "number": ""
    },
    "RHP": {
      "model": "rhib-p-model",
      "class": "Boat",
      "name": "RHIB P",
      "number": ""
    },
    "RHB": {
      "model": "rhib-model",
      "class": "Boat",
      "name": "RHIB_S",
      "number": ""
    }
  };
  vesselName: any;
  createFormGroup!: FormGroup;
  createResponseFormGroup!: FormGroup;
  createDashboardRoundsFormGroup!: FormGroup;
  responsibilities: boolean = false;
  viewOfficerData: boolean = false;
  editResponseIcon: boolean = true;
  cancelEditResponseIcon: boolean = false;
  roundsViews: boolean = false;
  roundsCreates: boolean = false;
  roundsUpdates: boolean = false;
  roundsDashboard: boolean = false;
  usersDetails: any;
  roundsList: any;
  roundsId: any;
  createRoundsDataDetails: any = [];
  dashboardRoundHeading: any = "Create Rounds";
  editResponseDashboardIcon: boolean = false;
  closeResponseDashboardIcon: boolean = false;
  viewResponseDashboard: boolean = false;
  createResponseDashboard: boolean = true;
  updateResponseDashboard: boolean = false;
  roundsNotes: any;
  selectedDate:any=new Date();
  roundstype: any = ['Small Arms', 'Safety & Security', 'Habitability', 'MCR Technical Rounds', 'Departmental Rounds', 'Magazine Rounds', 'Fire Prevention']
  selectedLayoutList: any;
  selectedTagItem: any;
  selectedTagNotesItem: any;
  shipLocation: any;
  shipName: any;
  supervisorListData: any =[];
  location:any = "";
  locationForm: boolean = false;
  constructor(private ctrlServ : ControllerService, private configService: ConfigService,private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService) { }

  ngOnInit(): void {
    // console.log("data", this.shipVesselName)
    // console.log("shipLocations--", this.shipLocations)
    this.getUserData();
    this.supervisorList();
    this.vesselName = Object.keys(this.shipVesselName);
    console.log("VesselName", this.vesselName)
    var shipLocations = ['FMFDryDock', 'SA1', 'SA2', 'SA3', 'SA4', 'SA5', 'SA6', 'SB1', 'SB2', 'SB3', 'SB4', 'SB5', 'SB6', 'SB7', 'SB8', 'SC1', 'SC2', 'SC3', 'SC5', 'SC6', 'SC7', 'SD1', 'SD2', 'SD3', 'SD4', 'SD5', 'SE1', 'A3', 'D1', 'D1,1', 'D2', 'D2', 'D2,1', 'D2,2', 'F1', 'F1,2', 'F3', 'F3,1', 'F3,2', 'F4', 'F4,1', 'F4,2', 'G', 'G,1', 'G,2']
    shipLocations.forEach(element => {
      let key = this.shipLocations.findIndex(e => e.key.trim() == element.trim())
      if (key > -1) {
        this.shipLocations.splice(key, 1);
      }
    });
    // console.log("shipLocations", this.shipLocations)
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "sidepanel") {
          if (data.event === 'scheduleData') {
            var data = data['data']
            this.viewOfficerData = true;
            this.isReadOnly = true;
            this.createFormGroup.disable();
            this.scheduleCancel = true;
            this.viewOfficer = true;
            this.scheduleCreate = false;
            this.editOfficer = false;
            this.createOfficer = false
            this.editIcon = true;
            this.id = data._id;
            this.getRoundsData();
            this.createFormGroup.setValue({
              officer: (data.email ? data.email : ""),
              vessel: (data.vessel ? data.vessel : ""),
              role: (data.role ? data.role : ""),
              location: (data.location ? data.location : ""),
              notes: (data.notes ? data.notes : ""),
              startdate: (new Date(data.startdate) ? new Date(this.ctrlServ.formatUTCTZ(data.startdate, "DD-MMM-YYYY")) : ""),
              enddate: (new Date(data.enddate) ? new Date(this.ctrlServ.formatUTCTZ(data.enddate, "DD-MMM-YYYY")) : ""),
              starttime: (data.starttime ? data.starttime : ""),
              endtime: (data.endtime ? data.endtime : ""),
              numberonduty: (data.numberonduty ? data.numberonduty : ""),
              occupation: (data.occupation ? data.occupation : ""),
              rank: (data.rank ? data.rank : ""),
            });
          }
          if (data.event === 'scheduleSelectedData') {
            this.selectedTagNotesItem = data.data;
            console.log("this.selectedTagNotesItem---", this.selectedTagNotesItem)
          }
          if (data.event === 'schedule') {
            var keyElem = data.key
            if (keyElem == "schedule") {
              this.viewOfficerData = true;
              this.roundsDashboard = false;
              this.scheduleWindowState = true;
            }
            if(data.selectedLayoutList){
              this.selectedLayoutList = data.selectedLayoutList
              this.selectedTagItem = data.selectedTag
            }
            if (keyElem == "dashboard") {
              this.viewOfficerData = false;
              this.roundsDashboard = true;
              
              // this.isReadOnlyDashboard = false;
              var vessel;
              if (this.configService['loginInfo'] != undefined) {
                vessel = this.configService['loginInfo']['vessel']
              }
              // var vessel = (this.configService['loginInfo']['vessel'] ? this.configService['loginInfo']['vessel'] : ""); 
              var formData = data.value;
              var filterDate = data.filterDate != undefined ? data.filterDate : '';
              this.createDashboardRoundsFormGroup.setValue({
                rounds: (""),
                starttime: ( ""),
                endtime: (""),
                date: (new Date(filterDate) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : ""),
                email: (""),
                vessel: (vessel?vessel: ""),
                details: (""),
              });
              if (formData) {
                this.createDashboardRoundsFormGroup.setValue({
                  rounds: (formData.rounds ? formData.rounds : ""),
                  starttime: (formData.starttime ? formData.starttime : ""),
                  endtime: (formData.endtime ? formData.endtime : ""),
                  date: (new Date(filterDate) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : ""),
                  email: (formData.email ? formData.email : ""),
                  vessel: (formData.vessel ? formData.vessel :vessel?vessel: ""),
                  details: (formData.details ? formData.details : ""),
                });
              }

            }
          }
          if (data.event === 'scheduleDashboardData') {
            var data = data['data']
            this.roundsId = data._id
            this.isReadOnlyDashboard = true;
            this.dashboardRoundHeading = "View Rounds";
            this.editResponseDashboardIcon = true;
            this.closeResponseDashboardIcon = false;
            this.viewResponseDashboard = true;
            this.createResponseDashboard = false;
            this.updateResponseDashboard = false;

            this.roundsStatus = data.status
            this.roundsNotes = data.notes
            this.createDashboardRoundsFormGroup.disable();
            this.createDashboardRoundsFormGroup.setValue({
              rounds: (data.rounds ? data.rounds : ""),
              starttime: (data.starttime ? data.starttime : ""),
              endtime: (data.endtime ? data.endtime : ""),
              date: (new Date(data.date) ? new Date(this.ctrlServ.formatUTCTZ(data.date, "DD-MMM-YYYY")) : ""),
              email: (data.email ? data.email : ""),
              vessel: (data.vessel ? data.vessel : ""),
              details: (data.details ? data.details : ""),
            });
          }
          if (data.event === 'scheduleDashboardDataView') {
            this.scheduleWindowState = true;
            var notes = data.notes;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
            var data = data['data']
            this.roundsId = data
            this.isReadOnlyDashboard = true;
            this.dashboardRoundHeading = "View Rounds";
            this.editResponseDashboardIcon = true;
            this.closeResponseDashboardIcon = false;
            this.viewResponseDashboard = true;
            this.createResponseDashboard = false;
            this.updateResponseDashboard = false;

            this.roundsStatus = data.status
            this.roundsNotes = data.notes
            this.createDashboardRoundsFormGroup.disable();
            this.getRoundsActivityDataView(this.roundsId);
          }
          if (data.src === "calendar") {
  
            if(data.event=='selectedDate'){
              this.selectedDate= data.data;
              this.createDashboardRoundsFormGroup.setValue({
                rounds: (""),
                starttime: ( ""),
                endtime: (""),
                date: (new Date(this.selectedDate) ? new Date(this.ctrlServ.formatUTCTZ(this.selectedDate, "DD-MMM-YYYY")) : ""),
                email: (""),
                vessel: ( ""),
                details: (""),
              });
            }
          }
        }

        if (data.src == "layoutPannel") {
          if (data.event == "rounds_sendLocation") {
            this.roundsDashboard = true;
            this.location = data.location;
          }
        }

        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.createDashboardRoundsFormGroup.patchValue({
              date: data.sliderDate != "" ? new Date(data.sliderDate) : "",
            });
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.createDashboardRoundsFormGroup.patchValue({
              date: data.endDate != "" ? new Date(data.endDate) : "",
            });
          }
        }
      })
      this.roundsDashboard = true;
      // this.isReadOnlyDashboard = false;
    this.createFormGroup = this._formBuilder.group({
      officer: ['', Validators.required],
      vessel: ['', Validators.required],
      role: [' ', Validators.required],
      location: [' '],
      notes: [''],
      startdate: ['', Validators.required],
      enddate: [''],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required],
      numberonduty: [''],
      occupation: [''],
      rank: ['', Validators.required],
    });
    this.createResponseFormGroup = this._formBuilder.group({
      rounds: [' ', Validators.required],
      starttime: [' ', Validators.required],
      details: [''],
    });
    this.createDashboardRoundsFormGroup = this._formBuilder.group({
      rounds: [' ', Validators.required],
      starttime: [' ', Validators.required],
      endtime: [' ', Validators.required],
      date: [' ', Validators.required],
      email: [' ', Validators.required],
      vessel: [' ', Validators.required],
      details: [''],
      // "officerid": this.id,
      // "notes": this.createResponseFormGroup.value.notes,
      // "status": 'false',
      // "role":this.createFormGroup.value.role, 
      // "number":this.createFormGroup.value.number,
    });
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  scheduleWindow() {
    this.scheduleWindowState = !this.scheduleWindowState;
    this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
    // this.broadcastInfo({ src: 'chitStepper', event: 'stepperActive', data: 'null', key: "" });
  }
  scheduleEditForm() {
    this.isReadOnly = false;
    this.createFormGroup.enable();
    this.ReadOnlyData = false;
    this.cancelEditIcon = true;
    this.scheduleCancel = false;
    this.scheduleUpdate = true;
    this.scheduleCreate = false;
    this.editOfficer = true;
    this.viewOfficer = false
    this.createOfficer = false;
    this.disabled = true;
  }
  scheduleCloseForm() {
    this.isReadOnly = true;
    this.createFormGroup.disable();
    this.ReadOnlyData = true;
    this.cancelEditIcon = false;
    this.scheduleCancel = true;
    this.scheduleUpdate = false;
    this.scheduleCreate = false;
    this.editOfficer = false;
    this.viewOfficer = true;
    this.createOfficer = false;
    this.disabled = false;
  }
  dateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'd, MMMM y')
    }
  }
  scheduleUpdateData() {
    if (this.createFormGroup.valid) {
      // var id = Math.floor((Math.random() * 1000) + 1);
      this.selectOfficerData = [];
      this.selectOfficerData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createFormGroup.value.officer.toLowerCase());
      var vessel = [];
      Object.entries(this.shipVesselName).forEach(([key, value]) => {
        if (key == this.createFormGroup.value.vessel) {
          vessel.push(key, value);
        }
      });
      var vesselNumber = vessel[1]

      var data = {
        "name": this.selectOfficerData[0]['First Name'],
        "email": this.createFormGroup.value.officer,
        "vessel": this.createFormGroup.value.vessel,
        "role": this.createFormGroup.value.role,
        "location": this.createFormGroup.value.location,
        "notes": this.createFormGroup.value.notes,
        "startdate": this.datePipe.transform(this.createFormGroup.value.startdate, 'd-MMMM-y'),
        "enddate": this.datePipe.transform(this.createFormGroup.value.enddate, 'd-MMMM-y'),
        "starttime": this.createFormGroup.value.starttime,
        "endtime": this.createFormGroup.value.endtime,
        "number": vesselNumber.number,
        "numberonduty": this.createFormGroup.value.numberonduty,
        "occupation": this.createFormGroup.value.occupation,
        "rank": this.createFormGroup.value.rank,
      }
      // this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataUpdate', data: data});
      // console.log("data recived", data);
      this.chitService.updateOfficer(this.id, data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          this.scheduleWindowState = false;
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
        }, error => {
          console.error('There was an error!');
        })

    }
  }

  scheduleDeleteActivity() {
    this.chitService.deleteOfficer(this.id)
      .subscribe((res) => {
        this.scheduleWindowState = false;
        this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
        this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
      }, error => {
        console.error('There was an error!');
      })
    this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataDelete', data: this.id });
  }
  selectOfficerData: any;
  // selectOfficer(value){
  //   this.selectOfficerData = [];
  //   this.selectOfficerData = this.usersDetails.filter(t => t.email.toLowerCase() == value.toLowerCase());
  // }
  scheduleCreateData() {
    if (this.createFormGroup.valid) {
      this.selectOfficerData = [];
      this.selectOfficerData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createFormGroup.value.officer.toLowerCase());
      var id = Math.floor((Math.random() * 1000) + 1);
      var vessel = [];
      Object.entries(this.shipVesselName).forEach(([key, value]) => {
        if (key == this.createFormGroup.value.vessel) {
          vessel.push(key, value);
        }
      });

      var vesselNumber = vessel[1]
      this.submitted = true;
      var data = {
        "name": this.selectOfficerData[0]['First Name'],
        "email": this.createFormGroup.value.officer,
        "vessel": this.createFormGroup.value.vessel,
        "role": this.createFormGroup.value.role,
        "location": this.createFormGroup.value.location,
        "notes": this.createFormGroup.value.notes,
        "startdate": this.datePipe.transform(this.createFormGroup.value.startdate, 'd-MMMM-y'),
        "enddate": this.datePipe.transform(this.createFormGroup.value.startdate, 'd-MMMM-y'),
        "starttime": this.createFormGroup.value.starttime,
        "endtime": this.createFormGroup.value.endtime,
        "number": vesselNumber.number,
        "status": "ACTIVE",
        "icon": '',
        "numberonduty": this.createFormGroup.value.numberonduty,
        "occupation": this.createFormGroup.value.occupation,
        "rank": this.createFormGroup.value.rank,
      }
      this.chitService.addOfficer(data)
        .subscribe((res) => {
          if (res['result']) {
            console.log('schedule Created ', res.insertedId)
            this.id = res.insertedId;
          }
          if (res.insertedId) {
            if (this.createRoundsDataDetails.length > 0) {
              for (let index = 0; index < this.createRoundsDataDetails.length; index++) {
                const element = this.createRoundsDataDetails[index];
                var data = {
                  "officerid": this.id,
                  "rounds": element.rounds,
                  "details": element.details,
                  "starttime": element.starttime,
                  "notes": element.notes,
                  "status": element.status,
                  "email": element.officer,
                  "date": element.date,
                  "role": element.role,
                  "vessel": element.vessel,
                  "number": element.number,
                  "endtime": element.endtime
                }
                this.chitService.addRounds(data)
                  .subscribe((res) => {
                    if (res['status'] === '200') {
                    }
                    this.roundsTable = false;
                    this.isReadOnlyData = false;
                    this.viewOfficerData = true;
                    // this.getRoundsData();
                  }, error => {
                    console.error('There was an error!');
                  })
              }
              this.scheduleWindowState = false;
              this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
              this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
            }
          } else {
            this.scheduleWindowState = false;
            this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
            this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          }
        }, error => {
          console.error('There was an error!');
        })

    }
  }
  viewVesponsibile() {
    this.responsibilities = true;
    this.isReadOnlyData = false;
    this.viewOfficerData = false;
  }
  closeResponsibilities() {
    this.responsibilities = false;
    this.isReadOnlyData = false;
    this.viewOfficerData = true;
  }

  roundsTable = false;
  roundsStatus: any;
  viewRounds(item) {
    this.roundsTable = true;
    this.isReadOnlyData = true;
    this.viewOfficerData = false;
    this.roundsViews = true;
    this.roundsCreates = false;
    this.roundsUpdates = false;
    this.roundsId = item._id
    this.roundsStatus = item.status
    this.roundsNotes = item.notes
    this.createResponseFormGroup.setValue({
      rounds: (item.rounds ? item.rounds : ""),
      starttime: (item.starttime ? item.starttime : ""),
      details: (item.details ? item.details : ""),
    });
  }
  createRounds() {
    this.roundsTable = true;
    this.isReadOnlyData = false;
    this.viewOfficerData = false;
    this.roundsViews = false;
    this.roundsCreates = true;
    this.roundsUpdates = false;
    this.editResponseIcon = false;
    this.createResponseFormGroup.reset();
  }
  closeRounds() {
    this.roundsTable = false;
    this.isReadOnlyData = true;
    this.viewOfficerData = true;
  }
  responseEditForm() {
    this.isReadOnlyData = false;
    this.editResponseIcon = false;
    this.cancelEditResponseIcon = true;
    this.roundsViews = false;
    this.roundsCreates = false;
    this.roundsUpdates = true;
    this.createResponseFormGroup.enable();
  }
  responseCloseForm() {
    this.isReadOnlyData = true;
    this.editResponseIcon = true;
    this.cancelEditResponseIcon = false;
    this.createResponseFormGroup.disable();
  }

  getUserData() {
    this.chitService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
  }
  getRoundsData() {
    this.chitService.getRoundsDataList().subscribe(
      (response) => {
        this.roundsList = response;
        console.log("roundsList", this.roundsList)
        var selectedRounds = this.roundsList.filter(t => t.officerid == this.id);
        this.roundsList = selectedRounds;
      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
  }
  createRoundsData() {   
    if (this.createResponseFormGroup.valid) {
      var data = {
        "officerid": this.id,
        "rounds": this.createResponseFormGroup.value.rounds,
        "details": this.createResponseFormGroup.value.details,
        "time": this.createResponseFormGroup.value.time,
        "notes": this.createResponseFormGroup.value.notes,
        "status": 'false',
        "email": this.createFormGroup.value.officer,
        "date": this.datePipe.transform(this.createFormGroup.value.startdate, 'd-MMMM-y'),
        "role": this.createFormGroup.value.role,
        "vessel": this.createFormGroup.value.vessel,
        "number": this.createFormGroup.value.number,
        "endtime": this.createFormGroup.value.endtime
      }
      if (this.id) {
        this.chitService.addRounds(data)
          .subscribe((res) => {
            if (res['status'] === '200') {
            }
            this.roundsTable = false;
            this.isReadOnlyData = false;
            this.viewOfficerData = true;
            this.getRoundsData();
          }, error => {
            console.error('There was an error!');
          })
      } else {
        this.createRoundsDataDetails.push(data);
        this.roundsTable = false;
        this.isReadOnlyData = false;
        this.viewOfficerData = true;
      }
    }
  }
  getRoundsActivityDataView(roundsId) {
    this.chitService.getRoundsData(roundsId).subscribe(
      (response) => {
        var repos = response;
        if (repos.length !== 0) {
          var data = repos[0];
            this.roundsStatus = data.status
            this.roundsNotes = data.notes
            this.createDashboardRoundsFormGroup.setValue({
              rounds: (data.rounds ? data.rounds : ""),
              starttime: (data.starttime ? data.starttime : ""),
              endtime: (data.endtime ? data.endtime : ""),
              date: (new Date(data.date) ? new Date(data.date) : ""),
              email: (data.email ? data.email : ""),
              vessel: (data.vessel ? data.vessel : ""),
              details: (data.details ? data.details : ""),
            });
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }

  roundsDelete(id) {
    this.chitService.deleteRounds(id)
      .subscribe((res) => {
        this.getRoundsData();
      }, error => {
        console.error('There was an error!');
      })
  }
  UpdateRounds() {
    if (this.createResponseFormGroup.valid) {
      var data = {
        "officerid": this.id,
        "rounds": this.createResponseFormGroup.value.rounds,
        "details": this.createResponseFormGroup.value.details,
        "time": this.createResponseFormGroup.value.time,
        "notes": (this.roundsNotes ? this.roundsNotes : ""),
        "status": (this.roundsStatus ? this.roundsStatus : ""),
        "email": this.createFormGroup.value.officer,
        "date": this.datePipe.transform(this.createFormGroup.value.startdate, 'd-MMMM-y'),
        "role": this.createFormGroup.value.role,
        "vessel": this.createFormGroup.value.vessel,
        "number": this.createFormGroup.value.number,
        "endtime": this.createFormGroup.value.endtime
      }
      this.chitService.updateRounds(this.roundsId, data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          this.roundsTable = false;
          this.isReadOnlyData = false;
          this.viewOfficerData = true;
          this.getRoundsData();
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  closeRoundsDashboard() {
    this.scheduleWindowState = false
    this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
    // this.broadcastInfo({ src: 'chitStepper', event: 'stepperActive', data: 'null', key: "" });
  }
  officerNameDetails: any;
  createRoundsDashboard() {
    if (this.createDashboardRoundsFormGroup.valid) {
      var data = {
        "officerid": '',
        "rounds": this.createDashboardRoundsFormGroup.value.rounds,
        "details": this.createDashboardRoundsFormGroup.value.details,
        "starttime": this.createDashboardRoundsFormGroup.value.starttime,
        "notes": '',
        "status": 'false',
        "email": this.createDashboardRoundsFormGroup.value.email,
        "date": this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'd-MMMM-y'),
        "role": '',
        "vessel": this.createDashboardRoundsFormGroup.value.vessel,
        "number": '',
        "endtime": this.createDashboardRoundsFormGroup.value.endtime
      }
      var userData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createDashboardRoundsFormGroup.value.email.toLowerCase());
      this.officerNameDetails = userData[0]['First Name'] + ' ' + userData[0]['Role'] || "",
      this.chitService.addRounds(data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          var roundsId = res.insertedId;
          this.scheduleWindowState = false
          let tagLocation, locationShape;
          if(this.location != ""){
            tagLocation = JSON.parse(this.location);
            locationShape =  "avatar1";
          }else{
            let locationKey = "rounds_"+this.createDashboardRoundsFormGroup.value.rounds.split(" ").join('')
            let tagLocationIdx = this.chitService.locationList.findIndex(item=> item.locationKey == locationKey);
            if(tagLocationIdx != -1){
              tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
              locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "avatar1";
            }
          }
          var chitData =[{'typeCollectionId': roundsId, 'typeCollection': 'Rounds',"purpose": this.createDashboardRoundsFormGroup.value.details,"tagLocation": tagLocation, "locationShape": locationShape }]
          var logBookData = {'typeCollection': 'Rounds',"tagName":this.createDashboardRoundsFormGroup.value.rounds, "description": this.createDashboardRoundsFormGroup.value.details,"startDate":this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'yyyy-MM-dd'), "startTime": this.createDashboardRoundsFormGroup.value.starttime,"endTime": this.createDashboardRoundsFormGroup.value.endtime };
          var chit ={
            "Location": this.createDashboardRoundsFormGroup.value.location,
            "Vessel": this.createDashboardRoundsFormGroup.value.vessel,
            "activity": this.createDashboardRoundsFormGroup.value.rounds,
            "emitterLocation": this.createDashboardRoundsFormGroup.value.rounds,
            "startTime": this.createDashboardRoundsFormGroup.value.starttime,
            "endTime": this.createDashboardRoundsFormGroup.value.endtime,
            "officerName":this.officerNameDetails
          }
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'chit', event: 'roundsCreate', data: chitData, chit : chit, seletedCollection : this.selectedTagItem, formdate: this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'd-MMMM-y')});
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  updateRoundsDashboard() {
    if (this.createDashboardRoundsFormGroup.valid) {
      var data = {
        "officerid": '',
        "rounds": this.createDashboardRoundsFormGroup.value.rounds,
        "details": this.createDashboardRoundsFormGroup.value.details,
        "starttime": this.createDashboardRoundsFormGroup.value.starttime,
        "email": this.createDashboardRoundsFormGroup.value.email,
        "date": this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'd-MMMM-y'),
        "role": '',
        "vessel": this.createDashboardRoundsFormGroup.value.vessel,
        "number": '',
        "endtime": this.createDashboardRoundsFormGroup.value.endtime,
        "notes": (this.roundsNotes ? this.roundsNotes : ""),
        "status": (this.roundsStatus ? this.roundsStatus : ""),
      }
      var userData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createDashboardRoundsFormGroup.value.email.toLowerCase());
      this.officerNameDetails = userData[0]['First Name'] + ' ' + userData[0]['Role'] || "",
      this.chitService.updateRounds(this.roundsId, data)
        .subscribe((res) => {
          if (res['status'] === '200') {
          }
          this.scheduleWindowState = false
          let tagLocation, locationShape;
          if(this.location != ""){
            tagLocation = JSON.parse(this.location);
            locationShape =  "avatar1";
          }else{
            let locationKey = "rounds_"+this.createDashboardRoundsFormGroup.value.rounds.split(" ").join('')
            let tagLocationIdx = this.chitService.locationList.findIndex(item=> item.locationKey == locationKey);
            if(tagLocationIdx != -1){
              tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
              locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "avatar1";
            }
          }
          var chitData =[{'typeCollectionId': this.roundsId, 'typeCollection': 'Rounds',"purpose": this.createDashboardRoundsFormGroup.value.details,"tagLocation": tagLocation, "locationShape":locationShape,"tagShapeId":this.selectedTagNotesItem.tagShapeId}]
          var logBookData = {'typeCollection': 'Rounds',"tagName":this.createDashboardRoundsFormGroup.value.rounds, "description": this.createDashboardRoundsFormGroup.value.details,"startDate":this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'yyyy-MM-dd'), "startTime": this.createDashboardRoundsFormGroup.value.starttime,"endTime": this.createDashboardRoundsFormGroup.value.endtime };
          var chit ={
            "Location": this.createDashboardRoundsFormGroup.value.location,
            "Vessel": this.createDashboardRoundsFormGroup.value.vessel,
            "activity": this.createDashboardRoundsFormGroup.value.rounds,
            "emitterLocation": this.createDashboardRoundsFormGroup.value.rounds,
            "startTime": this.createDashboardRoundsFormGroup.value.starttime,
            "endTime": this.createDashboardRoundsFormGroup.value.endtime,
            "officerName":this.officerNameDetails
          }
          console.log("this.selectedTagNotesItem", this.selectedTagNotesItem)
          this.broadcastInfo({ src: 'chit', event: 'roundsCreate', data: chitData, chit : chit, seletedCollection : this.selectedTagNotesItem, formdate: this.datePipe.transform(this.createDashboardRoundsFormGroup.value.date, 'd-MMMM-y')});
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  editRoundsDashboard() {
    this.isReadOnlyDashboard = false;
    this.dashboardRoundHeading = "Edit Rounds";
    this.editResponseDashboardIcon = false;
    this.closeResponseDashboardIcon = true;
    this.viewResponseDashboard = false;
    this.createResponseDashboard = false;
    this.updateResponseDashboard = true;
    this.createDashboardRoundsFormGroup.enable();
  }
  closeEditRoundsDashboard() {
    this.isReadOnlyDashboard = true;
    this.dashboardRoundHeading = "View Rounds";
    this.editResponseDashboardIcon = true;
    this.closeResponseDashboardIcon = false;
    this.viewResponseDashboard = true;
    this.createResponseDashboard = false;
    this.updateResponseDashboard = false;
    this.createDashboardRoundsFormGroup.disable();
  }
  roundseDeleteActivity() {
    this.chitService.deleteRounds(this.roundsId)
      .subscribe((res) => {
        this.scheduleWindowState = false
        this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDataInsert', data: 'true' });
        this.broadcastInfo({ src: 'chit', event: 'roundsDelete', data: '', key: "Rounds" });
        this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
      }, error => {
        console.error('There was an error!');
      })
  }

    // Supervisor

    supervisorList() {
      this.configService.getProjectConfig().then((project_config: any) => {
      // this.chitService.getOodData()
      this.chitService.getScheduleData()
        .subscribe(
          (response) => {
            var repos = response;
            this.supervisorListData = repos;
            console.log('this.supervi', this.supervisorListData)
            var filterData = this.supervisorListData.filter(obj => obj.userId == this.configService.accountEmail)
            if(filterData.length != 0){
              this.RoundsFormBinded(filterData[0])
            }
            // this.loginUserID = (this.profileInfo.accountEmail ? this.profileInfo.accountEmail : "");
            // var userMatch = repos.filter(t => t.ood_email == this.loginUserID);
            // var vesselMatch = userMatch.filter(t => t.vessel.toLowerCase() == this.chitCreateVessel.toLowerCase());
            // var dateFilter = vesselMatch.filter(t => this.datePipe.transform(t.start_date, 'yyy-MM-dd') == this.chitUserDate);
            // // console.log('response received', dateFilter)
            // this.supervisorListData = dateFilter;
          },
          error => {
            console.error('Request failed with error')
            console.log(error)
          })
        });
    }

  RoundsFormBinded(chitFormData){
    this.createDashboardRoundsFormGroup.patchValue({
      // Location: (chitFormData.location ? chitFormData.location : ""),
      //  date: (new Date(chitFormData.start_date) ? new Date(chitFormData.start_date) : "" ),
       starttime: (chitFormData.start_time ? chitFormData.start_time : "08:00" ),
       endtime: (chitFormData.end_time ? chitFormData.end_time : "20:00" ),
      // activity: (chitFormData.activity ? chitFormData.activity : "" ),
       vessel: (chitFormData.vessel ? chitFormData.vessel : "" ),
    });
    this.shipLocation = chitFormData.location;
    this.shipName = chitFormData.vessel;
  }

  pickLocation() {
    this.roundsDashboard = false;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'rounds';
  }
}
