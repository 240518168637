import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from "../services/communication.service";
import { ChitService } from '../services/chit.service';
import { TagoutService } from '../services/tagout.service'
import { ControllerService } from '../services/controller.service';
// import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-tagout',
  templateUrl: './tagout.component.html',
  styleUrls: ['./tagout.component.scss'],
  providers: [DatePipe,],
  // providers: [
  //   { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  // ]
})
export class TagoutComponent implements OnInit {
  @Input() createForm: any;
  broadcastSubs;
  tagoutWindowState: any = false;
  isReadOnly: any = false;
  tagHeading = "Create Lockout Tagout"
  tagoutId: any;
  tagoutStatus: any;
  formId: any;
  statusUpdateValue: any;
  editIcon: boolean = false;
  cancelEditIcon: boolean = false;
  deleteTagoutForm: boolean = false;

  createFormGroup!: FormGroup;
  createPrereQuisites!: FormGroup;
  createAmplifying!: FormGroup;
  createAuthorization!: FormGroup;
  createFinalCompletion!: FormGroup;
  createGuarantee!: FormGroup;
  createMaintainer!: FormGroup;

  generalData: boolean = false;
  prereQuisites: boolean = false;
  amplifying: boolean = false;
  authorization: boolean = false;
  finalCompletion: boolean = false;
  guarantee: boolean = false;
  maintainer: boolean = false;
  tagMasterData: any = [];

  usersDetails: any;
  vesselName = ["HAL", "VAN", "VDQ", "TOR", "REG", "CAL", "MON", "FRE", "WIN", "CHA", "STJ", "OTT", "VSR", "VIS", "ORI", "AST", "HDW", "MAR", "VIC", "WSR", "COR", "CHI", "KIN", "GLA", "NAN", "EDM", "SHA", "WHI", "YEL", "GBY", "MCT", "SAS", "BRN", "SUM", "SAC", "TUG", "ORC", "RHP", "RHB"];
  shipLocations = [
    {
      "key": "A1",
      "value": "A1"
    },
    {
      "key": "A1,2",
      "value": "A1,2"
    },
    {
      "key": "A2",
      "value": "A2"
    },
    {
      "key": "A2,2",
      "value": "A2,2"
    }]
  shipLocations_b = [
    {
      "key": "A1",
      "value": "A1"
    },
    {
      "key": "A1,2",
      "value": "A1,2"
    },
    {
      "key": "A2",
      "value": "A2"
    },
    {
      "key": "A2,2",
      "value": "A2,2"
    },
    {
      "key": "B1",
      "value": "B1"
    },
    {
      "key": "B1,2",
      "value": "B1,2"
    },
    {
      "key": "B2",
      "value": "B2"
    },
    {
      "key": "B2,2",
      "value": "B2,2"
    },
    {
      "key": "C1",
      "value": "C1"
    },
    {
      "key": "C1,2",
      "value": "C1,2"
    },
    {
      "key": "C2",
      "value": "C2"
    },
    {
      "key": "C2,2",
      "value": "C2,2"
    },
    {
      "key": "C3",
      "value": "C3"
    },
    {
      "key": "C3,2",
      "value": "C3,2"
    },
    {
      "key": "C4",
      "value": "C4"
    },
    {
      "key": "C4,2",
      "value": "C4,2"
    },
    {
      "key": "C4,3",
      "value": "C4,3"
    },
    {
      "key": "C4,4",
      "value": "C4,4"
    },
    {
      "key": "C5",
      "value": "C5"
    },
    {
      "key": "C5,2",
      "value": "C5,2"
    },
    {
      "key": "YN",
      "value": "YN"
    },
    {
      "key": "YN,2",
      "value": "YN,2"
    },
    {
      "key": "Y1",
      "value": "Y1"
    },
    {
      "key": "Y1,2",
      "value": "Y1,2"
    },
    {
      "key": "YS",
      "value": "YS"
    },
    {
      "key": "YS,2",
      "value": "YS,2"
    },
    {
      "key": "Y2",
      "value": "Y2"
    },
    {
      "key": "Y2,2",
      "value": "Y2,2"
    },
    {
      "key": "Y3",
      "value": "Y3"
    },
    {
      "key": "Y3,2",
      "value": "Y3,2"
    }
  ]
  evolutionArray: any = ['Ammunitioning', 'Bollard Pulls', 'Man-Aloft', 'Antenna Rotating', 'Ceremonial', 'Cold Move', 'Crane Work', 'Diving Operations', 'Engine Exhuasting', 'Fueling Operations', 'Jetty Service', 'Radiating', 'Antenna Radiating', 'Truck Fuelling', 'Man Aloft/Radiate', 'Man Aloft/Rotating', 'Other Activities'];
  workOrder: any = ['FMF Work Order', 'SS Work order'];
  department: any = ['ME', 'HL', 'EL', 'CS'];
  selectedLayoutList: any;
  selectedTagItem: any;
  lotoId: any;
  isReadOnlyDashboard: boolean;
  dashboardRoundHeading: string;
  editResponseDashboardIcon: boolean;
  viewResponseDashboard: boolean;
  notes: any;
  startTime = '08:00';
  endTime = '16:00';
  selectedTagNotesItem: any;
  locationForm: boolean = false;
  location = "";
  constructor(private ctrlServ : ControllerService ,private datePipe: DatePipe, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService, private tagoutService: TagoutService) { }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngOnInit(): void {
    this.getUserData();
    if (!this.createForm) {
      this.generalData = true;
    } else {
      this.generalData = false;
    }
    this.formId = Math.floor((Math.random() * 999) + 1);
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "dashboard") {
          if (data.event === 'openTagoutApproved') {
            this.notes = data.notes;
            var notes = data.notes;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
            var showApprove = data.key;
            let obj = data['data'];
            this.tagMasterData = data['master'];
            if (showApprove == "approved") {
              this.tagoutId = obj['_id'];
              this.generalData = false;
              this.prereQuisites = false;
              this.amplifying = false;
              this.authorization = true;
              this.guarantee = false;
              this.maintainer = false;
              this.tagHeading = "Authorization of work to commence";
              this.createAuthorization.patchValue(obj['authorization']);
            }
          }
          if (data.event === 'createTagoutDialog') {
            // if(data.selectedLayoutList){
            // this.selectedLayoutList = data.selectedLayoutList
            this.selectedTagItem = data.selectedTag;
            var filterDate = data.filterDate != undefined ? data.filterDate : '';
            
            this.createFormGroup.controls.date.setValue(new Date(filterDate) ? new Date(this.ctrlServ.formatUTCTZ(filterDate, "DD-MMM-YYYY")) : "");
            // }
            var showApprove = data.data;
            var number = 10;
            var numbers = 10;
            var dasd = number - numbers;
            var data = showApprove + 1;
            var dataa = dasd + showApprove
            console.log("number:", dataa);
            var x = "000";
            var y: number = +x;
            console.log("number:", y);

          }
          if (data.event === 'openTagoutComplete') {
            this.notes = data.notes;
            var notes = data.notes;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
            var showApprove = data.key;
            let obj = data['data'];
            this.tagMasterData = data['master'];
            if (showApprove == "complete") {
              this.tagoutId = obj['_id'];
              this.generalData = false;
              this.prereQuisites = false;
              this.amplifying = false;
              this.authorization = false;
              this.finalCompletion = true;
              this.guarantee = false;
              this.maintainer = false;
              this.tagHeading = "Final Completion";
              this.createFinalCompletion.patchValue(obj['finalcompletion']);
            }
            if (showApprove == 'guarantee') {
              this.tagoutId = obj['_id'];
              this.generalData = false;
              this.prereQuisites = false;
              this.amplifying = false;
              this.authorization = false;
              this.finalCompletion = false;
              this.guarantee = true;
              this.maintainer = false;
              this.tagoutStatus = obj['status']
              this.tagHeading = "Guarantee Of Isolation";
              this.createGuarantee.patchValue(obj['guarantee']);
            }
            if (showApprove == 'maintainer') {
              this.tagoutId = obj['_id'];
              this.generalData = false;
              this.prereQuisites = false;
              this.amplifying = false;
              this.authorization = false;
              this.finalCompletion = false;
              this.guarantee = false;
              this.maintainer = true;
              this.tagHeading = "Maintainer should Sign";
              this.createMaintainer.patchValue(obj['maintainer']);
            }
          }
          if (data.event === 'openTagoutData') {
            this.tagoutId = data.key;
            var notes = data.notes;
            this.location = notes != undefined && typeof notes.props.tagLocation === "object" ? JSON.stringify(notes.props.tagLocation) : "";
            var data = data.data
            this.deleteTagoutForm = true;
            var amplifying = data.amplifying
            var prerequisites = data.prerequisites
            this.isReadOnly = true;
            this.editIcon = true;
            this.tagHeading = "View Lockout Tagout";
            this.createFormGroup.disable();
            this.createPrereQuisites.disable();
            this.createAmplifying.disable();
            this.generalData = true;
            this.statusUpdateValue = data.status;
            this.createFormGroup.setValue({
              unit: (data&&data.unit ? data.unit : ""),
              formNumber: (data&&data.formNumber ? data.formNumber : ""),
              noOfTags: (data&&data.noOfTags ? data.noOfTags : ""),
              date: (data&&data.date ? new Date(this.ctrlServ.formatUTCTZ(data.date, "DD-MMM-YYYY")) : ""),
              starttime: (data&&data.starttime ? data.starttime : ""),
              crossRefFormNo: (data&&data.crossRefFormNo ? data.crossRefFormNo : ""),
              system: (data&&data.system ? data.system : ""),
              location: (data&&data.location ? data.location : ""),
              workOrder: (data&&data.workOrder ? data.workOrder : ""),
              lockBox: (data&&data.lockBox ? data.lockBox : ""),
              description: (data&&data.description ? data.description : ""),
              name: (data&&data.name ? data.name : ""),
              phoneNumber: (data&&data.phoneNumber ? data.phoneNumber : ""),
              department: (data&&data.department ? data.department : ""),
              endtime: (data&&data.endtime ? data.endtime : ""),
            });
            this.createPrereQuisites.setValue({
              lockout: (prerequisites&&prerequisites.lockout ? prerequisites.lockout : ""),
              qa: (prerequisites&&prerequisites.qa ? prerequisites.qa : ""),
              wti: (prerequisites&&prerequisites.wti  ? prerequisites.wti : ""),
              dcs: (prerequisites&& prerequisites.dcs? prerequisites.dcs : ""),
            });
            this.createAmplifying.setValue({
              amplifyingDate: (amplifying&&amplifying.amplifyingDate ? amplifying.amplifyingDate : ""),
              forFormNo: (amplifying&&amplifying.forFormNo ? amplifying.forFormNo : ""),
              amplifyingDetails: (amplifying&&amplifying.amplifyingDetails ? amplifying.amplifyingDetails : ""),
            });
          }
          if (data.event === 'scheduleSelectedData') {
            this.selectedTagNotesItem = data.data;
          }
        }
        if (data.src == "layoutPannel") {
          if (data.event == "loto_sendLocation") {
            this.locationForm = false
            this.generalData = true;
            this.location = data.location;
          }
        }

        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.createFormGroup.patchValue({
              date: data.sliderDate != "" ? new Date(data.sliderDate) : "",
            });
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.createFormGroup.patchValue({
              date: data.endDate != "" ? new Date(this.ctrlServ.formatUTCTZ(data.endDate, "DD-MMM-YYYY")) : "",
            });
          }
        }
      })

    this.createFormGroup = this._formBuilder.group({
      unit: ['', Validators.required],
      formNumber: [this.formId, Validators.required],
      noOfTags: ['', Validators.required],
      date: ['', Validators.required],
      starttime: [' ', Validators.required],
      endtime: [' ', Validators.required],
      crossRefFormNo: ['', Validators.required],
      system: ['', Validators.required],
      location: ['', Validators.required],
      workOrder: ['', Validators.required],
      lockBox: ['', Validators.required],
      description: ['', Validators.required],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      department: ['', Validators.required],
    });
    this.createPrereQuisites = this._formBuilder.group({
      lockout: ['done', Validators.required],
      qa: ['done', Validators.required],
      wti: ['done', Validators.required],
      dcs: ['done', Validators.required],
    });
    this.createAmplifying = this._formBuilder.group({
      amplifyingDate: [new Date(), Validators.required],
      forFormNo: ['', Validators.required],
      amplifyingDetails: ['', Validators.required]
    });
    this.createAuthorization = this._formBuilder.group({
      mseoPrint: ['', Validators.required],
      mseoDate: [new Date(), Validators.required],
      mseoApproved: [false, Validators.requiredTrue],
      oodPrint: ['', Validators.required],
      oodDate: [new Date(), Validators.required],
      oodApproved: [false, Validators.requiredTrue],
    });
    this.createFinalCompletion = this._formBuilder.group({
      maintainerPrint: ['', Validators.required],
      maintainerDate: [new Date(), Validators.required],
      maintainerApproved: [false, Validators.requiredTrue],

      deptPrint: ['', Validators.required],
      deptDate: [new Date(), Validators.required],
      deptApproved: [false, Validators.requiredTrue],

      mseoPrint: ['', Validators.required],
      mseoDate: [new Date(), Validators.required],
      mseoApproved: [false, Validators.requiredTrue],

      oodPrint: ['', Validators.required],
      oodDate: [new Date(), Validators.required],
      oodApproved: [false, Validators.requiredTrue],

      lockoutPrint: ['', Validators.required],
      lockoutDate: [new Date(), Validators.required],
      lockoutApproved: [false, Validators.requiredTrue],
    });
    this.createGuarantee = this._formBuilder.group({
      precautions: ['', Validators.required],
      assuranceTest: ['', Validators.required],
      padlock: ['', Validators.required],
      testedDate: [new Date(), Validators.required],
      testedPrint: ['', Validators.required],
      testedApproved: [false, Validators.requiredTrue],
      maintainerPrint: ['', Validators.required],
      maintainerDate: [new Date(), Validators.required],
      maintainerApproved: [false, Validators.requiredTrue],
    });
    this.createMaintainer = this._formBuilder.group({
      maintainerDataPrint: ['', Validators.required],
      maintainerDataDate: [new Date(), Validators.required],
      maintainerDataApproved: [false, Validators.requiredTrue],
    });
  }
  getUserData() {
    this.chitService.getUserData().subscribe(
      (response) => {
        this.usersDetails = response;
      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
  }
  closeTagoutWindow() {
    this.cancelEditIcon = false;
    this.tagoutWindowState = false;
    this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
    this.broadcastInfo({ src: 'tagout', event: 'closeTagout', data: this.tagoutWindowState, key: "" });
    this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true });

  }
  officerNameDetails: any;
  createGeneralData() {
    if (this.isReadOnly == false) {
      if (this.createFormGroup.valid) {
        var userData = this.usersDetails.filter(t => t.email.toLowerCase() == this.createFormGroup.value.name.toLowerCase());
        this.officerNameDetails = userData[0]['First Name'] + ' ' + userData[0]['Role'] || "";
        if (this.tagoutId) {
          var data = {
            "unit": this.createFormGroup.value.unit,
            "formNumber": this.createFormGroup.value.formNumber,
            "noOfTags": this.createFormGroup.value.noOfTags,
            "date": this.createFormGroup.value.date,
            "crossRefFormNo": this.createFormGroup.value.crossRefFormNo,
            "system": this.createFormGroup.value.system,
            "location": this.createFormGroup.value.location,
            "workOrder": this.createFormGroup.value.workOrder,
            "lockBox": this.createFormGroup.value.lockBox,
            "description": this.createFormGroup.value.description,
            "name": this.createFormGroup.value.name,
            "phoneNumber": this.createFormGroup.value.phoneNumber,
            "status": this.statusUpdateValue,
            "department": this.createFormGroup.value.department,
            'starttime': this.createFormGroup.value.starttime,
            'endtime': this.createFormGroup.value.endtime,
          }
          console.log("createGeneralData 1:", data)
          this.tagoutService.updateTagout(this.tagoutId, data)
            .subscribe((res) => {
              if (res['status'] === '200') {
              }
              this.generalData = false;
              this.prereQuisites = true;
              this.createLog();
              this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
              this.updateLotosDashboard();
            }, error => {
              console.error('There was an error!');
            })
        } else {
          this.tagoutService.addTagout(this.createFormGroup.value)
          
            .subscribe((res) => {
              if (res['status'] === '200') {
              }
              this.tagoutId = res.insertedId;
              this.deleteTagoutForm = true;
              this.generalData = false;
              this.prereQuisites = true;
              this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
              this.createLog();
              this.createLotosDashboard();
            }, error => {
              console.error('There was an error!');
            })
        }
      }
    } else {
      this.generalData = false;
      this.prereQuisites = true;
    }
  }

  createLog(){
    var logBookData = {
      'typeCollection': 'Lockout/Tagout',
      "tagName": 'initiate',
      "description": this.createFormGroup.value.description,
      "startDate": this.datePipe.transform(this.tagoutService.sliderDate, 'yyyy-MM-dd'),
      "startTime": this.createFormGroup.value.starttime,
      "endTime": this.createFormGroup.value.endtime,
      "formStatus":'initiate'
    };
    this.chitService.createLogBook(logBookData);
  }

  createLotosDashboard() {
    var loto = {
      "Location": this.createFormGroup.value.location,//location
      "Vessel": this.createFormGroup.value.unit,//unit
      "activity": "initiate",//""
      "emitterLocation": "Lock # Form #", 
      "startTime": this.createFormGroup.value.starttime,
      "endTime": this.createFormGroup.value.endtime,
      "officerName":this.officerNameDetails
    }

    let tagLocation, locationShape;
    if(this.location != ""){
      tagLocation = JSON.parse(this.location);
      locationShape =  "avatar4";
    }else{
      let locationKey = "loto_"+this.createFormGroup.value.location.split(" ").join('')
      let tagLocationIdx = this.chitService.locationList.findIndex(item=> item.locationKey == locationKey);
      if(tagLocationIdx != -1){
        tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
        locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "avatar4";
      }
    }
    var lotoData = [{ 'typeCollectionId': this.tagoutId, 'typeCollection': 'Lockout/Tagout', 'purpose': this.createFormGroup.value.description,"tagLocation": tagLocation, "locationShape": locationShape}]
    this.broadcastInfo({ src: 'chit', event: 'lotocreate', data: lotoData, chit: loto, seletedCollection: this.selectedTagItem,formdate: this.datePipe.transform(this.createFormGroup.value.date, 'd-MMMM-y') });
  }
  updateLotosDashboard() {
    var loto = {
      "Location": this.createFormGroup.value.location,//location
      "Vessel": this.createFormGroup.value.unit,//unit
      "activity": "initiate",//""
      "emitterLocation": "Lock # Form #", 
      "startTime": this.createFormGroup.value.starttime,
      "endTime": this.createFormGroup.value.endtime
    }
    let tagLocation, locationShape;
    if(this.location != ""){
      tagLocation = JSON.parse(this.location);
      locationShape =  "avatar4";
    }else{
      let locationKey = "loto_"+this.createFormGroup.value.location.split(" ").join('')
      let tagLocationIdx = this.chitService.locationList.findIndex(item=> item.locationKey == locationKey);
      if(tagLocationIdx != -1){
        tagLocation = this.chitService.locationList[tagLocationIdx]['location'];
        locationShape = this.chitService.locationList[tagLocationIdx]['locationShape'] || "avatar4";
      }
    }
    var lotoData = [{ 'typeCollectionId': this.tagoutId, 'typeCollection': 'Lockout/Tagout', 'purpose': this.createFormGroup.value.description,"tagLocation": tagLocation, "locationShape": locationShape,"tagShapeId":this.selectedTagNotesItem.tagShapeId}]
    this.broadcastInfo({ src: 'chit', event: 'lotocreate', data: lotoData, chit: loto, seletedCollection: this.selectedTagNotesItem,formdate: this.datePipe.transform(this.createFormGroup.value.date, 'd-MMMM-y') });

  }
  backGeneralWindow() {
    this.generalData = true;
    this.prereQuisites = false;
  }
  createPrereQuisitesData() {
    if (this.isReadOnly == false) {
      if (this.createPrereQuisites.valid) {
        console.log("createPrereQuisitesData 2:", this.createPrereQuisites.value)
        // var statusValue = this.createPrereQuisites.value
        // if (statusValue.dcs == 'inprogress') {
        //   this.statusUpdateValue = 'inprogress'
        // } else if (statusValue.lockout == 'inprogress') {
        //   this.statusUpdateValue = 'inprogress'
        // } else if (statusValue.qa == 'inprogress') {
        //   this.statusUpdateValue = 'inprogress'
        // } else if (statusValue.wti == 'inprogress') {
        //   this.statusUpdateValue = 'inprogress'
        // } else {
        //   this.statusUpdateValue = 'done'
        // }
        console.log("statusUpdateValue :", this.statusUpdateValue)
        this.tagoutService.addTagoutPrerequisites(this.tagoutId, this.createPrereQuisites.value)
          .subscribe((res) => {
            var status = res.status
            // this.tagoutStatusCompleted();
            this.generalData = false;
            this.prereQuisites = false;
            this.amplifying = true;
          }, error => {
            console.error('There was an error!');
          })
        // this.generalData = false;
        // this.prereQuisites = false;
        // this.amplifying = true;
      }
    } else {
      this.generalData = false;
      this.prereQuisites = false;
      this.amplifying = true;
    }
  }
  backprereQuisitesWindow() {
    this.generalData = false;
    this.prereQuisites = true;
    this.amplifying = false;
  }
  createAmplifyingData() {
    if (this.isReadOnly == false) {
      if (this.createAmplifying.valid) {
        console.log("createAmplifying 3:", this.createAmplifying.value)
        this.tagoutService.addTagoutAmplifying(this.tagoutId, this.createAmplifying.value)
          .subscribe((res) => {
            var status = res.status
            if (this.statusUpdateValue == 'undefined' || this.statusUpdateValue == null) {
              this.statusUpdateValue = 'initiate'
              this.tagoutStatusCompleted();
            }
            this.generalData = false;
            this.prereQuisites = false;
            this.amplifying = false;
            this.authorization = false;
            this.finalCompletion = false;
            this.guarantee = false;
            this.maintainer = false;
            this.tagoutWindowState = false;
            this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
            this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true });
          }, error => {
            console.error('There was an error!');
          })
      }
    } else {
      this.generalData = false;
      this.prereQuisites = false;
      this.amplifying = false;
      this.authorization = false;
      this.finalCompletion = false;
      this.tagoutWindowState = false;
      this.guarantee = false;
      this.maintainer = false;
      this.broadcastInfo({ src: 'tagout', event: 'closeTagout', data: this.tagoutWindowState, key: "" });
    }
    // this.generalData = false;
    // this.prereQuisites = false;
    // this.amplifying = false;
    // this.authorization = false;
    // this.finalCompletion = false;
    // this.tagoutWindowState = false;
    // this.broadcastInfo({ src: 'tagout', event: 'closeTagout', data: this.tagoutWindowState, key: "" });
  }
  tagoutStatusCompleted() {
    this.tagoutService.approveTagoutData(this.tagoutId, { status: this.statusUpdateValue })
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
      }, error => {
        console.error('There was an error!');
      })
  }

  get mseoPrint() {
    return this.createAuthorization.get('mseoPrint');
  }
  get mseoDate() {
    return this.createAuthorization.get('mseoDate');
  }
  get mseoApproved() {
    return this.createAuthorization.get('mseoApproved');
  }
  get oodPrint() {
    return this.createAuthorization.get('oodPrint');
  }
  get oodDate() {
    return this.createAuthorization.get('oodDate');
  }
  get oodApproved() {
    return this.createAuthorization.get('oodApproved');
  }

  createAuthorizationData() {
    if (this.isActiveRole('guarantee', 'MSEO/CSEO')) {
      this.mseoPrint.clearValidators();
      this.mseoPrint.updateValueAndValidity();
      this.mseoDate.clearValidators();
      this.mseoDate.updateValueAndValidity()
      this.mseoApproved.clearValidators();
      this.mseoApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('guarantee', 'OOD/DTech')) {
      this.oodPrint.clearValidators();
      this.oodPrint.updateValueAndValidity();
      this.oodDate.clearValidators();
      this.oodDate.updateValueAndValidity()
      this.oodApproved.clearValidators();
      this.oodApproved.updateValueAndValidity();
    }
    if (this.createAuthorization.valid) {
      console.log("createAuthorization 4:", this.createAuthorization.value);
      this.tagoutService.addTagoutAuthorization(this.tagoutId, this.createAuthorization.value)
        .subscribe((res) => {
          this.statusUpdateValue = 'authorize';
          if (this.createAuthorization.value['mseoApproved'] && this.createAuthorization.value['oodApproved']) {
            this.tagoutStatusCompleted();
          }
          var logBookData = {
            'typeCollection': 'Lockout/Tagout',
            "tagName": this.statusUpdateValue,
            "description": this.notes.description,
            "startDate": this.datePipe.transform(this.tagoutService.sliderDate, 'yyyy-MM-dd'),
            "startTime": this.notes.startTime,
            "endTime": this.notes.endTime,
            "formStatus":this.statusUpdateValue
          };
          this.chitService.createLogBook(logBookData);
          this.generalData = false;
          this.prereQuisites = false;
          this.amplifying = false;
          this.authorization = false;
          this.finalCompletion = false;
          this.tagoutWindowState = false;
          this.guarantee = false;
          this.maintainer = false;
          this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
          this.notes['props'].activity = 'authorize';
          this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true, notes: this.notes });
          this.broadcastInfo({ src: 'logout/tagout', event: 'updateNotes', data: this.notes });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  get finalMaintainerPrint() {
    return this.createFinalCompletion.get('maintainerPrint');
  }
  get finalMaintainerDate() {
    return this.createFinalCompletion.get('maintainerDate');
  }
  get finalMaintainerApproved() {
    return this.createFinalCompletion.get('maintainerApproved');
  }
  get deptPrint() {
    return this.createFinalCompletion.get('deptPrint');
  }
  get deptDate() {
    return this.createFinalCompletion.get('deptDate');
  }
  get deptApproved() {
    return this.createFinalCompletion.get('deptApproved');
  }
  get finalMseoPrint() {
    return this.createFinalCompletion.get('mseoPrint');
  }
  get finalMseoDate() {
    return this.createFinalCompletion.get('mseoDate');
  }
  get finalMseoApproved() {
    return this.createFinalCompletion.get('mseoApproved');
  }
  get finalOodPrint() {
    return this.createFinalCompletion.get('oodPrint');
  }
  get finalOodDate() {
    return this.createFinalCompletion.get('oodDate');
  }
  get finalOodApproved() {
    return this.createFinalCompletion.get('oodApproved');
  }
  get lockoutPrint() {
    return this.createFinalCompletion.get('lockoutPrint');
  }
  get lockoutDate() {
    return this.createFinalCompletion.get('lockoutDate');
  }
  get lockoutApproved() {
    return this.createFinalCompletion.get('lockoutApproved');
  }

  createFinalCompletionData() {
    if (this.isActiveRole('authorize', 'Maintainer')) {
      this.finalMaintainerPrint.clearValidators();
      this.finalMaintainerPrint.updateValueAndValidity();
      this.finalMaintainerDate.clearValidators();
      this.finalMaintainerDate.updateValueAndValidity()
      this.finalMaintainerApproved.clearValidators();
      this.finalMaintainerApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('authorize', 'Dept Rep')) {
      this.deptPrint.clearValidators();
      this.deptPrint.updateValueAndValidity();
      this.deptDate.clearValidators();
      this.deptDate.updateValueAndValidity()
      this.deptApproved.clearValidators();
      this.deptApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('authorize', 'MSEO/CSEO')) {
      this.finalMseoPrint.clearValidators();
      this.finalMseoPrint.updateValueAndValidity();
      this.finalMseoDate.clearValidators();
      this.finalMseoDate.updateValueAndValidity()
      this.finalMseoApproved.clearValidators();
      this.finalMseoApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('authorize', 'OOD/DTech')) {
      this.finalOodPrint.clearValidators();
      this.finalOodPrint.updateValueAndValidity();
      this.finalOodPrint.clearValidators();
      this.finalOodPrint.updateValueAndValidity()
      this.finalOodApproved.clearValidators();
      this.finalOodApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('authorize', 'Lockout/Tagout(Coordinator)')) {
      this.lockoutPrint.clearValidators();
      this.lockoutPrint.updateValueAndValidity();
      this.lockoutDate.clearValidators();
      this.lockoutDate.updateValueAndValidity()
      this.lockoutApproved.clearValidators();
      this.lockoutApproved.updateValueAndValidity();
    }

    if (this.createFinalCompletion.valid) {
      console.log("createFinalCompletion 4:", this.createFinalCompletion.value)
      this.tagoutService.addTagoutFinalcompletion(this.tagoutId, this.createFinalCompletion.value)
        .subscribe((res) => {
          var status = res.status
          this.statusUpdateValue = 'done'
          if (this.createFinalCompletion.value['maintainerApproved'] && this.createFinalCompletion.value['deptApproved'] && this.createFinalCompletion.value['mseoApproved'] && this.createFinalCompletion.value['oodApproved'] && this.createFinalCompletion.value['lockoutApproved']) {
            this.tagoutStatusCompleted();
          }
          var logBookData = {
            'typeCollection': 'Lockout/Tagout',
            "tagName": this.statusUpdateValue,
            "description": this.notes.description,
            "startDate": this.datePipe.transform(this.tagoutService.sliderDate, 'yyyy-MM-dd'),
            "startTime": this.notes.startTime,
            "endTime": this.notes.endTime,
            "formStatus":this.statusUpdateValue
          };
          this.chitService.createLogBook(logBookData);
          this.generalData = false;
          this.prereQuisites = false;
          this.amplifying = false;
          this.authorization = false;
          this.finalCompletion = false;
          this.guarantee = false;
          this.maintainer = false;
          this.tagoutWindowState = false;
          this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
          this.notes['props'].activity = 'done';
          this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true, notes: this.notes });
          this.broadcastInfo({ src: 'logout/tagout', event: 'updateNotes', data: this.notes });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  get testedDate() {
    return this.createGuarantee.get('testedDate');
  }
  get testedPrint() {
    return this.createGuarantee.get('testedPrint');
  }
  get testedApproved() {
    return this.createGuarantee.get('testedApproved');
  }
  get maintainerPrint() {
    return this.createGuarantee.get('maintainerPrint');
  }
  get maintainerDate() {
    return this.createGuarantee.get('maintainerDate');
  }
  get maintainerApproved() {
    return this.createGuarantee.get('maintainerApproved');
  }
  createGuaranteeData() {
    if (this.isActiveRole('initiate', 'Tester')) {
      this.testedDate.clearValidators();
      this.testedDate.updateValueAndValidity();
      this.testedPrint.clearValidators();
      this.testedPrint.updateValueAndValidity()
      this.testedApproved.clearValidators();
      this.testedApproved.updateValueAndValidity();
    }
    if (this.isActiveRole('initiate', 'Maintainer')) {
      this.maintainerPrint.clearValidators();
      this.maintainerPrint.updateValueAndValidity();
      this.maintainerDate.clearValidators();
      this.maintainerDate.updateValueAndValidity()
      this.maintainerApproved.clearValidators();
      this.maintainerApproved.updateValueAndValidity();
    }
    if (this.createGuarantee.valid) {
      console.log("createGuarantee 5:", this.createGuarantee.value);
      this.tagoutService.addTagoutGuarantee(this.tagoutId, this.createGuarantee.value)
        .subscribe((res) => {
          this.statusUpdateValue = 'guarantee'
          if (this.createGuarantee.value['testedApproved'] && this.createGuarantee.value['maintainerApproved']) {
            this.tagoutStatusCompleted();
          }
          var logBookData = {
            'typeCollection': 'Lockout/Tagout',
            "tagName": this.statusUpdateValue,
            "description": this.createGuarantee.value.precautions,
            "startDate": this.datePipe.transform(this.tagoutService.sliderDate, 'yyyy-MM-dd'),
            "startTime": this.notes.startTime,
            "endTime": this.notes.endTime,
            "formStatus":this.statusUpdateValue
          };
          this.chitService.createLogBook(logBookData);
          this.generalData = false;
          this.prereQuisites = false;
          this.amplifying = false;
          this.authorization = false;
          this.finalCompletion = false;
          this.guarantee = false;
          this.maintainer = false;
          this.tagoutWindowState = false;
          this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
          this.broadcastInfo({ src: 'tagout', event: 'closeTagout', data: this.tagoutWindowState, key: "" });
          this.notes['props'].activity = 'guarantee';
          this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true, notes: this.notes });
          this.broadcastInfo({ src: 'logout/tagout', event: 'updateNotes', data: this.notes });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  get maintainerDataPrint() {
    return this.createMaintainer.get('maintainerDataPrint');
  }
  get maintainerDataDate() {
    return this.createMaintainer.get('maintainerDataDate');
  }
  get maintainerDataApproved() {
    return this.createMaintainer.get('maintainerDataApproved');
  }
  createMaintainerData() {
    if (this.isActiveRole('done', 'Qualified Person')) {
      this.maintainerPrint.clearValidators();
      this.maintainerPrint.updateValueAndValidity();
      this.maintainerDate.clearValidators();
      this.maintainerDate.updateValueAndValidity()
      this.maintainerApproved.clearValidators();
      this.maintainerApproved.updateValueAndValidity();
    }

    if (this.createMaintainer.valid) {
      console.log("createMaintainer 5:", this.createMaintainer.value)
      this.tagoutService.addTagoutMaintainer(this.tagoutId, this.createMaintainer.value)
        .subscribe((res) => {
          var status = res.status
          this.statusUpdateValue = 'maintainer';
          this.tagoutStatusCompleted();
          this.generalData = false;
          this.prereQuisites = false;
          this.amplifying = false;
          this.authorization = false;
          this.finalCompletion = false;
          this.guarantee = false;
          this.maintainer = false;
          this.tagoutWindowState = false;
          var logBookData = {
            'typeCollection': 'Lockout/Tagout',
            "tagName": this.statusUpdateValue,
            "description": this.notes.description,
            "startDate": this.datePipe.transform(this.tagoutService.sliderDate, 'yyyy-MM-dd'),
            "startTime": this.notes.startTime,
            "endTime": this.notes.endTime,
            "formStatus":this.statusUpdateValue
          };
          this.chitService.createLogBook(logBookData);
          this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
          this.notes['props'].activity = 'maintainer';
          this.broadcastInfo({ src: 'tagout', event: 'tagoutClose', status: true, notes: this.notes });
          this.broadcastInfo({ src: 'logout/tagout', event: 'updateNotes', data: this.notes });
        }, error => {
          console.error('There was an error!');
        })
    }
  }

  deletetagout() {
    this.tagoutService.deleteTagout(this.tagoutId)
      .subscribe((res) => {
        this.tagoutWindowState = false;
        this.broadcastInfo({ src: 'tagout', event: 'tagoutDataModified', data: 'true' });
        this.broadcastInfo({ src: 'tagout', event: 'closeTagout', data: this.tagoutWindowState, key: "" });
      }, error => {
        console.error('There was an error!');
      })
  }
  // editIcon: boolean = false;
  // cancelEditIcon: boolean = false;
  tagoutEditForm() {
    this.isReadOnly = false;
    this.tagHeading = "Edit Lockout Tagout"
    this.cancelEditIcon = true;
    // this.editIcon =
    this.createFormGroup.enable();
    this.createPrereQuisites.enable();
    this.createAmplifying.enable();
  }
  tagoutDisableForm() {
    this.isReadOnly = true;
    this.tagHeading = "View Lockout Tagout";
    this.cancelEditIcon = false;
    this.createFormGroup.disable();
    this.createPrereQuisites.disable();
    this.createAmplifying.disable();
  }

  isActiveRole(scheduleDuty, role) {
    role = role.split(" ").join("").toLowerCase();
    scheduleDuty = scheduleDuty.split(" ").join("").toLowerCase();
    const found = this.tagMasterData.some(el => el.scheduleDuty.split(" ").join("").toLowerCase() == scheduleDuty && el.role.split(" ").join("").toLowerCase() == role);
    return !found;
  }


  pickLocation() {
    this.locationForm = true;
    this.generalData = false;
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'loto';
  }

}
