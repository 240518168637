import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { TagService } from '../services/tag.service';
import { DateHighlightService } from "../services/date-highlight.service";
import { ReportService } from '../services/report.service';
import moment from 'moment';
import { of } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { RadhazVisService } from "../services/radhaz.service";

@Component({
  selector: 'app-slms-slider',
  templateUrl: './slms-slider.component.html',
  styleUrls: ['./slms-slider.component.scss']
})
export class SlmsSliderComponent implements OnInit {

  shipDate: any = 1;
  shipTime: any = 0;
  timeMaxvalue: any = 24;
  numberOfMinutes: any = 1440;
  hideUnselected: any = false;
  currentDayShipList: any = [];
  range: any = 1;
  monthRange = 27;
  shipObjects: any = [];
  broadcastSubs: any;
  date = new Date();
  sliderDate = new Date("01-MAR-2021");
  startDate = new Date("01-MAR-2021");
  endDate: any;
  activityEndDate: any;
  AnimationTimer: any;
  currentShipData: any = [];
  currentRangeShipData: any = []
  sliderSeconds: any = 0;
  sliderHours: any = 0;
  timerSpeed: any = '300';
  animationDate: any = new Date("01-MAR-2021");
  animationState: boolean = false;
  activeShipList: any = '';
  jettyMissingShipObjects: any = [];
  jettyMissingShipList: any = [];
  profileID: any;
  loginUserShip: any;
  loginUserStartDate: any;
  changeEvent: any = 'default';
  constructor(private radhazVisServ: RadhazVisService, private configService: ConfigService, private chitService: ChitService, private reportServ: ReportService, private tagServ: TagService, private ctrlServ: ControllerService, private dateService: DateHighlightService, private communicationServ: CommunicationService, private datePipe: DatePipe, private translate: TranslateService, private language: LanguageService) {
  }

  ngOnInit(): void {
    this.animationDate = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy');
  }


  /* * * * *
* on date change
* * * * * */
  dateChange(e) {
    this.changeEvent = "dateChange";
    this.sliderDate = e.value;
    this.shipDate = 1;
    this.range = Number(this.shipDate);
    this.animationDate = this.datePipe.transform(this.sliderDate, 'dd-MMM-yy');
    this.onChangeDateRange(this.shipDate);
  }

  /* * * * *
* time range value
* * * * * */
  onTimeChange(event) {
    alert('onTimeChange');
    console.log('datechange', event.value)

  }

  onChangescrub(value){
    this.changeEvent = "default";
    this.onChangeDateRange(value);

  }

  /* * * * *
* date range value
* * * * * */
  onChangeDateRange(value) {
    this.shipDate = value;
    this.range = Number(this.shipDate);
    if (this.range === 0) {
      this.timeMaxvalue = 24;
      this.numberOfMinutes = this.timeMaxvalue * 60;
    } else {
      this.timeMaxvalue = this.range * 24;
      this.numberOfMinutes = this.timeMaxvalue * 60;
    }
    this.filterTimeRange();
  }

  filterTimeRange() {
    let range = ((0 < this.range) ? this.range - 1 : this.range);
    let date = this.getRangeDate(range);
    let startDateTime = new Date(date);
    this.reportServ.setCurrentViewDate(startDateTime);
    this.showFilteredShips(startDateTime);
  }

  dayFilterShips: any = []
  showFilteredShips(startDateTime) {
    this.dayFilterShips = [];
    this.currentDayShipList = [];
    this.animationDate = this.datePipe.transform(startDateTime, 'dd-MMM-yy');
    this.ctrlServ.animationDate = new Date(this.animationDate);
    this.ctrlServ.currentActivityDate = startDateTime;
    if (this.changeEvent == 'dateChange') {
      this.toSendDateToApi();
    }else if (this.changeEvent == 'default'){
      this.filterScrubberData();
    }
  }

  timerStart: any;
  timeAction(e) {
    alert("timeAction")
  }

  /* * * * *
  * get start date
  * * * * * */
  getRangeDate(range) {
    let date = new Date(this.sliderDate);
    date.setDate(this.sliderDate.getDate() + range);
    let rangeDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    return rangeDate;
  }

  /* * * * *
  * formate date
  * * * * * */
  formateDate(date) {
    let startDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    return startDate;
  }

  DayChange(e) {
    if (e == 'pre') {
      if (this.shipDate == 1) {
        var d = new Date(this.sliderDate);
        d.setDate(d.getDate() - 1);
        this.sliderDate = new Date(d);
        this.changeEvent = "dateChange";
        this.onChangeDateRange(this.shipDate);
      } else {
        this.changeEvent = "default";
        this.onChangeDateRange(this.shipDate - 1);
      }
    } else if (e == 'next') {
      if (this.monthRange >= this.shipDate) {
        this.changeEvent = "default";
        this.onChangeDateRange(this.shipDate + 1);
      } else {
        var d = new Date(this.sliderDate);
        d.setDate(d.getDate() + 1);
        this.sliderDate = new Date(d);
        this.changeEvent = "dateChange";
        this.onChangeDateRange(this.shipDate);
      }
    }
  }

  /* * * * *
* load to current day ship
* * * * * */
  loadCurrentDayShip() {
    let range = 1;
    this.onChangeDateRange(range);
  }


  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  toSendDateToApi() {
    setTimeout(() => {
      this.broadcastInfo({ src: 'slms-slider', event: 'filterSlms', sliderDate: this.sliderDate, endDate: this.animationDate });
    })
  }

  filterScrubberData() {
    setTimeout(() => {
      this.broadcastInfo({ src: 'slms-slider', event: 'filterScrubber', sliderDate: this.sliderDate, endDate: this.animationDate });
    })
  }
}
