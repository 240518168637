import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
@Pipe({
  name: 'filterSearch'
})
export class FilterPipe implements PipeTransform {
  filterKey=['jetty','location1','kiosk','servicetype'];
constructor(private communicationServ: CommunicationService,) {
}
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];

    if(!searchText) return items;

    return this.searchItems(items, searchText.toLowerCase());
   }

   private searchItems(items :any[], searchText): any[] {
     let results = [];
  //    const results = items => items
  // .map(item => item.children 
  //   ? { ...item, children: results(item.children) }
  //   : item
  // )
  // .filter(item => item.status);

      // items.forEach(it => {
      //   if (it.includes(searchText)) {
      //       results.push(it);
      //   } else {
      //     let searchResults =  this.searchItems(it.items_containers, searchText);
      //     if (searchResults.length > 0) {
      //         results.push({
      //           title: it.title,
      //           items_containers: searchResults
      //         });
      //     }
      //   }
      // });
      for (let i = 0; i < items.length; i++) {
        for (const key in items[i]) {
          const value = items[i][key];
          if(typeof value === 'string'){
            if (value.includes(searchText)) {
              results.push(items[i]);
            } 
          }

        }
      }
      this.broadcastInfo({ src: 'pipe', event: 'filterData', data: results, state: null, sub: null });
      return results;
   }
     /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
}
