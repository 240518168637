import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import moment from 'moment';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { ChitService } from '../services/chit.service';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  showPanelOpen: any = false;
  shipHandleToggleOpen: boolean = false;
  filterHandleToggleOpen: boolean = false;
  serviceHandleToggleOpen: boolean = false;
  controlPosition: any = 'Right';
  controlDPosition: any = 'Left';
  startDate: any = new Date();
  endDate: any = new Date();
  sortItem: any = 'startdate';
  dateGroupList: any = [];
  expandDateList: any = [];
  selectedGroupItem: any = 'date'
  currentActivityDate: any;
  search: any = ""
  filterKey: any = [];
  shipStatus: any = ['inprogress', 'scheduled', 'completed'];
  statusAll: boolean = true;
  currentActivityList: any = [];
  activitySelectedList: any = [];
  activityAll: any = true;
  activeShipList: any;
  parentCatListIdx: any;
  shipClassList: any = [];
  filterObjectKeys: any = [];
  defaultDateRange: any = 27;
  @Input() set scheduleShowState(v: any) {
    this.showPanelOpen = v;
  }
  @Input() shipLoadingStatus: boolean = false;
  @Output() openPublishDialog: EventEmitter<any> = new EventEmitter<any>();
  invertState: any = true;
  selectedDateRange: any = 27;
  defaultRangeDate = {
    "startdate": "01-Mar-21",
    "enddate": "28-Mar-21",
    "drawName": "Duty.W"
  }
  groupItems: any = ['name', 'role', 'vessel', 'date' ];
  // sidepanelObject = [
  //   {
  //     "name": "John Doe", 
  //     "rank": "LCdr-CPO2",
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "VAN",  
  //     "location": "A1,2", 
  //     "notes": "run up equipment for a basin trial",
  //     "number": "FFH 331",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "09:00",
  //     "endtime": "16:00",
  //     "numberonduty":"3",
  //     "occupation": "Any",
  //     "status": "ACTIVE",
  //     "id": "1001",
  //     "icon":""
  //   },
  //   {
  //     "name": "Liam",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "VAN",
  //     "startdate": "12-Mar-21",
  //     "enddate": "19-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "finish up with any crane work",
  //     "location": "A1",
  //     "numberonduty":"2",
  //     "rank": "PO1-PO2",
  //     "occupation": "Operation / logistics / Executive / Deck",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1002",
  //     "icon":""
  //   },
  //   {
  //     "name": "William",    
  //     "role": "Machine Control Room(MCR)",
  //     "vessel": "VAN",
  //     "location": "C1",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "startdate": "07-Mar-21",
  //     "enddate": "11-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1003",
  //     "icon":""
  //   },
  //   {
  //     "name": "Noah",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "VAN",
  //     "location": "A1",
  //     "numberonduty":"2",
  //     "rank": "any",
  //     "occupation": "",
  //     "startdate": "20-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1004",
  //     "icon":""
  //   },
  //   {
  //     "name": "Jacob",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "CAL",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "opportunity for crane work",
  //     "location": "A1",
  //     "numberonduty":"2",
  //     "rank": "LS-OS",
  //     "occupation": "Combat System Engineering",
  //     "number": "FFH 335",
  //     "status": "ACTIVE",
  //     "id": "1005",
  //     "icon":""
  //   },
  //   {
  //     "name": "Logan",    
  //     "role": "Watchkeeper",
  //     "vessel": "REG",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Starting in the forenoon ",
  //     "location": "A2",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Logistics",
  //     "number": "FFH 334",
  //     "status": "ACTIVE",
  //     "id": "1006",
  //     "icon":""
  //   },
  //   {
  //     "name": "Nathan",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "REG",
  //     "startdate": "11-Mar-21",
  //     "enddate": "18-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "shceduled for forenoon, may extend in the afternoon",
  //     "location": "A2",
  //     "numberonduty":"1",
  //     "rank": "PO1-PO2",
  //     "occupation": "Executive Department",
  //     "number": "FFH 334",
  //     "status": "ACTIVE",
  //     "id": "1007",
  //     "icon":""
  //   },
  //   {
  //     "name": "Oliver",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "REG",
  //     "location": "A2",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Deck",
  //     "startdate": "10-Mar-21",
  //     "enddate": "10-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 334",
  //     "status": "ACTIVE",
  //     "id": "1008",
  //     "icon":""
  //   },
  //   {
  //     "name": "Lucas",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "REG",
  //     "location": "A2",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Combat System Engineering",
  //     "startdate": "19-Mar-21",
  //     "enddate": "24-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 334",
  //     "status": "ACTIVE",
  //     "id": "1009",
  //     "icon":""
  //   },
  //   {
  //     "name": "Ethan",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "REG",
  //     "location": "A2,2",
  //     "numberonduty":"2",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "NETP",
  //     "startdate": "25-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 334",
  //     "status": "ACTIVE",
  //     "id": "1010",
  //     "icon":""
  //   },
  //   {
  //     "name": "Thomas",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "OTT",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inner ring, cut in transit",
  //     "location": "C2",
  //     "numberonduty":"2",
  //     "rank": "PO1-PO2",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "number": "FFH 341",
  //     "status": "ACTIVE",
  //     "id": "1011",
  //     "icon":""
  //   },
  //   {
  //     "name": "Mason",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "OTT",
  //     "location": "C2",
  //     "numberonduty":"2",
  //     "rank": "MS-OS",
  //     "occupation": "Deck",
  //     "startdate": "02-Mar-21",
  //     "enddate": "02-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 341",
  //     "status": "ACTIVE",
  //     "id": "1012",
  //     "icon":""
  //   },
  //   {
  //     "name": "James",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "OTT",
  //     "location": "A2",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Fire Prevention",
  //     "startdate": "25-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 341",
  //     "status": "ACTIVE",
  //     "id": "1013",
  //     "icon":""
  //   },
  //   {
  //     "name": "Gabriel",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "WIN",
  //     "startdate": "06-Mar-21",
  //     "enddate": "09-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "location": "A2,2",
  //     "numberonduty":"2",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "Any",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1014",
  //     "icon":""
  //   },
  //   {
  //     "name": "Alexander",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "WIN",
  //     "startdate": "11-Mar-21",
  //     "enddate": "17-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inner ring, cut in transit",
  //     "location": "A2,2",
  //     "numberonduty":"1",
  //     "rank": "PO1-PO2",
  //     "occupation": "Executive Department",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1015",
  //     "icon":""
  //   },
  //   {
  //     "name": "Jack",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "WIN",
  //     "startdate": "18-Mar-21",
  //     "enddate": "18-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inner ring, cut in transit",
  //     "location": "C1",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Logistics",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1016",
  //     "icon":""
  //   },
  //   {
  //     "name": "Owen",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "WIN",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inner ring, cut in transit",
  //     "location": "YN",
  //     "numberonduty":"2",
  //     "rank": "Any",
  //     "occupation": "Any",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1017",
  //     "icon":""
  //   },
  //   {
  //     "name": "Adam",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "WIN",
  //     "location": "YN",
  //     "numberonduty":"2",
  //     "rank": "PO1-PO2",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "startdate": "02-Mar-21",
  //     "enddate": "05-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1018",
  //     "icon":""
  //   },
  //   {
  //     "name": "Leo",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "WIN",
  //     "location": "C1",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Executive Department",
  //     "startdate": "19-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 338",
  //     "status": "ACTIVE",
  //     "id": "1019",
  //     "icon":""
  //   },
  //   {
  //     "name": "Henry",    
  //     "role": "Duty Cook",
  //     "vessel": "YEL",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "A2,2",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Cook",
  //     "number": "MM 706",
  //     "status": "ACTIVE",
  //     "id": "1020",
  //     "icon":""
  //   },
  //   {
  //     "name": "Jackson",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "CHI",
  //     "location": "C4,2",
  //     "numberonduty":"2",
  //     "rank": "Any",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "startdate": "19-Mar-21",
  //     "enddate": "23-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1021",
  //     "icon":""
  //   },
  //   {
  //     "name": "Aiden",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "VAN",
  //     "startdate": "18-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "fault finding ",
  //     "location": "B2",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Executive Department",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1022",
  //     "icon":""
  //   },
  //   {
  //     "name": "Felix",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "VAN",
  //     "location": "B2",
  //     "numberonduty":"3",
  //     "rank": "LS-OS",
  //     "occupation": "Logistics",
  //     "startdate": "02-Mar-21",
  //     "enddate": "17-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1023",
  //     "icon":""
  //   },
  //   {
  //     "name": "Jayden",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "CHI",
  //     "startdate": "13-Mar-21",
  //     "enddate": "17-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Fault finding",
  //     "location": "C4,2",
  //     "numberonduty":"2",
  //     "rank": "Any",
  //     "occupation": "Logistics",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1024",
  //     "icon":""
  //   },
  //   {
  //     "name": "Zachary",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "NAN",
  //     "startdate": "24-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Fault finding",
  //     "location": "C3",
  //     "numberonduty":"2",
  //     "rank": "Any",
  //     "occupation": "Combat System Engineering",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1025",
  //     "icon":""
  //   },
  //   {
  //     "name": "Daniel",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "NAN",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Fault finding",
  //     "location": "C1",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Fire Prevention",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1026",
  //     "icon":""
  //   },
  //   {
  //     "name": "Ryan",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "WSR",
  //     "location": "C1",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Operations",
  //     "startdate": "04-Mar-21",
  //     "enddate": "04-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "SSK 877",
  //     "status": "ACTIVE",
  //     "id": "1027",
  //     "icon":""
  //   },
  //   {
  //     "name": "Carter",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "CHI",
  //     "startdate": "19-Mar-21",
  //     "enddate": "24-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "C4",
  //     "numberonduty":"2",
  //     "rank": "MS-OS",
  //     "occupation": "Executive Department",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1028",
  //     "icon":""
  //   },
  //   {
  //     "name": "Carter",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "EDM",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "C2,2",
  //     "numberonduty":"1",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "Operation / logistics / Executive / Deck",
  //     "number": "MM 703",
  //     "status": "ACTIVE",
  //     "id": "1029",
  //     "icon":""
  //   },
  //   {
  //     "name": "Charles",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "CHI",
  //     "location": "C4",
  //     "numberonduty":"4",
  //     "rank": "LS-OS",
  //     "occupation": "Logistics",
  //     "startdate": "25-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1030",
  //     "icon":""
  //   },
  //   {
  //     "name": "Parker",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "CHI",
  //     "startdate": "01-Mar-21",
  //     "enddate": "07-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Cleanup of upper deck MSCV work",
  //     "location": "C4",
  //     "numberonduty":"2",
  //     "rank": "PO1-PO2",
  //     "occupation": "Any",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1031",
  //     "icon":""
  //   },
  //   {
  //     "name": "Hunter",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "NAN",
  //     "startdate": "08-Mar-21",
  //     "enddate": "11-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "C5,2",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1032",
  //     "icon":""
  //   },
  //   {
  //     "name": "Isaac",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "SAS",
  //     "startdate": "12-Mar-21",
  //     "enddate": "16-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "C3",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Operations",
  //     "number": "MM 709",
  //     "status": "ACTIVE",
  //     "id": "1033",
  //     "icon":""
  //   },
  //   {
  //     "name": "Matthew",    
  //     "role": "Duty Cook",
  //     "vessel": "CHI",
  //     "location": "C4,2",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Cook",
  //     "startdate": "24-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1034",
  //     "icon":""
  //   },
  //   {
  //     "name": "Caleb",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "WHI",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inner ring, cut in transit",
  //     "location": "YS",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Deck",
  //     "number": "MM 705",
  //     "status": "ACTIVE",
  //     "id": "1035",
  //     "icon":""
  //   },
  //   {
  //     "name": "Nolan",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "WHI",
  //     "location": "YS",
  //     "numberonduty":"2",
  //     "rank": "Any",
  //     "occupation": "NETP",
  //     "startdate": "02-Mar-21",
  //     "enddate": "18-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "MM 705",
  //     "status": "ACTIVE",
  //     "id": "1036",
  //     "icon":""
  //   },
  //   {
  //     "name": "Hudson",    
  //     "role": "Duty Cook",
  //     "vessel": "WHI",
  //     "location": "C3,2",
  //     "numberonduty":"2",
  //     "rank": "Cook",
  //     "occupation": "Cook",
  //     "startdate": "19-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "MM 705",
  //     "status": "ACTIVE",
  //     "id": "1037",
  //     "icon":""
  //   },
  //   {
  //     "name": "Raphael",    
  //     "role": "Watchkeeper",
  //     "vessel": "CHI",
  //     "startdate": "08-Mar-21",
  //     "enddate": "12-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Fault finding polarization motor fault ",
  //     "location": "C4",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Logistics",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1038",
  //     "icon":""
  //   },
  //   {
  //     "name": "Evan",    
  //     "role": "Watchkeeper",
  //     "vessel": "NAN",
  //     "startdate": "13-Mar-21",
  //     "enddate": "13-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Fault finding polarization motor fault ",
  //     "location": "C5",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Combat System Engineering",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1039",
  //     "icon":""
  //   },
  //   {
  //     "name": "Lincoln",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "NAN",
  //     "startdate": "25-Mar-21",
  //     "enddate": "30-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "welding inspection of inner and outer ring ",
  //     "location": "C5",
  //     "numberonduty":"1",
  //     "rank": "PO1-PO2",
  //     "occupation": "Operation / logistics / Executive / Deck",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1040",
  //     "icon":""
  //   },
  //   {
  //     "name": "Dylan",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "NAN",
  //     "location": "C5",
  //     "numberonduty":"1",
  //     "rank": "PO1-PO2",
  //     "occupation": "Combat System Engineering",
  //     "startdate": "14-Mar-21",
  //     "enddate": "24-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1041",
  //     "icon":""
  //   },
  //   {
  //     "name": "David",    
  //     "role": "Machine Control Room(MCR)",
  //     "vessel": "CHI",
  //     "startdate": "02-Mar-21",
  //     "enddate": "06-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Shoot blockage profile",
  //     "location": "C4,2",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "NETP",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1042",
  //     "icon":""
  //   },
  //   {
  //     "name": "Elliot",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "CHI",
  //     "startdate": "13-Mar-21",
  //     "enddate": "17-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "C4",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Deck",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1043",
  //     "icon":""
  //   },
  //   {
  //     "name": "Anthony",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "WSR",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "C4,2",
  //     "numberonduty":"2",
  //     "rank": "LS-OS",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "number": "SSK 877",
  //     "status": "ACTIVE",
  //     "id": "1044",
  //     "icon":""
  //   },
  //   {
  //     "name": "Grayson",    
  //     "role": "Machine Control Room(MCR)",
  //     "vessel": "STJ",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "B1",
  //     "numberonduty":"2",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "Operation / logistics / Executive / Deck",
  //     "number": "FFH 340",
  //     "status": "ACTIVE",
  //     "id": "1045",
  //     "icon":""
  //   },
  //   {
  //     "name": "Joshua",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "FRE",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "B2",
  //     "numberonduty":"1",
  //     "rank": "PO1-PO2",
  //     "occupation": "Executive Department",
  //     "number": "FFH 337",
  //     "status": "ACTIVE",
  //     "id": "1046",
  //     "icon":""
  //   },
  //   {
  //     "name": "Alexis",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "COR",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "C4,3",
  //     "numberonduty":"2",
  //     "rank": "MS-OS",
  //     "occupation": "Logistics",
  //     "number": "SSK 878",
  //     "status": "ACTIVE",
  //     "id": "1047",
  //     "icon":""
  //   },
  //   {
  //     "name": "Theodore",    
  //     "role": "Machine Control Room(MCR)",
  //     "vessel": "SHA",
  //     "startdate": "01-Mar-21",
  //     "enddate": "01-Mar-21",
  //     "starttime": "08:00",
  //     "endtime": "16:00",
  //     "notes": "Power Balance, move live traffic, verify UPS Configuration is functioning",
  //     "location": "C5",
  //     "numberonduty":"1",
  //     "rank": "LS-OS",
  //     "occupation": "Combat System Engineering",
  //     "number": "MM 704",
  //     "status": "ACTIVE",
  //     "id": "1048",
  //     "icon":""
  //   },
  //   {
  //     "name": "Connor",    
  //     "role": "Brow Watchkeeper/Quartermaster",
  //     "vessel": "CHI",
  //     "location": "C4,2",
  //     "numberonduty":"2",
  //     "rank": "LS-OS",
  //     "occupation": "Logistics",
  //     "startdate": "07-Mar-21",
  //     "enddate": "07-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "SSK 879",
  //     "status": "ACTIVE",
  //     "id": "1049",
  //     "icon":""
  //   },
  //   {
  //     "name": "Luke",    
  //     "role": "Watchkeeper",
  //     "vessel": "NAN",
  //     "location": "C5",
  //     "numberonduty":"2",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "Executive Department",
  //     "startdate": "08-Mar-21",
  //     "enddate": "11-Mar-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "number": "MM 702",
  //     "status": "ACTIVE",
  //     "id": "1050",
  //     "icon":""
  //   },
  //   {
  //     "name": "Wyatt",    
  //     "role": "Officer of the Day(OOD)",
  //     "vessel": "VIS",
  //     "startdate": "01-Nov-20",
  //     "enddate": "30-Dec-20",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "location": "B2",
  //     "numberonduty":"2",
  //     "rank": "LCdr-CPO2",
  //     "occupation": "Marine Technician / Engineering Technician",
  //     "number": "F111",
  //     "status": "ACTIVE",
  //     "id": "1051",
  //     "icon":""
  //   },
  //   {
  //     "name": "Tristan",    
  //     "role": "Watchkeeper",
  //     "vessel": "VAN",
  //     "startdate": "01-Jan-21",
  //     "enddate": "30-Dec-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "location": "C3",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Executive Department",
  //     "number": "FFH 331",
  //     "status": "ACTIVE",
  //     "id": "1052",
  //     "icon":""
  //   },
  //   {
  //     "name": "Austin",    
  //     "role": "Duty Petty Officer(DPO)",
  //     "vessel": "VIS",
  //     "startdate": "01-Jan-21",
  //     "enddate": "30-Dec-21",
  //     "starttime": "",
  //     "endtime": "",
  //     "notes": "",
  //     "location": "C2",
  //     "numberonduty":"1",
  //     "rank": "MS-OS",
  //     "occupation": "Operations",
  //     "number": "F111",
  //     "status": "ACTIVE",
  //     "id": "1053",
  //     "icon":""
  //   },
  //   {
  //     "name": "Eli",    
  //     "role": "Duty Cook",
  //     "vessel": "HAL",
  //     "startdate": "04-Aug-21",
  //     "enddate": "03-Aug-21",
  //     "starttime": "12:22",
  //     "endtime": "12:02",
  //     "notes": "123",
  //     "location": "A1",
  //     "numberonduty":"1",
  //     "rank": "Any",
  //     "occupation": "Cook",
  //     "number": "FFH 330",
  //     "status": "ACTIVE",
  //     "id": "1054",
  //     "icon":""
  //   }
  // ]
  sidepanelObject: any;
  isFilterAll: boolean = true;
  defaultActivityList = [{ "name": "Radiating", "key": "radiating" },
  { "name": "Engine Exhuasting", "key": "engineexhuasting" },
  { "name": "Man-Aloft", "key": "man-aloft" },
  { "name": "Diving Operations", "key": "divingoperations" },
  { "name": "Crane Work", "key": "cranework" },
  { "name": "Cold Move", "key": "coldmove" },
  { "name": "Antenna Rotating", "key": "antennarotating" },
  { "name": "Stack Emissions", "key": "stackemissions" },
  { "name": "Fueling Operations", "key": "fuelingoperations" },
  { "name": "Ammunitioning", "key": "ammunitioning" },
  { "name": "Jetty Service", "key": "jettyservice" },
  { "name": "Other Activities", "key": "otheractivities" },
  { "name": "Manaloft/Rotating", "key": "manaloft/rotating" },
  { "name": "Manaloft/Radiate", "key": "manaloft/radiate" },
  { "name": "Truck Fuelling", "key": "truckfuelling" },
  { "name": "Ceremonial", "key": "ceremonial" },
  { "name": "Bollardpulls", "key": "bollardpulls" },
  { "name": "Radiate", "key": "radiate" },
  { "name": "UW Tx", "key": "uxtx" },
  { "name": "Diving Ops", "key": "divingops" },
  { "name": "Crane Ops", "key": "craneops" },
  { "name": "Antenna Rotate", "key": "antennarotate" },
  { "name": "Run Engines", "key": "runengines" },
  { "name": "Black-out", "key": "blackout" },
  { "name": "Basin Trial", "key": "basintrial" },
  { "name": "Fast Cruise", "key": "fastcruise" },
  { "name": "Fuelling", "key": "fuelling" },
  { "name": "Lifting Cert", "key": "liftingcert" },
  { "name": "Ceremonial", "key": "ceremonial" },
  { "name": "Incline Exp", "key": "inclineexp" },
  { "name": "Trimming", "key": "trimming" }]
  sidepanelUpdatedData: any;
  currentActivityListState: any = {};
  defRangeMetaData: any = [];
  scheduleWindowState: any = false;
  broadcastSubs: any;

  constructor(private ctrlServe: ControllerService, private communicationServ: CommunicationService, private translate: TranslateService, private languageChange: LanguageService, private datePipe: DatePipe,private chitService: ChitService) {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.broadcastSubs = this.communicationServ.getInstance()
  }

  ngOnInit(): void {
    // var sortData = this.alphabetOrder(this.sidepanelObject, 'activity');
    this.getOfficerData();
    this.broadcastSubs = this.communicationServ.getInstance()
    .subscribe((data: any) => {
      if (data.src === "sidepanel") {
        if(data.event == 'dutywToggle'){
          this.shipHandleToggleOpen=data['data'];
        }
        if (data.event === 'scheduleDataUpdate') {
          var data = data['data']
          console.log("Updated dssdsd--", data)
          var update= [];
          this.sidepanelUpdatedData.forEach(element => {
            if (element.id == data.id) {
              element.name = data.name
              element.vessel = data.vessel
              element.role = data.role
              element.location = data.location
              element.notes = data.notes
              element.startdate = data.startdate
              element.enddate = data.enddate
              element.starttime = data.starttime;
              element.endtime = data.endtime;
              element.numberonduty = data.numberonduty;
              element.occupation = data.occupation;
              element.rank = data.rank;
              update.push(element);
            }
          });
          this.scheduleWindowState = false;
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
        }
        if (data.event === 'scheduleDataInsert') {
          var data = data['data']
          if (data == 'true') {
            this.getOfficerData();
          }
          // this.sidepanelUpdatedData.push(data);
          // this.scheduleWindowState = false;
          // this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
        }
        
        if (data.event === 'scheduleDataDelete') {
          var data = data['data']
          this.sidepanelUpdatedData = this.sidepanelUpdatedData.filter(item => item.id !== data);
          this.scheduleWindowState = false;
          this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState });
        }
      }
      if (data.src === "slider") {
        if (data.event === 'updateListDate') {
          var defaultRangeDate = data['data'];
          if (defaultRangeDate) {
            var startDate = defaultRangeDate;
            var endDate = this.defaultRangeDate.enddate;
            this.getDateGroup(startDate, endDate);
          }
        }
        if (data.event === 'update_date_range') {
          var defaultRangeDate = data['data'];
          if (defaultRangeDate) {
            this.startDate = new Date(defaultRangeDate['startDate']);
            this.endDate = new Date(defaultRangeDate['endDate']);
            this.getDateGroup(this.startDate, this.endDate);
          }
        }
        if (data.event === 'changeDayRange') {
          if (data['key']) {
            this.todayFromDate = '';
            this.filterDateRange(this.selectedDateRange)
          }
        }
      }
    })
    // this.sidepanelUpdatedData = this.sidepanelObject;
    // this.defRangeMetaData = this.sidepanelObject;
    // this.startDate = new Date(this.defaultRangeDate.startdate);
    // this.endDate = new Date(this.defaultRangeDate.enddate);
    // this.getActivityList();
    // this.currentActivityDate = new Date(this.defaultRangeDate.startdate);
    // var listData = this.sidepanelUpdatedData[0]
    // this.getFilterKey(listData);
    // this.activityListSelect(this.sidepanelUpdatedData);
    // this.getDateGroup(this.startDate, this.endDate);
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ToggleOpenHanddle() {
    this.shipHandleToggleOpen = !this.shipHandleToggleOpen;
    if (!this.shipHandleToggleOpen) {
      if (window.innerWidth > 541) {
      }
    }
    if (!this.shipHandleToggleOpen) {
      var elem = document.getElementsByClassName('side_panel');
      elem[0].classList.remove('open');
    } else {
    }
  }
  getPanelwidth(e) {
    if (e == 'filterPanel') {
      let rawElem = document.getElementById('shipPanel');
      width = rawElem.offsetWidth;
      return width + 'px';
    } else {
      let elem = document.getElementById(e);
      var width = elem.offsetWidth;
      if (this.filterHandleToggleOpen) {
        let rawElem = document.getElementById('filterPanel');
        width += rawElem.offsetWidth;
      }
      if (this.serviceHandleToggleOpen) {
        let rawElem = document.getElementById('filterPanel');
        width += rawElem.offsetWidth;
      }
      if (e == 'toolPanel' || e == 'shipPanel_2' || e == 'servicesPanel' || e == 'cautionPanel') {
        let rawElem = document.getElementById(e);
        width = rawElem.offsetWidth;
        return width + 'px';
      }
      if (e == 'WFMPanel' || e == 'libPanel') {
        let rawElem = document.getElementById(e);
        width = rawElem.offsetWidth;
      }

      return 'translateX(' + width + 'px)';
    }
  }
  filterDateRange(range) {
    range = Number(range);
    if (this.defaultDateRange >= range) {
      this.processDateRange(range);
    } else {
      console.log(" out of range !!");
      this.selectedDateRange = range;
      this.ctrlServe.selectedDateRange = range;
      this.defaultDateRange = range
      this.ctrlServe.defaultDateRange = range;
      this.broadcastInfo({ src: 'main', event: 'changeDateRange', data: range, key: "" });
      this.processDateRange(range);
      // this.openPublishDialog.emit('daterange-confirm-box');      
    }
  }

    /* * * * *
  * process date range
  * * * * * */
    processDateRange(range) {
      this.selectedDateRange = range;
      range = Number(range);
      let filterData = [];
      let startDate = this.startDate;
      range = Number(range);//((0 < range) ? range - 1 : range);
      this.setRangeDate(range);
  
      for (let i = 0; i < range + 1; i++) {
        let shipDate = moment(startDate).add(i, 'days');
        this.defRangeMetaData.forEach(ship => {
          if (ship['status'] === 'ACTIVE') {
            if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
              const found = filterData.some(el => el.id === ship.id);
              if (!found) {
                console.log("ship Data", ship)
                filterData.push(ship);
              }
            }
          }
        });
      }
      this.sidepanelUpdatedData = filterData;
      this.sidepanelObject = filterData;
      this.activityListSelect(filterData);
      this.sortByShiplist(this.sortItem);
      let obj = { "startDate": startDate, data: filterData };
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: obj, key: "" });
      if (this.search != '') {
        this.searchData();
      }
      this.getDateGroup(this.startDate, this.endDate);
    }

  // Data formate and convert
  dateFormat(date) {
    if (date) {
      return this.datePipe.transform(date, 'MMMM d, y')
    }
  }

  getDayLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    return (days + 1) + " day(s)";
  }

  getDayHoursLength(startDate, endDate) {
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
  }

  formateDate(date) {
    if (date) {
      return this.datePipe.transform(date, 'dd-MMM-yy');
    } else {
      return '-'
    }
  }
  getDayHoursLength2(startDate, startTime, endDate, endTime) {
    startDate = this.datePipe.transform(startDate, 'dd-MMM-yy') + ' ,' + startTime;
    endDate = this.datePipe.transform(endDate, 'dd-MMM-yy') + ' ,' + endTime;
    var startCDate: any = new Date(startDate)
    var endCDate: any = new Date(endDate)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    return days + "days" + " " + hours + "hours";
  }
  alphabetOrder(data, key) {
    data.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    return data;
  }
  regEx(location) {
    return location;
  }
  regExReverse(location) {
    return location.replace(/\//g, "_").replace(/\s/g, '');
  }

  expandGroupCard(i, item, date) {
    if (this.sortItem == 'startdate') {
      if (this.expandDateList.length > 0) {
        var idx = this.expandDateList.findIndex(e => e['date'].groupValue == date.groupValue && e['data'].id == item.id);
        if (idx == -1) {
          this.expandDateList.push({ 'date': date, 'data': item });
        } else {
          if (!this.sidepanelUpdatedData[i].expand) {
            this.expandDateList.splice(idx, 1);
          }
        }
      } else {
        this.expandDateList.push({ 'date': date, 'data': item });
      }
    }
    console.log("this.expandDateList", this.expandDateList)
  }
  expandCheckDate(item, date) {
    if (this.sortItem == 'startdate') {
      if (this.expandDateList.length > 0) {
        var idx = this.expandDateList.findIndex(e => e['date'].groupValue == date.groupValue && e['data'].id == item.id);
        if (idx == -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  getGroupItems(key) {
    let objGroup = [];
    objGroup = this.sidepanelUpdatedData.map(el => el[key]);
    objGroup = objGroup.filter((value, i, arr) => arr.indexOf(value) === i);
    return objGroup;
  }
  getDateRange(startDate, endDate) {
    if (startDate && endDate) {
      var startCDate = new Date(startDate)
      var endCDate = new Date(endDate);
      for (var a: any = startCDate; startCDate <= endCDate; startCDate.setDate(startCDate.getDate() + 1)) {
        a = a + ',' + startCDate;
      }
      a = a.split(',');
      a.splice(0, 1);
    }
    return a;
  }
  getDateGroup(startDate, endDate) {
    if (startDate && endDate) {
      var res = [];
      if (this.selectedGroupItem === 'date') {
        res = this.getDateRange(this.datePipe.transform(startDate, 'MMMM d, y'), this.datePipe.transform(endDate, 'MMMM d, y'));
        this.addObjData(res);
      } else {
        res = this.getGroupItems(this.selectedGroupItem);
        this.addObjData(res);
      }
    }
  }
  addObjData(a) {
    this.dateGroupList = [];
    // this.dateGroupList = a.map(val => ({ groupName: this.selectedGroupItem, groupValue: this.datePipe.transform(val, 'MMMM d, y'), viewStatus: true, expand: true }));
    // console.log("this.dateGroupList", this.dateGroupList)
    if (this.selectedGroupItem === 'date') {
      this.dateGroupList = a.map(val => ({ groupName: this.selectedGroupItem, groupValue: this.datePipe.transform(val, 'MMMM d, y'), viewStatus: true, expand: true }));
    } else {
      this.dateGroupList = a.map(val => ({ groupName: this.selectedGroupItem, groupValue: val, viewStatus: false, expand: true }));
    }
  }
  checkDateGroup(act, groupItem, i) {
    var startDate = new Date(act.startdate);
    var endDate = new Date(act.enddate);
    var status = false;
    var dateValue = new Date(act.startdate);

    if (groupItem['groupName'] === 'date') {
      dateValue = new Date(groupItem.groupValue);
    } else {
      dateValue = endDate;
    }
      if (this.currentActivityDate) {
        if (new Date(this.currentActivityDate).getTime() <= new Date(dateValue).getTime()) {
          if (groupItem['groupName'] === 'date') {
            if (endDate >= dateValue && startDate <= dateValue) {
              this.dateGroupList[i].viewStatus = true;
              status = groupItem.expand ? true : false;
            }
          } else {
            if (act[groupItem['groupName']].split(" ").join("") === groupItem['groupValue'].split(" ").join("")) {
              if (endDate >= dateValue && startDate <= dateValue) {
                this.dateGroupList[i].viewStatus = true;
                status = groupItem.expand ? true : false;
              }
            }
          }
        } else {
          this.dateGroupList[i].viewStatus = true;
        }
      }

    return status;
  }

  onChangeGroupItem(event, key) {
    this.selectedGroupItem = key;
    this.getDateGroup(this.startDate, this.endDate);
  }

  // search fuction
  searchData() {
    var filterData = [];
    if (this.search === '') {
      filterData = null;
      this.sidepanelUpdatedData = this.sidepanelObject;
      this.statusShiplist(this.sidepanelObject);
      this.activityListSelect(this.sidepanelObject);
    } else {
      if (this.sidepanelObject.length > 0) {
        for (let i = 0; i < this.sidepanelObject.length; i++) {
          if (this.filterKey.length > 0) {
            this.filterKey.forEach((key) => {
              if (typeof this.sidepanelObject[i][key] === 'string' && typeof this.search === 'string') {
                if (this.sidepanelObject[i][key].toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                  const found = filterData.some(el => el.id === this.sidepanelObject[i].id);
                  if (!found) {
                    filterData.push(this.sidepanelObject[i]);
                  }
                }
              }
            });
          } else {
            if (this.sidepanelObject[i].id.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
              filterData.push(this.sidepanelObject[i]);
            }
          }
        }
      }
      this.sidepanelUpdatedData = filterData;
      this.statusShiplist(filterData);
      this.activityListSelect(filterData);
    }
    this.getDateGroup(this.startDate, this.endDate);
    this.mappingCategory();
    this.sortByShiplist(this.sortItem);
  }


  //  clear search

  searchClear() {
    this.search = "";
    this.sidepanelUpdatedData = this.sidepanelObject;
    this.statusShiplist(this.sidepanelObject);
    this.activityListSelect(this.sidepanelObject);
    this.sortByShiplist(this.sortItem);
    let filterdData = null;
    this.mappingCategory();
  }
  statusShiplist(e) {
    if (this.shipStatus.length != 3) {
      this.statusAll = false;
    } else {
      this.statusAll = true;
    }
    this.sidepanelUpdatedData = [];
    let idx = this.shipStatus.findIndex(e => e == 'all');
    if (idx == -1) {
      e.forEach(element => {
        let idx = this.shipStatus.findIndex(e => e == element.status);
        if (idx > -1) {
          this.sidepanelUpdatedData.push(element)
        }
      });
    } else {
      this.sidepanelUpdatedData = e;
    }
    this.setActiveShipIdx();
    this.sortByShiplist(this.sortItem);
  }
  //  Activity filter

  activityListSelect(shipData, def = false) {
    if (this.activitySelectedList.length != this.currentActivityList.length) {
      this.activityAll = false;
    } else {
      this.activityAll = true;
    }

    this.sidepanelUpdatedData = [];
    if (!this.activityAll) {
      if (this.activitySelectedList.length == 0) {
        this.sidepanelUpdatedData = [];
      }

      else {
        shipData.forEach(element => {
          let idx = this.activitySelectedList.findIndex(e => e == element.activity.replace(/\s/g, ''));
          if (idx > -1) {
            this.sidepanelUpdatedData.push(element)
          }
        });

        if (def) {
          if (this.search != '') {
            this.searchData();
          }
        }
      }

    } else {
      this.sidepanelUpdatedData = shipData;
      if (def) {
        if (this.search != '') {
          this.searchData();
        }
      }
    }
  }
  sortByShiplist(e) {
    this.sortItem = e;
    let direction = 1;
    this.sidepanelUpdatedData.sort((a, b) => {
      if (e == 'startdate' || e == 'enddate') {
        let aDate = new Date(a[e]);
        let bDate = new Date(b[e])
        if (aDate < bDate) {
          return -1 * direction;
        }
        else if (aDate > bDate) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      } else {
        if (a[e] < b[e]) {
          return -1 * direction;
        }
        else if (a[e] > b[e]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      }
    });

    if (this.activeShipList) {
      let idx = this.sidepanelUpdatedData.findIndex(e => e.id == this.activeShipList.id)
      if (idx > -1) {
        this.parentCatListIdx = idx;
      }
    }
  }

  //  Class mapping ship meta data

  mappingCategory() {
    this.shipClassList = [];
    if (this.sidepanelUpdatedData.length > 0) {
      this.sidepanelUpdatedData.forEach(element => {
        if (this.shipClassList.indexOf(element.class) == -1) {
          this.shipClassList.push(element.class);
        }
      });
    }
  }
  setActiveShipIdx() {
    if (this.activeShipList) {
      let idx = this.sidepanelUpdatedData.findIndex(e => e.vessel == this.activeShipList.vessel)
      if (idx > -1) {
        if (this.parentCatListIdx == null) {
        }
        this.parentCatListIdx = idx;
      } else {
        this.parentCatListIdx = null;
      }
    }
  }
  getFilterKey(listData) {
    this.filterObjectKeys = [];
    this.filterObjectKeys = Object.keys(listData);
    this.filterKey = this.filterObjectKeys;
  }
  filterToggle() {
    this.filterHandleToggleOpen = !this.filterHandleToggleOpen;
    if (window.innerWidth < 541) {
      this.ToggleOpenHanddle()
    }
  }
  // sortitem on change
  onChangeSortItem(event, key) {
    this.sortByShiplist(key);
  }
  //  select all activity

  activitySelectAll(checked) {
    this.getDateGroup(this.startDate, this.endDate);
    if (checked) {
      this.activitySelectedList = this.currentActivityList;
    } else {
      this.activitySelectedList = [];
    }
    this.activityListSelect(this.sidepanelUpdatedData, true);
  }
  getActivityList() {
    this.currentActivityList = [];
    this.currentActivityListState = {};
    if (this.sidepanelUpdatedData.length > 0) {
      var data = {};
      this.sidepanelUpdatedData.forEach(ship => {
        if (ship['status'] === 'ACTIVE') {
          let activity = ship['_id'].split(" ").join("").toLowerCase();
          const found = this.currentActivityList.some(el => el === activity);
          if (!found) {
            if (activity != '') {
              // console.log(activity)
              this.currentActivityList.push(activity);
              data[activity] = true;
            }
          }
        }
      });
      this.activitySelectedList = this.currentActivityList;
      this.currentActivityListState = data;
    }
  }

  //  method for if activity show or disabled

  isDisabledActivity(activity) {
    const found = this.currentActivityList.some(el => el === activity);
    return !found;
  }
  shipExtentIdx: any;
  shipExtent(i) {
    if (this.shipExtentIdx == undefined) {
      this.shipExtentIdx = i;
    } else {
      if (this.shipExtentIdx == i) {
        delete this.shipExtentIdx
      } else {
        this.shipExtentIdx = i;
      }
    }
  }

  coneExtentIdx: any;
  coneExtent(i) {
    if (this.coneExtentIdx == undefined) {
      this.coneExtentIdx = i;
    } else {
      if (this.coneExtentIdx == i) {
        delete this.coneExtentIdx
      } else {
        this.coneExtentIdx = i;
      }
    }
  }
  availableList(activity) {
    var state = true;
    this.currentActivityList.forEach(element => {
      if (element == activity) {
        state = false;
      }
    });
    return state;
  }
  getActivityStatus(activity) {
    const found = this.activitySelectedList.some(el => el === activity);
    return found;
  }
  onChangeActivity(event, activity) {
    this.getDateGroup(this.startDate, this.endDate);
    if (event.checked) {
      this.activitySelectedList.push(activity);
    } else {
      let idx = this.activitySelectedList.findIndex(el => el === activity);
      if (idx > -1) {
        this.activitySelectedList.splice(idx, 1);
        this.activityAll = false;
      }
    }
    this.getAcivityListKey();
    this.activityListSelect(this.sidepanelObject, true);
  }
  getAcivityListKey() {
    this.currentActivityList = [];
    if (this.defRangeMetaData.length > 0) {
      this.defRangeMetaData.map(ship => {
        if (ship['status'] === 'ACTIVE') {
          let activity = ship['activity'].split(" ").join("").toLowerCase();
          const found = this.currentActivityList.some(el => el === activity);
          if (!found) {
            if (activity != '') {
              this.currentActivityList.push(activity);
            }
          }
        }
      });
    }
  }

  //  method for reset all the filters

  resetFilter() {
    this.sortItem = 'startdate';
    this.searchClear();
    this.clearDateRange();
    this.activitySelectAll(true);
    this.filterSelectAll(true);
    this.getDateGroup(this.startDate, this.endDate);
    this.expandDateList = [];
  }

  //  select all

  filterSelectAll(checked) {
    this.isFilterAll = checked;
    if (checked) {
      this.filterKey = this.filterObjectKeys;
    } else {
      this.filterKey = [];
    }
  }

  //  clear date range

  clearDateRange() {
    if (this.defaultRangeDate) {
      this.selectedDateRange = 27;
      this.startDate = new Date(this.defaultRangeDate.startdate)
      this.endDate = new Date(this.defaultRangeDate.enddate)
      this.getDateGroup(this.startDate, this.endDate);
      this.sidepanelUpdatedData = this.defRangeMetaData;
      this.sidepanelObject = this.sidepanelUpdatedData;
      this.activityListSelect(this.sidepanelUpdatedData);
      this.sortByShiplist(this.sortItem);
      if (this.search != '') {
        this.searchData();
      }
    }
  }
  todayFromDate: any;
  onChangeDateToday(e) {
    console.log("onChangeDateToday", this.todayFromDate);
    // this.todayFromDate = e.target.value.replace(/[^0-9\.]+/g, '');
    if (this.todayFromDate != null) {
      let range = Number(this.todayFromDate);
      if (this.defaultDateRange >= range) {
        this.selectedDateRange = range;
        this.todayFromDate = null;
      } else {
        this.todayFromDate = null;
        this.openPublishDialog.emit('Given range is out of the date range, Please give within the date range !!');
      }
    }
  }

  // get date for calender

  setRangeDate(range) {
    let date = new Date(this.startDate);
    date.setDate(this.startDate.getDate() + range);
    this.endDate = date;
    this.getDateGroup(this.startDate, this.endDate);
  }

  // filter data based on date range
  dateRangeChange(dateRangeStart: any, dateRangeEnd: any) {
    delete this.todayFromDate
    let filterData = [];
    if (dateRangeEnd.value) {
      let startDate = dateRangeStart.value;
      let endDate = dateRangeEnd.value;
      var diffInMs = new Date(endDate).getTime() - new Date(startDate).getTime();
      let range = diffInMs / (1000 * 3600 * 24);
      this.setRangeDate(range);
      this.selectedDateRange = null;

      for (let i = 0; i < range + 1; i++) {
        let shipDate = moment(startDate).add(i, 'days');
        this.defRangeMetaData.forEach(ship => {
          if (ship['ship_status'] === 'show') {
            if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
              const found = filterData.some(el => el.name === ship.name);
              if (!found) {
                filterData.push(ship);
              }
            }
          }
        });
      }
      // this.sidepanelUpdatedData = filterData;
      // this.sidepanelObject = filterData;
      // this.activityListSelect(filterData);
      this.sortByShiplist(this.sortItem);
      let obj = { "startDate": startDate, data: filterData };
      this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: obj, key: "" });
      if (this.search != '') {
        this.searchData();
      }
      this.getDateGroup(this.startDate, this.endDate);
    }
  }
  // dateRangeChange(dateRangeStart: any, dateRangeEnd: any) {
  //   delete this.todayFromDate
  //   let filterData = [];
  //   if (dateRangeEnd.value) {
  //     let startDate = dateRangeStart.value;
  //     let endDate = dateRangeEnd.value;
  //     var diffInMs = new Date(endDate).getTime() - new Date(startDate).getTime();
  //     let range = diffInMs / (1000 * 3600 * 24);
  //     this.setRangeDate(range);
  //     this.selectedDateRange = null;

  //     for (let i = 0; i < range + 1; i++) {
  //       let shipDate = moment(startDate).add(i, 'days');
  //       this.defRangeMetaData.forEach(ship => {
  //         if (ship['status'] === 'ACTIVE') {
  //           if (shipDate['_d'] >= moment(ship.startdate)['_d'] && shipDate['_d'] <= moment(ship.enddate)['_d']) {
  //             const found = filterData.some(el => el.id === ship.id);
  //             if (!found) {
  //               filterData.push(ship);
  //             }
  //           }
  //         }
  //       });
  //     }
  //     this.sidepanelUpdatedData = filterData;
  //     this.sidepanelObject = filterData;
  //     this.activityListSelect(filterData);
  //     this.sortByShiplist(this.sortItem);
  //     let obj = { "startDate": startDate, data: filterData };
  //     // this.broadcastInfo({ src: 'sidepanel', event: 'filtered_data', data: obj, key: "" });
  //     if (this.search != '') {
  //       this.searchData();
  //     }
  //     this.getDateGroup(this.startDate, this.endDate);
  //   }
  // }
  scheduleData(data, date) {
    console.log("chitId", data);
    console.log("Ship Details", date.groupValue);
    this.scheduleWindowState = true;
    
    var activityDate = this.datePipe.transform(date.groupValue, 'MM-dd-yyy') ;
    this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "" });
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'scheduleData', data: data, key: data.activity, date: activityDate });
    }, 100)
  }
  scheduleCreate(){
    this.scheduleWindowState = true;
    setTimeout(() => {
      this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "schedule" });
    }, 100);
  }

  getOfficerData() {
    this.chitService.getOfficerDataList().subscribe(
      (response) => {
        console.log('data getOfficerData', response);
        response.forEach(element => {
          var startCDate: any = new Date(element.startdate)
          var endCDate: any = new Date(element.enddate)
          var delta = Math.abs(endCDate - startCDate) / 1000;
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          element['days'] = days.toString() + "days" + " " + hours.toString() + "hours";
          // responseData.push()
        });
        this.sidepanelObject = response;
        console.log('data getOfficerData', response);
        this.sidepanelUpdatedData = this.sidepanelObject;
        this.defRangeMetaData = this.sidepanelObject;
        this.startDate = new Date(this.defaultRangeDate.startdate);
        this.endDate = new Date(this.defaultRangeDate.enddate);
        this.getActivityList();
        this.currentActivityDate = new Date(this.defaultRangeDate.startdate);
        var listData = this.sidepanelUpdatedData[0]
        this.getFilterKey(listData);
        this.activityListSelect(this.sidepanelUpdatedData);
        this.getDateGroup(this.startDate, this.endDate);
      },
      error => {
        console.error('Request failed with error')
        // console.log(error)
      })
      // if (this.sidepanelObject.length > 0) {
        
      // }
  }
}
