import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationService } from '../services/communication.service';
import { ChitService } from '../services/chit.service';
import { ConfigService } from '../services/config.service';
import { SocketService } from '../services/socketService';
import { TagoutService } from '../services/tagout.service';
import { TagService } from '../services/tag.service';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {
  broadcastSubs: any;
  notificationData: string;
  approvalStatus: any;
  showLayout: boolean;
  visitorNotificationData: any;

  constructor(private communicationServ: CommunicationService, private socketService: SocketService, private chitService: ChitService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src == "admin-SB") {
          if (data.event == "openDialogConfirmStateBoardDelete") {
            this.openDialog('stateBoardDelete-confirm');
          }
        }
        if (data.src === "coursePanel") {
          if (data.event == 'openDialog') {
            let message = data['data'];
            this.showLayout = false;
            this.broadcastInfo({ src: 'coursePanel', event: "LayoutPanelClose", data: this.showLayout })
            this.openDialog(message);
          }
        }
        if (data.src == "comment") {
          if (data.event == 'openDialogConfirmLayoutName') {
            this.openDialog('layoutName-confirm');
          }
        }
        if (data.src === "material") {
          if (data.event === 'openDialogConfirmGroupDelete') {
            this.openDialog('groupdelete-confirm');
          }
          if (data.event === 'openDialogConfirmStatusDelete') {
            this.openDialog('statusdelete-confirm');
          }
          if (data.event == 'openDialogConfirmLayoutName') {
            //  this.saveNotesData = data.data;
            this.openDialog('layoutName-confirm');
          }
          if (data.event == 'openDialogConfirmLayoutDelete') {
            this.openDialog('layoutdelete-confirm');
          }
          if (data.event == 'openDialogConfirmNotesDelete') {
            this.openDialog('notesdelete-confirm');
          }
          if (data.event == 'openDialogConfirmLogBookDelete') {
            this.openDialog('logbookdelete-confirm');
          }
          if (data.event == 'userListAddConfrim') {
            //this.approval = data.data;
            this.openDialog('userListAdd-Confrim');
          }
          if (data.event == 'commentDelate-popup') {
            this.openDialog('commentDelate-popup');
          }
          if (data.event == 'notificationData') {
            this.notificationData = data.notificationData
            this.approvalStatus = data.data
            this.openDialog('notificationData');
          }  
          if (data.event == 'openVisitorNotification') {
            if(!this.chitService.isOpenPopupStatus){
              this.chitService.isOpenPopupStatus = true;
              this.visitorNotificationData = data.data;
              console.log("openVisitorNotification");
              this.openDialog('visitorNotification');
            }           
          }   
        }
        if(data.src=='sidepanel'){
          if(data.event == 'pinview'){
            
            this.openDialog('userPin');
          }
        }

      })
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * *
  * method for openDialog comp
  * * */
  openDialog(e): void {
    let width = '285px';
    let type = 'error';
    let value = '';
    if (e == "Layout name already exists !!") {
      value = "layoutSate";
    }
    if (e == 'layoutName-confirm') {
      type = 'layoutName-confirm';
    }
    if (e == "notificationData") {
      type = 'notificationData';
      value = this.notificationData
      width = "560px";
    }
    if (e == 'commentDelate-popup') {
      type = 'commentDelate-popup';
    }
    if (e == 'layoutdeleteNotes-confirm') {
      type = 'layoutdeleteNotes-confirm';
    }
    if (e == 'layoutName-confirm') {
      type = 'layoutName-confirm'
    }
    if (e == 'layoutdelete-confirm') {
      type = 'layoutdelete-confirm';
    }
    if (e == 'notesdelete-confirm') {
      type = 'notesdelete-confirm';
    }
    if(e == 'logbookdelete-confirm'){
      type = 'logbookdelete-confirm';
    }
    if (e == "stateBoardDelete-confirm") {
      type = "stateBoardDelete-confirm";
    }
    if (e == "userListAdd-Confrim") {
      type = "userListAdd-Confrim";
    }
    if (e == "statusdelete-confirm") {
      type = "statusdelete-confirm"
    }
    if (e == "groupdelete-confirm") {
      type = "groupdelete-confirm"
    }
    if (e == "userPin") {
      type = "userPin";
    }
    if (e == "visitorNotification") {
      type = "visitorNotification";
      value = this.visitorNotificationData
    }
    const dialogRef = this.dialog.open(PopupComponent, {
      panelClass: 'full-width-dialog',
      width: width,
      data: { value: value, error: e, type: type, approval: this.approvalStatus },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('aaaa', result)
      if (result) {
        if (result.toLowerCase() === 'layout-delete-yes') {
          this.broadcastInfo({ src: 'main', event: 'deleteLayoutData', data: "", sub: null });
        }
        if (result.toLowerCase() === 'layout-notes-yes') {
          this.broadcastInfo({ src: 'main', event: 'deleteNotesData', data: "", sub: null });
        }
        if (result.toLowerCase() === 'layout-logbook-yes') {
          this.broadcastInfo({ src: 'main', event: 'deleteLogBookData', data: "", sub: null });
        }
        if (result.toLowerCase() === 'group-delete-yes') {
          this.broadcastInfo({ src: 'main', event: 'deleteGroupData', data: "", sub: null });
        }
        if (result.toLowerCase() === 'status-delete-yes') {
          this.broadcastInfo({ src: 'main', event: 'deleteStatusData', data: "", sub: null });
        }
        if (result.toLowerCase() === 'layoutname-name-yes') {
          this.broadcastInfo({ src: 'main', event: 'layoutNameSave', data: '', sub: null });
        }
        if (result.toLowerCase() === 'layout-delete-notes-yes') {
          this.broadcastInfo({ src: 'main', event: 'layoutNotesDelete', data: '', sub: null });
        }
        if (result.toLowerCase() === 'userlist-add-yes') {
          this.broadcastInfo({ src: 'commentScreen', event: 'userListAddyes', data: '', sub: null });
        }
        if (result.toLowerCase() === 'comment-data-yes') {
          this.broadcastInfo({ src: 'commentScreen', event: 'deleteComment', data: "", sub: null });
        }
        if (result.toLowerCase() === "userlist-add-view") {
          this.broadcastInfo({ src: 'sidepanel', event: 'userList-add-view', data: "", sub: null });
        }
      }
    });
  }


}

export interface DialogData {
  data: string;
  error: any;
  type: any;
  approval: string;
}

@Component({
  selector: 'popup-model',
  templateUrl: 'popup-model.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class PopupComponent {
  approvalComment: any;
  activityMessage: any;
  isReadOnly = false
  notificationData: any;
  notesId: any;
  notesData: any;
  visitorInfo: any;
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>, 
    private tagoutServ: TagoutService,
    private tagServ : TagService,     
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private socketService: SocketService, public communicationServ: CommunicationService, private chitService: ChitService, private configservice: ConfigService) {
    if (this.data['type'] == 'visitorNotification') {
      this.visitorInfo = this.data['value']
    }
    if (this.data['type'] != 'visitorNotification' && this.data['value']) {
      this.notificationData = this.data['value'];
      this.notesId = this.notificationData.CommentScreendata._id
      if (this.data['value'].chitId) {
        this.chitId = this.data['value'].chitId
        if (this.data['value'].type == 'Turnover') {
          this.getTurnoverDataView();
        } else {
          this.getChitActivityDataView();
          this.getNotesDataView();
        }
      }
    }
    dialogRef.disableClose = true;
  }
  broadcastIframeInfo(data: any) {
    this.communicationServ.createIframeInstance(data);
  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  closeClick(): void {
    this.chitService.isOpenPopupStatus = false;
    this.dialogRef.close();
  }

  closeAddUserClick() {
    this.dialogRef.close();
    this.broadcastInfo({ src: 'commentScreen', event: 'closeUser', data: "", sub: null });
  }
  onDelete() {
    this.broadcastInfo({ src: 'commentScreen', event: 'deleteComment', data: "", sub: null });
    this.closeClick()
  }
  onNotesDelete() {
    this.broadcastInfo({ src: 'main', event: 'deleteNotesData', data: "", sub: null });
    this.closeClick()
  }
  onLogBookDelete() {
    this.broadcastInfo({ src: 'main', event: 'deleteLogBookData', data: "", sub: null });
    this.closeClick()
  }
  onStateBoardDelete() {
    this.broadcastInfo({ src: 'main', event: 'deleteStateBoardData', data: "", sub: null });
    this.closeClick();
  }
  onSaveCommentData() {
    this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null });
    this.closeClick();
  }
  messageData(e) {
    this.activityMessage = e.target.value
  }
  approval = 0;
  approvalStatus(event) {
    this.approval = event
    this.broadcastInfo({ src: 'commentScreen', event: 'approvalStatus', data: event, sub: null });
  }
  approveComment(event) {
    this.approvalComment = event
    this.broadcastInfo({ src: 'commentScreen', event: 'approvalStatus', data: event, sub: null });
  }
  notifiectionShip = false;
  notifiectionShipCollection = false;
  changeShipApproval(e, t, data) {
    if (t == 'first') {
      this.approvalComment = 'Approve ' + ' ' + data.ship + ' ' + "Schedulling" + ' ' + data.activity + ' ' + "at" + ' ' + data.location + ' ' + "Location"
      this.notifiectionShip = true;
      this.notifiectionShipCollection = false;
    }
    if (t == 'scound') {
      this.approvalComment = 'Approve ' + ' ' + data.Vessel + ' ' + "Schedulling" + ' ' + data.activity + ' ' + "at" + ' ' + data.Location + "Location"
      this.notifiectionShip = false;
      this.notifiectionShipCollection = true;
    }
  }

  rejected(data) {
    this.approvalComment = 'Rejected for Approval ' + ' ' + data.Vessel + ' ' + "Schedulling" + ' ' + data.activity + ' ' + "at" + ' ' + data.Location + "Location"
    this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    this.closeClick();
    this.updateStatus('no');
    this.updateDataNotification('no');
    this.createLog('reject');
  }
  
  userApproveData:any;
  approveEvent:any;
  Approve(data) {
    // this.approveEvent = "chitEvent";
    this.userApproveData=data;
    // delete this.userPinValid;
    // delete this.userPin;
    // this.userPinState=true;
    this.approvalComment = 'Approve ' + ' ' + this.userApproveData.Vessel + ' ' + "Schedulling" + ' ' + this.userApproveData.activity + ' ' + "at" + ' ' + this.userApproveData.Location + "Location"
    this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    this.closeClick();
    this.updateStatus('yes');
    this.updateDataNotification('yes');
    this.createLog('completed');
  }

  createLog(status){
    var logBookData = {
      'typeCollection': this.notesData.typeCollection, 
      "tagName": this.notesData.tagName,
      "description": this.activityMessage,
      "startDate": this.tagoutServ.sliderFormateDate, //this.data['value'].shipDetails.Date, 
      "startTime": this.notesData.startTime,
      "formStatus": status
    };
    this.chitService.createLogBook(logBookData);
  }
  
  approveContinue(){
    this.approvalComment = 'Approve ' + ' ' + this.userApproveData.Vessel + ' ' + "Schedulling" + ' ' + this.userApproveData.activity + ' ' + "at" + ' ' + this.userApproveData.Location + "Location"
    this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    this.closeClick();
    this.updateStatus('yes');
    this.updateDataNotification('yes');
    delete this.userApproveData;
  }

  ApproveCollision() {
    // this.userApproveData=data;
    // this.approveEvent = "collisionEvent";
    // delete this.userPinValid;
    // delete this.userPin;
    // this.userPinState=true;
    this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    this.closeClick();
    this.updateStatus('yes');
    delete this.userApproveData;
  }

  approveCollisionContinue(){

    // this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    // this.closeClick();
    // this.updateStatus('yes');
    // delete this.userApproveData;
  }

  ApproveTurnover(data) {
    this.userApproveData=data;
    // this.approveEvent = "turnoverEvent";
    // delete this.userPinValid;
    // delete this.userPin;
    // this.userPinState=true;
    this.approvalComment = 'Approve ' + ' ' + this.userApproveData.Vessel + ' ' + "Schedulling" + ' ' + this.userApproveData.activity + ' ' + "at" + ' ' + this.userApproveData.Location + "Location"
    // this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    this.closeClick();
    this.updateTurnoverStatus('yes');
    this.updateDataNotification('yes');
    delete this.userApproveData;
  }

  ApproveTurnoverContinue(){
    // this.approvalComment = 'Approve ' + ' ' + this.userApproveData.Vessel + ' ' + "Schedulling" + ' ' + this.userApproveData.activity + ' ' + "at" + ' ' + this.userApproveData.Location + "Location"
    // // this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment, meassage: this.activityMessage, sub: null, notificationData: this.data['value'] });
    // this.closeClick();
    // this.updateTurnoverStatus('yes');
    // this.updateDataNotification('yes');
    // delete this.userApproveData;
  }

  closeUserPin(){
    delete this.userPinValid;
    delete this.userPin;
    this.userPinState=false;
  }
  userPin:any;
  userPinValid:any;
  userPinState:boolean=false;
  validateUserPin(){
    if(this.userPin){
      this.chitService.getUserData()
      .subscribe(
        (response) => {
          var repos = response;
          console.log("getUserData",repos);
          let userData = repos.filter(e=> e['email']==this.configservice['loginInfo']['accountEmail'])
          if(userData.length>0){
            if(userData[0].Pin==this.userPin){
              delete this.userPinValid;
              this.userPinState=false;
              delete this.userPin;
              if(this.approveEvent=='chitEvent'){
                this.approveContinue();
              }
              if(this.approveEvent=='collisionEvent'){
                this.approveCollisionContinue();
              }
              if(this.approveEvent=='turnoverEvent'){
                this.ApproveTurnoverContinue();
              }
              
            }else{
              this.userPinValid = "Invalid Pin Number";
            }
          }
          // this.configService['userList'] = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
    }else{
      this.userPinValid = "Please Enter Pin Number";
    }

  }
  userPinValidate(e){
    console.log("userPinValidate",e);
    delete this.userPinValid;
  }

  numberPad(val){
    if(val=='backspace'){
      this.userPin =this.userPin.slice(0,-1);
    }else{
      this.userPin =this.userPin?this.userPin+val:val;
    }
  }


  updateDataNotification(e) {
    var data = {
      "chitId": this.chitId,
      "email": this.configservice.accountEmail,
      "status": e
    }
    // this.configService.getProjectConfig().then((project_config: any) => {
    this.chitService.updateNotification(data)
      .subscribe((res) => {
        this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'notification' });
        console.log("notification update", res)
      }, error => {
        console.error('There was an error!');
      })
    // })
  }

  chitId: any;
  activity: any;
  userEmailData: any = [];
  getChitActivityDataView() {
    this.chitService.getChitActivityData(this.chitId).subscribe(
      (response) => {
        var repos = response;
        // console.log("chit data--- : ", repos[0]); 
        if (repos.length !== 0) {
          this.activity = repos[0];
          this.userEmailData = JSON.parse(this.activity.notification);
          if (repos[0]['status']) {
            if (repos[0]['status'] == "completed") {
              this.notesDataUpdate('completed');
              // this.socketService.notificationUpdate('success');
            }
            if (repos[0]['status'] == "reject") {
              this.notesDataUpdate('reject');
              // this.socketService.notificationUpdate('success');
            }
          }
        }

      },
      error => {
        console.error('Request failed with error')
      })
  }
  // getTurnoverDataView() {
  //   this.configservice.getTurnoverSheetData(this.chitId).subscribe(
  //     (response) => {
  //       var repos = response;
  //       // console.log("chit data--- : ", repos[0]); 
  //       if (repos.length !== 0) {
  //         this.activity = repos[0];
  //         this.userEmailData = JSON.parse(this.activity.notification);
  //       }
  //     },
  //     error => {
  //       console.error('Request failed with error')
  //     })
  // }
  getTurnoverDataView() {
    this.configservice.getTurnoverSheetData(this.chitId).then(response => {
      console.log('getturnover', response);
      var repos = response;
      // console.log("chit data--- : ", repos[0]); 
      if (repos) {
        this.activity = repos[0];
        this.userEmailData = JSON.parse(this.activity.notification);
      }
    })
  }
  getNotesDataView() {
    this.configservice.getNotesView(this.notesId).then(response => {
      console.log('getNotesDataView', response);
      var repos = response;
      // console.log("chit data--- : ", repos[0]); 
      if (repos) {
        this.notesData = repos[0];
        if (this.notesData) {
          if (this.notesData['formStatus'] == "completed") {
            this.socketService.notificationUpdate('success');
          }
          if (this.notesData['formStatus'] == "reject") {
            this.socketService.notificationUpdate('success');
          }
        }

      }
    })
  }
  updateStatus(e) {
    this.notificationMessage = true;
    var currentUser = this.userEmailData.filter(t => t.email == this.configservice.accountEmail);
    if (currentUser.length > 0) {
      currentUser[0]['status'] = e;
      if (this.activityMessage) {
        currentUser[0]['message'] = this.activityMessage;
      }
      // this.userEmailData.forEach(element => {
      //   if (element.status == 'no' || element.status == 'inProgress') {
      //     this.notificationMessage = false;
      //     if (element.status == 'no') {
      //       this.notesDataUpdate('reject');
      //     }
      //   }
      // });
      // if (this.notificationMessage) {
      //   this.notesDataUpdate('completed');
      // }
      this.updateChit()
    }
  }
  updateChit() {
    if (this.chitId) {
      var emailId = JSON.stringify(this.userEmailData);
      var data = {
        status: (this.activity.status ? this.activity.status : ""),
        comments: (this.activityMessage ? this.activityMessage : ""),
        verified: (this.activity.verified ? this.activity.verified : ""),
        notification: (emailId.length > 0 ? emailId : [])
      }
      this.chitService.approveData(this.chitId, data)
        .subscribe((res) => {
          var status = res.status
          var data = res['value']
          if (data) {
            this.getChitActivityDataView();
            // if(data['status'] == "completed"){
            //   this.notesDataUpdate('completed');
            //   // this.socketService.notificationUpdate('success');
            // }
            // if(data['status'] == "reject"){
            //   this.notesDataUpdate('completed');
            //   // this.socketService.notificationUpdate('success');
            // }
          }
        }, error => {
          console.error('There was an error!');
        })
    }
  }


  rejectedTurnover(data) {
    this.approvalComment = 'Rejected for Approval ' + ' ' + data.Vessel + ' ' + "Schedulling" + ' ' + data.activity + ' ' + "at" + ' ' + data.Location + "Location"
    // this.broadcastInfo({ src: 'comment', event: 'saveActivityMessage', ApprovalStatus: this.approvalComment,meassage: this.activityMessage, sub: null ,notificationData : this.data['value']});
    this.closeClick();
    this.updateTurnoverStatus('no');
    this.updateDataNotification('no');
  }



  viewTrunoverSheet() {
    this.broadcastInfo({ src: 'sidepanel', event: 'stateBoardUser', data: { name: "stateBoard", state: false } });
    this.broadcastInfo({ src: 'sidepanel', event: 'stateBoardForm', data: true });
    this.broadcastInfo({ src: 'sidepanel', event: 'selectedStateBoardForm', data: this.chitId, mode: 'view' });
  }
  notificationMessage = false;
  updateTurnoverStatus(e) {
    this.notificationMessage = true;
    var currentUser = this.userEmailData.filter(t => t.email == this.configservice.accountEmail);
    if (currentUser.length > 0) {
      currentUser[0]['status'] = e;
      if (this.activityMessage) {
        currentUser[0]['message'] = this.activityMessage;
      }
      this.userEmailData.forEach(element => {
        if (element.status == 'no' || element.status == 'inProgress') {
          this.notificationMessage = false;
          if (element.status == 'no') {
            this.notesDataUpdate('reject');
          }
        }
      });
      if (this.notificationMessage) {
        this.broadcastInfo({ src: 'comment', event: 'allUserNotification', data: 'success' });
      } else {
        this.broadcastInfo({ src: 'comment', event: 'allUserNotification', data: 'no' });
      }
      this.updateTurnoverSheet();
    }
  }

  updateTurnoverSheet() {
    if (this.chitId) {
      var emailId = JSON.stringify(this.userEmailData);
      if (this.notificationMessage) {
        this.activity.status = 'completed'
        this.notesDataUpdate(this.activity.status);
      }
      var data = {
        formList: (this.activity.formList ? this.activity.formList : ""),
        status: (this.activity.status ? this.activity.status : ""),
        notification: (emailId.length > 0 ? emailId : [])
      }
      // this.configservice.updateTurnoverData(this.chitId, data)
      //   .subscribe((res) => {
      //     var status = res.status
      //   }, error => {
      //     console.error('There was an error!');
      //   })
      this.configservice.updateTurnoverData(this.chitId, data).then(response => {
        console.log('getturnover', response);
        var data = response['value']
        if (data) {
          if (data['status'] == "completed") {
            this.socketService.notificationUpdate('success');
          }
          if (data['status'] == "reject") {
            this.socketService.notificationUpdate('success');
          }
        }
      })

    }
  }

  notesDataUpdate(data) {
    var notesData = this.notificationData.CommentScreendata
    var notesId;
    if (notesData) {
      notesId = notesData._id
      // var comments = {
      //   collectionRefId: notesData.collectionRefId,
      //   description: notesData.description,
      //   layoutId: notesData.layoutId,
      //   markerId: notesData.markerId,
      //   startDate: notesData.startDate,
      //   status: notesData.status ? notesData.status : notesData.status,
      //   group : notesData.group || "",
      //   statusId: notesData.statusId,
      //   tagShapeId: notesData.tagShapeId,
      //   _id: notesData._id,
      //   typeCollectionId: notesData.typeCollectionId,close
      //   typeCollection: notesData.typeCollection,
      //   uploadFile: notesData.uploadFile ? notesData.uploadFile : [],
      //   userList: notesData.userList ? notesData.userList : [],
      //   formStatus: data?data: 'pending',
      //   props: notesData.props? notesData.props : [],
      //   endDate: notesData.endDate,
      //   parentMeshId: notesData.parentMeshId,
      //   shapeId: notesData.shapeId,
      //   tagName: notesData.tagName,
      // }
      if (notesId) {
        this.notesData['formStatus'] = data ? data : 'pending',
          this.updateNotesData(notesId, this.notesData);
      }
    }
  }
  updateNotesData(id, data) {
    this.configservice.updateNotes(id, data).then(res => {
      // if (data) {
      //   console.log("notes updated ", data);
      //   // this.getLayoutData();
      //   // this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      // }
      this.getNotesDataView();
      // var data = res['value']
      // if (data) {
      //   if(data['formStatus'] == "completed"){
      //     this.socketService.notificationUpdate('success');
      //   }
      //   if(data['formStatus'] == "reject"){
      //     this.socketService.notificationUpdate('success');
      //   }
      // }
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  acceptVisitor() {
    this.chitService.isOpenPopupStatus = false;
    this.chitService.createLogBook(this.visitorInfo['loogBookInfo']);
    this.configservice.updateTagShapeId(this.visitorInfo['visitorInfo']).subscribe(tagShape => {
      this.visitorUpdate(this.visitorInfo.scanInfo);      
      this.dialogRef.close();
    });
  }

  visitorUpdate(obj) {
    let location = obj.lastScanLocation.split(" ").join("").toLocaleLowerCase();
    if (location == 'gatein') {
      obj['signin'] = obj.lastScanDateTime;
      this.visitorSignIn(obj);
    } else if (location == 'gateout') {
      obj['signout'] = obj.lastScanDateTime;
      this.visitorSignOut(obj);
    } else {
      this.visitorScan(obj);
    }
  }

  visitorSignIn(obj) {
    if (obj) {
      this.chitService.visitorSignin(obj).subscribe(res => {
        console.log("visitorSignIn Sucess !!");             
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      })
    }
  }

  visitorSignOut(obj) {
    if (obj) {
      this.chitService.visitorSignout(obj).subscribe(res => {
        console.log("visitorSignOut Sucess !!");         
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      })
    }
  }

  visitorScan(obj) {
    if (obj) {
      this.chitService.visitorScan(obj).subscribe(res => {
        console.log("visitorScan Sucess !!");         
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      })
    }
  }

}
