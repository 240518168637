import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagoutService {
  project_config: any;
  private config: 'any';
  private _jsonURL = '../assets/project.config.json'; 
  private subject = new Subject<any>();
  sliderDate: any = new Date("01-MAR-2021");
  sliderFormateDate: any = "2021-03-01";

  // baseURL = 'http://localhost:4000/tagout/'
  // urlTagout: any = 'http://localhost:4000/tagoutmaster/';
  // urlRole: any = 'http://localhost:4000/tagoutRoles/';

  baseURL = 'https://nodesdms.azurewebsites.net/tagout/'
  urlTagout = 'https://nodesdms.azurewebsites.net/tagoutmaster/'
  urlRole: any = 'https://nodesdms.azurewebsites.net/tagoutRoles/';

  constructor(private http: HttpClient, private configService: ConfigService) { }
  getJSON(url = null): Observable<any> {
    return this.http.get(url || this._jsonURL);
  }

  getProjsectConfigs() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON().subscribe(data => {
          self.config = data[data["config"]];
          this.project_config = data;
          console.log("data config1111", this.project_config)
          resolve(self.config)
        },
          error => reject(error)
        );
      }
    })
  }
  getTagoutData(): Observable<any> {
    return this.http.get(this.baseURL)
  }
  getTagoutDataList(id: string): Observable<any> {
    return this.http.get(this.baseURL + id )
  }
  addTagout(data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.post(this.baseURL, body,{'headers':headers})
  }
  updateTagout(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + id, body,{'headers':headers})
  }
  addTagoutPrerequisites(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'prerequisites' + '/' + id, body,{'headers':headers})
  }
  getPrerequisitesDataList(id: string): Observable<any> {
    return this.http.get(this.baseURL + 'prerequisites' + '/' +  id )
  }
  addTagoutAmplifying(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'amplifying' + '/' + id, body,{'headers':headers})
  }
  getAmplifyingDataList(id: string): Observable<any> {
    return this.http.get(this.baseURL + 'amplifying' + '/' +  id )
  }
  addTagoutAuthorization(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'authorization' + '/' + id, body,{'headers':headers})
  }
  addTagoutFinalcompletion(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'finalcompletion' + '/' + id, body,{'headers':headers})
  }
  addTagoutGuarantee(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'guarantee' + '/' + id, body,{'headers':headers})
  }
  addTagoutMaintainer(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(data);
    return this.http.put(this.baseURL + 'maintainer' + '/' + id, body,{'headers':headers})
  }
  approveTagoutData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.baseURL + 'approve/' + id, body, { 'headers': headers })
  }
  deleteTagout(id: string): Observable<any> { 
    const headers = { 'content-type': 'application/json'}  
    return this.http.delete(this.baseURL + '/' + id, {'headers':headers})
  }
  // TagoutMaster
  getTagoutMasterData(): Observable<any> {
    return this.http.get(this.urlTagout)
  }
  getUserRoles(id: string): Observable<any> {
    return this.http.get(this.urlRole + id);
  } 
}
