import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

@Injectable()
export class ConfigService {
  appMode: any;
  accessToken: any;
  refershToken: any;
  loginInfo: any;
  // userList: any;
  private config: any;
  private _jsonURL = '../assets/project.config.json';
  project_config: any;
  // baseURL = 'http://localhost:3000/jetty/'
  baseURL = 'https://nodesdms.azurewebsites.net/jetty/';
  // userURL = 'http://localhost:3000/authaccess/';
  userURL = 'https://nodesdms.azurewebsites.net/authaccess/';
  weatherUrl = 'https://api.weatherapi.com/v1/forecast.json';
  weatherDevUrl = 'https://api.open-meteo.com/v1/forecast';
  webcamBaseURL:any ={'CA':'https://api.windy.com/api/webcams/v2/list/region=CA/?key=OHCVGpdWbcjUL8DZHOGSdYOWAdsPjcm9&',
  'HAL':'https://api.windy.com/api/webcams/v2/list/webcam=1181055395/?key=OHCVGpdWbcjUL8DZHOGSdYOWAdsPjcm9&'
}
  accountEmail: any;
  constructor(private http: HttpClient) {

  }

    /* * *
   * Layout API
   * * */
    getLayoutData() {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.Layout).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    getLayoutDatas(data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.postData(this.project_config.Layout+"Collectiondata/",data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    getLayout(id: string) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.Layout + id).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

    createLayout(data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.postData(this.project_config.Layout, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    updateLayout(id: string, data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.putData(this.project_config.Layout +"update/"+ id, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    deleteLayout(id: string) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.deleteData(this.project_config.Layout + id).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

  deleteLayoutData(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.Layout + 'delete/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  /* * *
 * Status API
 * * */
  getStatusData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.statusApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getStatus(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.statusApi + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  createStatus(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.statusApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateStatus(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.statusApi + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteStatus(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.statusApi + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getJSON(url = null): Observable<any> {
    return this.http.get(url || this._jsonURL);
  }
  getUserData(): Observable<any> {
    return this.http.get(this.userURL)
  }
  getProjectConfig() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON().subscribe(data => {
          self.config = data[data["config"]];
          this.project_config = data[data["config"]];
          console.log("data config", this.project_config)
          resolve(self.config)
        },
          error => reject(error)
        );
      }
    })
  }

  loadFile(url) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getJSON(url).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * get api call
  * * */
  getJsonData(url) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getJSON(url).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * post api call
  * * */
  pullData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }

  /* * *
  * get api call
  * * */
  getApiCall(url): Observable<any> {
    return this.http.get(url);
  }

  /* * *
  * get api call and head
  * * */
  getApiHeadCall(url): Observable<any> {
    // const header = { 'content-type': 'application/json','Accept':'*/*','Access-Control-Allow-Origin' : '*'}
    // const headers = new Headers();
    //     headers.append('Access-Control-Allow-Headers', 'Content-Type');
    //     headers.append('Access-Control-Allow-Methods', 'GET');
    //     headers.append('Access-Control-Allow-Origin', '*');
        const headers = new HttpHeaders({
          "Access-Control-Allow-Origin": '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
        });
    // return this.http.get(url,{headers:headers});
    return this.http.get<any>(url, {
      observe: "response",
      headers: headers
    });
  }

  /* * *
  * method for get Json data from server
  * * */
  pullJsonData(url, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.pullData(url, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for get Json data from server
  * * */
  getData(url, data, cbck, type = 'POST') {
    let self = this;
    if (type == 'POST') {
      return new Promise((resolve, reject) => {
        self.pullData(url, data).subscribe(data => {
          cbck(data)
        },
          error => reject(error)
        );
      })
    } else {
      return new Promise((resolve, reject) => {
        self.getApiCall(url).subscribe(data => {
          return data;
        },
          error => reject(error)
        );
      })
    }

  }

  /* * * * * *
  * excel upload
  * * * * * */
  async uploadExcelData(url, formData, cbck) {
    var xhr = new XMLHttpRequest();
    var reponse = "";
    // let url = this.project_config.xlUpload;
    xhr.open('POST', url, true);
    xhr.setRequestHeader('authorization', "Bearer " + this.accessToken);
    xhr.setRequestHeader('authentication', "no");
    xhr.onload = function (e) {
      reponse = xhr.responseText;
      cbck(reponse);
    };
    xhr.onreadystatechange = function (aEvt) {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          // alert("Excel Uploaded Successfully !!");
        } else {
          // alert("Excel Upload Falied !!");
        }
      }
    };
    xhr.send(formData);
  }

  getLaydownData(): Observable<any> {
    return this.http.get(this.baseURL)
  }

  getLaydownDatas(id: string): Observable<any> {
    return this.http.get(this.baseURL + id)
  }

  addLaydownDatas(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    console.log(body)
    return this.http.put(this.baseURL + id, body, { 'headers': headers })
  }

  /**
   * hazard area
   * */
  postData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }
  putData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.put(url, data, { 'headers': headers })
  }
  deleteData(url: string): Observable<any> {
    return this.http.delete(url)
  }
  getHazardData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.hazard).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  createHazard(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.hazard, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateHazard(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.hazard + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteHazard(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.hazard + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /**
   * first aid area
   */
  getFristAidData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.first_aid).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  createFirstAid(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.first_aid, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateFirstAid(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.first_aid + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteFirstAid(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.first_aid + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
* group Api start
* * */

  createGroup(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.groupApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateGroup(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.groupApi + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteGroup(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.groupApi + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  getGroupListData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.groupApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getWeatherData(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.weatherUrl + `?key=${data.apiKey}&q=${data.city}&days=${data.days}&aqi=no&alerts=no`).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getWeatherDataList(data){
    let self = this;
    var typeUrl = this.weatherDevUrl
    let lat = data.latitude?`?latitude=${data.latitude}`:'';
    let long = data.longitude?`&longitude=${data.longitude}`:'';
    let hourly = data.hourly?`&hourly=${data.hourly}`:'';
    let current_weather = data.current_weather?`&current_weather=${data.current_weather}`:'';
    let timezone = data.timezone?`&timezone=${data.timezone}`:'';
    let start = data.start_date?`&start_date=${data.start_date}`:'';
    let end = data.end_date?`&end_date=${data.end_date}`:'';
 
    typeUrl = typeUrl+lat+long+hourly+current_weather+timezone+start+end;
    let paramData = {param:typeUrl}
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.weatherApi+'/weather',paramData).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
/***
 * Get webcam data
 */
  getWebcamData(webcamApi) {
    console.log(this.webcamBaseURL + webcamApi)
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.webcamBaseURL[webcamApi['key']] + webcamApi['params']).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
    // return this.http.get(this.webcamBaseURL+webcamApiParams, { 'headers': headers })
  }

  /* * *
* Create SDMS Api start
* * */

  createSDMSLayout(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.SDMSLayout, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  createMarker(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.Marker, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  createNotes(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.Notes, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  createTagShape(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.TagShape, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  getMarker() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.Marker).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  getTagShape() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.TagShape).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  getNotes() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.getNotes).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  // update layout 

  updateSDMSLayout(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.SDMSLayout + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  updateMarker(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.SDMSMarker + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  updateNotes(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.Notes + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  updateTagShape(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.TagShape + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  updateTagShapeId(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.project_config.TagShape+'tagShapeId/', body, { 'headers': headers })
  }

  // delete

  deleteSDMSLayout(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.SDMSLayout + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  deleteMarker(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.Marker + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  deleteNotes(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.Notes + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getNotesView(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.Notes + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  deleteTagShape(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.TagShape + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  getMaterialStatusData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.materialApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }


  /* * *
    * State board API
    * * */
  getStateBoardListData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.stateBoard).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  createStateBoard(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.stateBoard, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateStateBoard(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.stateBoard + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteStateBoard(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.stateBoard + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
 
  
 
    /* * *
    * State board Form API
    * * */
    getStateBoardFormView(id: string) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.stateBoardForm + id).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

    getStateBoardFormListData() {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.stateBoardForm).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
  
    createStateBoardForm(data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.postData(this.project_config.stateBoardForm, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
  
    updateStateBoardForm(id: string, data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.putData(this.project_config.stateBoardForm + id, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
  
    // deleteStateBoardForm(id: string) {
    //   let self = this;
    //   return new Promise((resolve, reject) => {
    //     self.deleteData(this.project_config.stateBoardForm + id).subscribe(data => {
    //       resolve(data);
    //     },
    //       error => reject(error)
    //     );
    //   })
    // }
    
  /* * *
* Upload API
* * */
  postUploadData(url, data): Observable<any> {
    return this.http.post(url, data)
  }


  // layout datas

  
    /* * *
   * Layout API
   * * */
    getLayoutDataCollection() {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.Layout).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    getLayoutDatasCollection(data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.postData(this.project_config.Layout+"CollectionNotesData",data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

    getCollectonlayoutData(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.Layout + "collectionlayoutData", data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }


    getLayoutCollection(id: string) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.getApiCall(this.project_config.Layout + id).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

    createLayoutCollection(data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.postData(this.project_config.Layout, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    updateLayoutCollection(id: string, data) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.putData(this.project_config.Layout +"update/"+ id, data).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }
    deleteLayoutCollection(id: string) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.deleteData(this.project_config.Layout + id).subscribe(data => {
          resolve(data);
        },
          error => reject(error)
        );
      })
    }

  deleteLayoutDataCollection(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.Layout + 'delete/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  /******
   * Turnover sheet
   */
   turnoverSheetCreate(data){
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.turnover, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
   getTurnoverSheetData(turnover){
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.turnover + turnover).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
   getTurnoverSheetDatas(){
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.turnover).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
   updateTurnoverData(id,data){
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.turnover + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
   deleteTurnover(id){
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.turnover + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
   deleteTurnoverForm(id){
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.stateBoardForm + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
   }
  getOodScheduleData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.chitMasterSchedule).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

}
