import * as uuid from 'uuid';

declare var BABYLON;
export class CutOffEffect {
    dynTexture
    glassMat = []
    updateMode = true;
    spots = []
    cutoff_angle = 1;///15.39
    transMat;
    invMat;
    constructor() {

    }
    getTransMaterial(scene) {
        if (this.transMat) {
            return this.transMat;
        }
        let cmat = new BABYLON.PBRCustomMaterial("slicerTransMat", scene)
        cmat.metallic = 0;
        cmat.roughness = 0.4;
        cmat.specularColor = new BABYLON.Color3(1, 1, 1);
        cmat.albedoColor = new BABYLON.Color3(1, 1, 1);
        cmat.alpha = 0.3;
        this.transMat = cmat;
        return cmat;
    }
    getInvMaterial(scene) {
        if (this.invMat) {
            return this.invMat;
        }
        let cmat = new BABYLON.PBRCustomMaterial("slicerInvMat", scene)
        cmat.metallic = 0;
        cmat.roughness = 0.4;
        cmat.specularColor = new BABYLON.Color3(1, 1, 1);
        cmat.albedoColor = new BABYLON.Color3(1, 1, 1);
        cmat.alpha = 0.001;
        this.invMat = cmat;
        return cmat;
    }
    applyShaderToTarget(wall, scene) {
        let engine = scene.getEngine();
        let wallMat = wall.material;
        if (wallMat.fbfapplied) {
            wallMat.maxSimultaneousLights += 1;
            return
        }
        let cmat = new BABYLON.PBRCustomMaterial("wallMat", scene)
        cmat.metallic = wallMat.metallic;
        cmat.roughness = wallMat.roughness;
        cmat.specularColor = wallMat.specularColor;
        cmat.albedoColor = wallMat.albedoColor;
        cmat.Fragment_Before_Fog(`
            #if defined(PROJECTEDLIGHTTEXTURE4)
                vec4 strq = textureProjectionMatrix4 * vec4(vPositionW, 1.0);
                strq /= strq.w;
                vec3 proj = strq.x < 0. || strq.x > 1. || strq.y < 0. || strq.y > 1. ? vec3(0.) : texture${engine.version === 1 && engine.name === "WebGL" ? "2D" : ""}(projectionLightSampler4, strq.xy).rgb;
                if (proj.r == 1. && proj.g == 0. && proj.b == 0.) discard;
            #elif defined(PROJECTEDLIGHTTEXTURE5)
                vec4 strq = textureProjectionMatrix5 * vec4(vPositionW, 1.0);
                strq /= strq.w;
                vec3 proj = strq.x < 0. || strq.x > 1. || strq.y < 0. || strq.y > 1. ? vec3(0.) : texture${engine.version === 1 && engine.name === "WebGL" ? "2D" : ""}(projectionLightSampler5, strq.xy).rgb;
                if (proj.r == 1. && proj.g == 0. && proj.b == 0.) discard;
            #elif defined(PROJECTEDLIGHTTEXTURE6)
                vec4 strq = textureProjectionMatrix6 * vec4(vPositionW, 1.0);
                strq /= strq.w;
                vec3 proj = strq.x < 0. || strq.x > 1. || strq.y < 0. || strq.y > 1. ? vec3(0.) : texture${engine.version === 1 && engine.name === "WebGL" ? "2D" : ""}(projectionLightSampler6, strq.xy).rgb;
                if (proj.r == 1. && proj.g == 0. && proj.b == 0.) discard;
            #elif defined(PROJECTEDLIGHTTEXTURE7)
                vec4 strq = textureProjectionMatrix7 * vec4(vPositionW, 1.0);
                strq /= strq.w;
                vec3 proj = strq.x < 0. || strq.x > 1. || strq.y < 0. || strq.y > 1. ? vec3(0.) : texture${engine.version === 1 && engine.name === "WebGL" ? "2D" : ""}(projectionLightSampler7, strq.xy).rgb;
                if (proj.r == 1. && proj.g == 0. && proj.b == 0.) discard;
            #endif
        `)
        cmat.fbfapplied = true;
        cmat.maxSimultaneousLights += 1;
        cmat.backFaceCulling = false;
        wall.omat = wallMat.name;
        wall.material = cmat;

    }
    resetMat(scene, targ) {
        if (!targ.omat) {
            return
        }
        let mat = scene.getMaterialByName(targ.omat)
        if (mat) {
            targ.material = mat;
        }
    }
    getGlassMat(scene) {
        let mat = new BABYLON.PBRMaterial("glass", scene)
        mat.alpha = .25
        mat.emissiveColor = new BABYLON.Color3(0, 1, 0);
        return mat;
    }
    getProjectionMat(scene, pos) {

        const glass = { position: pos.clone(), size: new BABYLON.Vector3(1, 1, 1) }
        return glass;
    }
    updateCutPos(pos, idx = null, d = null) {
        let index = idx || this.spots.length - 1;
        let spotLight = this.spots[index];
        let target = this.glassMat[index];
        if (d) {
            target.size = d;
        }
        let _pos = pos.clone();
        _pos.x = -_pos.x;
        spotLight.position = target.position = _pos;
        let size = target.size;

        this.setSpotLightProjectionMatrix(spotLight, target, size);
        console.log("update cutTarget")
    }
    async cutTarget(pos, wall, scene, plane, d = null, n) {
        this.applyShaderToTarget(wall, scene);
        let uid = uuid.v4();
        let _pos = pos.clone();

        /* glass.position=pos;
        glass.rotation.y=Math.PI/2 */
        /* const bb = glass.getHierarchyBoundingVectors()
        const size = bb.max.subtract(bb.min) */
        //let n=wall.getFacetNormal(fid);
        let ort = plane;
        /* if(Math.abs(n.x)>0.5){
            ort='x'
        }
        if(Math.abs(n.y)>0.5){
            ort='y'
        } */
        if (ort == 'x') {
            // _pos.x=-_pos.x;
        }
        let glass = this.getProjectionMat(scene, _pos);
        if (d) {
            glass.size = d;
        }

        const spot = await this.createSpotLight(glass, wall, scene, ort, n);
        this.spots.push(spot);
        this.glassMat.push(glass);
        console.log("cutTarget", glass.size);
        //this.setSpotLightProjectionMatrix(spot, glass, size);
    }
    setSpotLightProjectionMatrix(spotLight, target, size, o = 'z') {
        let m = [0, 0, 0, 0]
        if (o === 'z') {
            m = [-size.x / 2 - target.position.x,
            size.x / 2 - target.position.x,
            -size.y / 2 + target.position.y,
            size.y / 2 + target.position.y]
        }
        if (o === 'x') {
            m = [-size.z / 2 - target.position.z,
            size.z / 2 - target.position.z,
            -size.y / 2 + target.position.y,
            size.y / 2 + target.position.y]
        }
        if (o === 'y') {
            m = [-size.z / 2 - target.position.z,
            size.z / 2 - target.position.z,
            -size.x / 2 + target.position.x,
            size.x / 2 + target.position.x]
        }
        console.log("PROJ", o, m);
        spotLight._projectionTextureProjectionLightMatrix = BABYLON.Matrix.OrthoOffCenterLH(
            m[0],
            m[1],
            m[2],
            m[3], 0, 1
        );
        spotLight._projectionTextureProjectionLightDirty = false;
        spotLight._projectionTextureDirty = true;
        setTimeout(() => {
            spotLight.angle = this.cutoff_angle || 1
        }, 500)
    }

    async createSpotLight(target, wall, scene, ort, norm) {
        //const bb = target.getHierarchyBoundingVectors()
        const size = target.size;// bb.max.subtract(bb.min)

        const spotPos = wall.getBoundingInfo().boundingBox.centerWorld;//target.position.clone();
        //spotPos.x+=0.2
        console.log(norm);
        const spotDir = this.roundOffVector(norm);
        const spotLight = new BABYLON.SpotLight("spot", spotPos, spotDir, this.cutoff_angle || 1, 1, scene)
        // spotLight.setDirectionToTarget(BABYLON.Vector3.Zero());
        this.setSpotLightProjectionMatrix(spotLight, target, size, ort);

        spotLight.projectionTexture = await this.createTexture(scene)

        spotLight.intensity = 0
        spotLight.includedOnlyMeshes = [wall]

        return spotLight;
    }

    async createTexture(scene) {

        const textureSize = 1024

        const dynamicTexture = new BABYLON.DynamicTexture("discardTexture", textureSize, scene, false, BABYLON.Constants.TEXTURE_NEAREST_SAMPLINGMODE)
        const context = dynamicTexture.getContext()

        context.fillStyle = 'rgb(255, 0, 0)'
        context.fillRect(0, 0, textureSize, textureSize)

        dynamicTexture.update()

        return dynamicTexture
    }
    cround(num) {
        return Math.sign(num) * Math.round(Math.abs(num))
    }
    roundOffVector(v) {
        let num = v.x;
        let x = this.cround(num);
        num = v.y;
        let y = this.cround(num);
        num = v.z;
        let z = this.cround(num);
        return new BABYLON.Vector3(x, y, z)
    }
    /**
     * cut effect using BABYLON CSG
     */
    csgArr = {}
    createSlicer(pos, d, scene, uid = null) {
        let id = uid || 'slicer';
        let slicer = BABYLON.MeshBuilder.CreateBox(id, d, scene);
        slicer.position = pos.clone();
        //slicer.isVisible=false;
        slicer.material = this.getInvMaterial(scene);
        return slicer

    }
    cutTargetAt(target, pos, scene, d, slicer = null) {
        let name = target.name;
        if (name.includes("_sliced")) {
            name = name.split("_sliced")[0];
        }
        let tmesh = this.csgArr[name];
        let dispose = true;
        if (!this.csgArr[name]) {
            tmesh = target;
            dispose = false;
        }
        let tcsg = BABYLON.CSG.FromMesh(tmesh);
        let box = slicer || this.createSlicer(pos, d, scene);
        let bcsg = BABYLON.CSG.FromMesh(box);
        let subcsg = tcsg.subtract(bcsg);
        let nmesh = subcsg.toMesh(name + "_sliced", tmesh.material, scene);
        nmesh.mid=tmesh.mid;
        this.csgArr[name] = nmesh;
        if (tmesh.parent) {
            nmesh.parent = tmesh.parent;
        }
        //nmesh.createNormals(true);
        this.invertNormals(nmesh);
        //box.isVisible=false;
        if (dispose) {
            tmesh.dispose()
        } else {
            tmesh.sliced=true;
            tmesh.setEnabled(false);
        }
    }
    invertNormals(m) {
        var vertex_data = BABYLON.VertexData.ExtractFromMesh(m);
        // inverse the order of each face's vertices
        var temp;
        for (var i = 0; i < vertex_data.positions.length; i += 3) {
            vertex_data.normals[i] = -vertex_data.normals[i]
            vertex_data.normals[i + 1] = -vertex_data.normals[i + 1]
            vertex_data.normals[i + 2] = -vertex_data.normals[i + 2];
        }
        vertex_data.applyToMesh(m);
    }
    /**
     * Polygon Custom Mesh
     *  */
    createFillShape(pos, points, plane, scene, uid = null) {
        let id = uid + "_fill" || '_fill';
        let lpoints = this.translatePointsToOrigin(points, pos);
        let dir = this.directionOfVerts(lpoints, plane);

        let fill = this.createPolygonFromPath(lpoints, plane, id, scene, dir);
        fill.position = pos.clone();
        fill.position[plane] -= (0.1 * dir)

        return fill

    }
    translatePointsToOrigin(points, pos) {
        let np = []
        points.forEach((point, i) => {
            np[i] = point.subtract(pos);
        })
        return np;
    }
    createPolygonFromPath(points, plane, uid, scene, dir) {

        let fill = new BABYLON.Mesh(uid, scene);

        let vdata = this.pointsToVtxData(points, plane, dir);
        let positions = vdata[0];
        let indices = vdata[1];
        let normals = [];
        let vertexData = new BABYLON.VertexData();

        //BABYLON.VertexData._ComputeSides(BABYLON.VertexData.DOUBLESIDE,positions, indices, normals,[]);
        //Assign positions, indices and normals to vertexData
        BABYLON.VertexData.ComputeNormals(positions, indices, normals);
        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        //Apply vertexData to custom mesh
        vertexData.applyToMesh(fill);
        return fill;
    }
    rotateVerts(v) {
        if (v.length < 3) {
            return v;
        }
        v.reverse()
        let s = v.splice(v.length - 2, 2);
        return s.concat(v);
    }
    directionOfVerts(path, plane) {
        if (!path.length) {
            return 1
        }
        if (path.length < 3) {
            return 1
        }
        let p0 = path[0];
        let p1 = path[1];
        let p2 = path[2];
        let p01 = p0.subtract(p1);
        let p12 = p1.subtract(p2);
        let cross = p01.cross(p12);
        return Math.sign(cross[plane]);
    }
    pointsToVtxData(points, plane, dir) {
        let verts = [];
        let inds = [];
        let vp = []
        points.forEach((point, i) => {
            /* verts.push(point.x)
            verts.push(point.y)
            verts.push(point.z) */
            this.pointToPos(verts, point)
            vp.push(point);
        })
        //console.log(points);
        let l = points.length;
        points.forEach((_point, i) => {
            let point = _point.clone();
            point[plane] += (0.2) * dir
            /* verts.push(point.x)
            verts.push(point.y)
            verts.push(point.z) */
            this.pointToPos(verts, point)
            vp.push(point);
        })
        for (let z = 0; z < l; z++) {
            let p1 = z + l + 1;
            let p2 = z + 1;
            if (p1 >= 2 * l) {
                p1 = l
            }
            if (p2 >= l) {
                p2 = 0
            }
            let pt = vp[z];
            this.pointToPos(verts, pt);
            pt = vp[z + l];
            this.pointToPos(verts, pt);
            pt = vp[p1]
            this.pointToPos(verts, pt);
            pt = vp[p2]
            this.pointToPos(verts, pt);
        }
        //face
        for (let i = 0; i < l - 2; i++) {
            inds.push(0);
            inds.push(i + 1);
            inds.push(i + 2);
        }
        //face
        for (var i = l - 2; i > 0; i--) {
            inds.push(l);
            inds.push(i + l + 1);
            inds.push(i + l);
        }
        //extrude
        for (let i = 0; i < l; i++) {
            let _i = (i * 4)
            let p1 = _i + (2 * l);
            let p2 = _i + (2 * l) + 1;
            let p3 = _i + (2 * l) + 2;
            let p4 = _i + (2 * l) + 3;


            inds.push(p1);
            inds.push(p2);
            inds.push(p3);
            //
            inds.push(p1);
            inds.push(p3);
            inds.push(p4);

        }
        // console.log(verts);
        // console.log(inds);
        return [verts, inds];
    }
    pointToPos(verts, point) {
        verts.push(point.x)
        verts.push(point.y)
        verts.push(point.z)
    }

}