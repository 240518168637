import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional, AfterViewInit, forwardRef, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { MatCalendar, MatDatepickerIntl, MatCalendarHeader } from "@angular/material/datepicker";
// import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { DateHighlightService } from "../services/date-highlight.service";
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';

import moment from 'moment';
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;
export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
/** Custom header component for datepicker. */
@Component({
  selector: "custom-date",
  template: `
    <div class="mat-calendar-header">
      <div class="mat-calendar-controls">
        <button
          mat-button
          type="button"
          class="mat-calendar-period-button"
          (click)="currentPeriodClicked()"
          [attr.aria-label]="periodButtonLabel"
          cdkAriaLive="polite"
        >
          {{ periodButtonText }}
          <div
            class="mat-calendar-arrow"
            [class.mat-calendar-invert]="calendar.currentView != 'month'"
          ></div>
        </button>

        <div class="mat-calendar-spacer"></div>

        <ng-content></ng-content>

        <button
          mat-icon-button
          type="button"
          class="mat-calendar-previous-button"
          [disabled]="!previousEnabled()"
          (click)="customPrev()"
          [attr.aria-label]="prevButtonLabel"
        ></button>

        <button
          mat-icon-button
          type="button"
          class="mat-calendar-next-button"
          [disabled]="!nextEnabled()"
          (click)="customNext()"
          [attr.aria-label]="nextButtonLabel"
        ></button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

  { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format }]
})
export class CustomDateComponent extends MatCalendarHeader<any> {
  // date = new FormControl(moment());
  constructor(
    _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) calendar: MatCalendar<any>,
    @Optional() _dateAdapter: DateAdapter<any>,
    @Optional() @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
    private dateService: DateHighlightService
  ) {
    super(_intl, calendar, _dateAdapter, _dateFormats, changeDetectorRef);
  }
  /** Handles user clicks on the period label. */
  currentPeriodClicked(): void {
    this.calendar.currentView =
      this.calendar.currentView == "month" ? "multi-year" : "month";
    this.dateService.displayMonth(
      this.calendar.activeDate._d.getFullYear(),
      this.calendar.currentView
    );
  }

  /** Handles user clicks on the previous button. */
  customPrev(): void {
    this.previousClicked();
    this.dateService.displayMonth(
      this.calendar.activeDate._d.getFullYear(),
      this.calendar.currentView
    );
  }

  /** Handles user clicks on the next button. */
  customNext(): void {
    this.nextClicked();
    this.dateService.displayMonth(
      this.calendar.activeDate._d.getFullYear(),
      this.calendar.currentView
    );
  }
}

/** @title Datepicker with custom calendar header */


@Component({
  selector: "date-highlight",
  templateUrl: "./date-hightlight.component.html",
  styleUrls: ['./date-hightlight.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateHightlightComponent {
  exampleHeader = CustomDateComponent;

  sliderDate: any;
  date = new FormControl(moment());
  @Input() set sliderDefaultDate(v: any) {
    this.sliderDate = v;
  }
  shipObjects: any;
  @Output() dateChange = new EventEmitter<string>();
  updatedDate: any = true;
  constructor(private dateService: DateHighlightService, private datePipe: DatePipe) { }
  yearSelected(event: any) {
    this.dateService.displayMonth(event._d.getFullYear(), "year");
  }
  dateClass() {
    return (d: Date) => {
      return this.dateService.getClass(d)
    };
  }

  onDateChange(event) {
    event.value = event.value._d;
    this.dateChange.emit(event);
  }



}

