import { Component, OnInit } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { LoginService } from '../services/login.service'; 
import { AuthService } from '../auth.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {
  loggedIn = false;
  constructor(public auth:MsalService,public loginServ:LoginService,private communicationServ: CommunicationService) { }

  ngOnInit(): void {
  }
  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.auth.loginRedirect();
    } else {
      this.auth.loginPopup().then(_response => {
        console.log("loginPopup ", _response);
        this.loginServ.loggedState=true;
      })
        .catch(err => {
          console.log("userCancelledError ", err)
        });
    }
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
}
